import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import botman from "../../Assets/Images/botgif.gif";

const MovableNova = ({ initialX, initialY }) => {
  const navigate = useNavigate();
  const [position, setPosition] = useState({ x: initialX, y: initialY });
  const [isDragging, setIsDragging] = useState(false);
  const logoRef = useRef(null);
  const draggedRef = useRef(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isDragging) return;

      draggedRef.current = true;

      // const newX = e.clientX;
      // const newY = window.innerHeight - e.clientY;

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const divWidth = logoRef.current.offsetWidth;
      const divHeight = logoRef.current.offsetHeight;

      const newX = Math.max(0, Math.min(e.clientX, windowWidth - divWidth));
      const newY = Math.max(0, Math.min(windowHeight - e.clientY, windowHeight - divHeight));

      setPosition({ x: newX, y: newY });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);

    setTimeout(() => (draggedRef.current = false), 0);
  };

  return (
    <div
      ref={logoRef}
      style={{
        left: `${position.x}px`,
        bottom: `${position.y}px`,
        cursor: "move",
      }}
      className="common-chat-bot-nova"
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!draggedRef.current) {
          navigate("/revenue/chat");
        }
      }}
    >
      <div className="common-chat-bot-nova-inner">
        <img src={botman} alt="botman" />
        <h6>Nova</h6>
      </div>
    </div>
  );
};

export default MovableNova;
