import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import { Multiselect } from "multiselect-react-dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../Assets/Images/Header/header-logo.png";
import sqtLogo from "../../Assets/Images/Header/header-logo-sqt.png";
import calender from "../../Assets/Images/Header/calender-icon.svg";
import mail from "../../Assets/Images/Header/mail-icon.svg";
import notification from "../../Assets/Images/Header/notification-icon.svg";
import todo from "../../Assets/Images/Header/to-do-list-icon.svg";
import headerAvtar from "../../Assets/Images/Header/header-avtar.png";
import NavDropdown from "react-bootstrap/NavDropdown";
import SearchIcon from "../../Assets/Images/Header/search-icon.svg";
import Form from "react-bootstrap/Form";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { commonService } from "../../utils/commonService";
import HeaderToDoList from "./HeaderToDoList";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { API_URL } from "../../utils/apiRoutes";
import { toast } from "react-toastify";
import NotificationList from "./NotificationList";
import userPlaceHolder from "../../Assets/Images/blank-user.svg";
import UserProfileUpdateModal from "./UserProfileUpdateModal";
import { apiCall } from "../../utils/axiosService";
import { apiResponse } from "../../utils/apiResponse";
import RevEvolve from "../../Assets/Images/LogIn/RevEvolve60.png";
import {
  setPropertyInRedux,
  setCrmPropertyInRedux,
  setAsOfDateForHeader,
  setDefaultAsOfDateForHeader,
  setUserRolePropertyList,
  setSidebarCollapse,
  setDefaultSidebar,
  setAllNotificationCount,
  setAllPropertyDetails,
  setBookingPaceMonth,
  setDefaultCurrency,
} from "../../utils/slices/headerPropertySlice";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { errorToastMessage } from "../../utils/toastMessage";
import editIcon from "../../Assets/Images/Action Icons/editaction1.svg";
import moment from "moment";
import hamBurgerMenu from "../../Assets/Images/hamBurgerMenu.svg";

function Header() {
  let userRights = "";
  let userRightsFromLocal = localStorage.getItem("userRights");
  if (userRightsFromLocal) {
    userRights = JSON.parse(commonService.getItem("userRights"));
  }

  const dispatch = useDispatch();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const sidebarOpen = useSelector(
    (store) => store.headerProperty.isSidebarOpen
  );

  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );
  const userDetail = commonService.getLoginUserData();
  let values = [
    { label: "Thing 1", value: 1 },
    { label: "Thing 2", value: 2 },
    { label: "Thing 3", value: 3 },
  ];
  const options = values;
  const [inputValue, setInputValue] = useState("");
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const [showModal, setShowModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [profileUpdateModal, setProfileUpdateModal] = useState(false);
  const [propertyDetail, setPropertyDetail] = useState(null);
  const [propertySelcted, setPropertySelcted] = useState(
    propertyDetailFromRTK ? propertyDetailFromRTK?.propertyid : ""
  );

  const [crmProperty, setCrmProperty] = useState(null);
  const [crmPropertySelcted, setCrmPropertySelcted] = useState(
    crmPropertyDetailFromRTK ? crmPropertyDetailFromRTK?.propertyid : ""
  );
  const [asOfDate, setAsOfDate] = useState(null);
  const minAsOfDateRef = useRef();
  const [selectedAsOfDate, setSelectedAsOfDate] = useState(null);
  const [notificationCount, setNotificationCount] = useState(null);
  const allCountRef = useRef();
  const notificationType = useSelector(
    (store) => store.headerProperty.allNotificationCount
  );

  const navigate = useNavigate();

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const closeProfileUpdateModal = () => {
    setProfileUpdateModal(false);
  };

  const closeNotificationModal = () => {
    setShowNotification(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href =
      API_URL.BASE_API_URL.EMA_MAIN_DOMAIN + "?logout=true";
  };

  const handleActionLog = () => {
    navigate("/configuration/action-logs");
  };

  const getPropertyList = async () => {
    // let propertyListResponse = await apiCall(
    //   {
    //     method: "POST",
    //     url:
    //       API_URL.BASE_API_URL.RevenueManagement +
    //       API_URL.REVENUE_PROPERTY.GET_LIST,
    //     body: {
    //       pageNum: 1,
    //       pageSize: 10,
    //       sortColumn: "",
    //       sortDirection: "asc",
    //       searchValue: "",
    //       propertycode: "",
    //       propertyname: "",
    //       displayname: "",
    //       brand_term: "",
    //       frenchise_term: "",
    //       pms_term: "",
    //       chain_term: "",
    //       pmstype_term: "",
    //       status_term: "",
    //       search: "",
    //       propertiesid: "",
    //       modulename: "",
    //       portfolioid: 0,
    //     },
    //   },
    //   false
    // );

    let propertyListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.UserManagement +
          API_URL.USER_ROLE_PROPERTY.GET_LIST_BY_MODULE_AND_USER,
        body: {
          userid: userDetail?.userid,
          modulename: "Revenue",
        },
      },
      false
    );

    let response = apiResponse(false, propertyListResponse);

    if (response?.isValidate) {
      setPropertyDetail(response?.data?.data);
      let sameCurrency = true,
        currency = response?.data?.data?.[0]?.currency_term;
      for (let i = 0; i < response?.data?.data?.length; i++) {
        if (response?.data?.data?.[i]?.currency_term !== currency) {
          sameCurrency = false;
          break;
        }
      }
      commonService.setItem(
        "defaultCurrency",
        JSON.stringify({
          currency: currency ? currency : "$",
        })
      );
      dispatch(setDefaultCurrency(currency ? currency : "$"));
      dispatch(setAllPropertyDetails(response?.data?.data));
    }

    if (!response?.isValidate) {
      setPropertyDetail(null);
      dispatch(setAllPropertyDetails(null));
    }
  };

  const getPropertyListByUserId = async () => {
    // let propertyListByUserIdReponse = await apiCall(
    //   {
    //     method: "POST",
    //     url:
    //       API_URL.BASE_API_URL.CRM +
    //       API_URL.PROPERTY_TERM.GET_PROPERTY_LIST_BY_USER_ID,
    //     body: {
    //       id: userDetail?.userid,
    //     },
    //   },
    //   false
    // );

    let propertyListByUserIdReponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.UserManagement +
          API_URL.USER_ROLE_PROPERTY.GET_LIST_BY_MODULE_AND_USER,
        body: {
          userid: userDetail?.userid,
          modulename: "CRM",
        },
      },
      false
    );

    let response = apiResponse(false, propertyListByUserIdReponse);

    if (response?.isValidate) {
      setCrmProperty(response?.data?.data);
      if (
        !crmPropertyDetailFromRTK ||
        crmPropertyDetailFromRTK?.propertyid === ""
      ) {
        let allPropertyId = "";
        response?.data?.data?.map((item, index) => {
          allPropertyId += `${item?.propertyid},`;
        });
        commonService.setItem(
          "crmProperty",
          JSON.stringify({
            propertyid: "",
            propertycode: "ALL",
            propertyname: "ALL",
            allPropertyId,
          })
        );

        setCrmPropertySelcted("");
        dispatch(
          setCrmPropertyInRedux({
            propertyid: "",
            propertycode: "ALL",
            propertyname: "ALL",
            allPropertyId,
          })
        );
      }
    }

    if (!response?.isValidate) {
      setCrmProperty(null);
    }
  };

  const getAsOfDate = async (propertyCode) => {
    let asOfDateResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.AS_OF_DATE.AS_OF_DATE,
        body: { propertyCode: propertyCode },
      },
      false
    );

    if (asOfDateResponse?.status_code === 0) {
      return errorToastMessage(asOfDateResponse?.message);
    } else if (asOfDateResponse?.status_code === 1) {
      setAsOfDate(
        asOfDateResponse?.data?.[0]?.last_data_refresh
          ? asOfDateResponse?.data?.[0]?.last_data_refresh
          : ""
        // : `${commonService.getDateForNode(new Date())} 00:00:00`
      );
      let date = moment(
        asOfDateResponse?.data?.[0]?.last_data_refresh
      ).subtract(90, "d");
      minAsOfDateRef.current = date;
      setSelectedAsOfDate(
        asOfDateResponse?.data?.[0]?.last_data_refresh
          ? asOfDateResponse?.data?.[0]?.last_data_refresh
          : ""
        // : `${commonService.getDateForNode(new Date())} 00:00:00`
      );
      dispatch(
        setAsOfDateForHeader(
          asOfDateResponse?.data?.[0]?.last_data_refresh
            ? asOfDateResponse?.data?.[0]?.last_data_refresh
            : ""
          // : `${commonService.getDateForNode(new Date())} 00:00:00`
        )
      );
      dispatch(
        setDefaultAsOfDateForHeader(
          asOfDateResponse?.data?.[0]?.last_data_refresh
            ? asOfDateResponse?.data?.[0]?.last_data_refresh
            : ""
          // : `${commonService.getDateForNode(new Date())} 00:00:00`
        )
      );
    }
  };

  const getUserRolePropertyList = async () => {
    let userRolePropertyResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.UserManagement +
          API_URL.USER_ROLE_PROPERTY.GET_LIST_BY_PROPERTY_AND_MODULE,
        body: {
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          modulename: "revenue",
        },
      },
      false
    );
    let response = apiResponse(false, userRolePropertyResponse);
    if (response?.isValidate) {
      let responseData = response?.data?.data;
      responseData.unshift({
        displayname: "All",
        userid: responseData.map((d) => d.userid),
      });
      dispatch(setUserRolePropertyList(responseData));
    } else {
      dispatch(setUserRolePropertyList(null));
    }
  };

  const getNotificationCount = async () => {
    let notificationCountResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Notification +
          API_URL.NOTIFICATION.GET_NOTIFICATION_READ_COUNT,
        body: {
          propertycode: propertyDetailFromRTK
            ? propertyDetailFromRTK?.propertycode
            : "",
        },
      },
      true
    );

    let response = apiResponse(false, notificationCountResponse);

    if (response?.isValidate) {
      let allCount = response?.data?.data?.filter(
        (x) => x?.associationtype === "All"
      );
      allCountRef.current = allCount?.[0]?.noofcount
        ? allCount?.[0]?.noofcount
        : 0;
      dispatch(setAllNotificationCount(response?.data?.data));
      setNotificationCount(response?.data?.data);
    }

    if (!response?.isValidate) {
      setNotificationCount(null);
    }
  };

  useEffect(() => {
    let userHaveAccess = userRights?.usermodule?.filter((x) => {
      if (
        window?.location?.href
          ?.toLowerCase()
          ?.includes(x.modulename?.toLowerCase())
      ) {
        return x;
      }
    });

    if (userHaveAccess?.length <= 0) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    setPropertySelcted(propertyDetailFromRTK ? propertyDetailFromRTK?.propertyid : "")
    if (userDetail != null && userDetail.userid !== 0) {
      const connect = new HubConnectionBuilder()
        .withUrl(API_URL.BASE_API_URL.LogAndMessage, { withCredentials: false })
        .withAutomaticReconnect()
        .build();

      connect
        .start()
        .then(() => {
          /*  connect.send("connect", userDetail.userid, commonService.getItem("token"));*/
          connect.send("connect", userDetail.userid);
          connect.on("getnotification", (data) => {
            if (
              document.querySelectorAll('[data_details="' + data.details + '"]')
                .length === 0
            ) {
              toast(
                <>
                  <div className="card-title h6" data_details={data.details}>
                    {data.title}
                  </div>
                  <p className="card-text pt-2">{data.details}</p>
                </>,
                {
                  position: "bottom-right",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                  theme: "dark",
                }
              );
            }
          });
        })
        .catch((error) => {});
    }

    if (window?.location?.href?.includes("/revenue/")) {
      getPropertyList();

      if (propertyDetailFromRTK) {
        getUserRolePropertyList();
        getAsOfDate(propertyDetailFromRTK?.propertycode);
      }
    }

    if (window?.location?.href?.includes("/crm/")) {
      getPropertyListByUserId();
      if (
        crmPropertyDetailFromRTK &&
        crmPropertyDetailFromRTK?.propertyid != ""
      ) {
        getAsOfDate(crmPropertyDetailFromRTK?.propertycode);
      }
    }

    if (
      window?.location?.href?.substring(
        window?.location?.href.lastIndexOf("/") + 1 === "Crm-Rateshop"
      ) ||
      window?.location?.href?.substring(
        window?.location?.href.lastIndexOf("/") + 1 === "crm-events"
      ) ||
      window?.location?.href?.substring(
        window?.location?.href.lastIndexOf("/") + 1 === "crm-rateiq"
      )
    ) {
      getPropertyList();
    }
    //commonService.SendPushNotification("Hi This Is Testing From Satish Thummar", "", 0, "Test", "");
    getNotificationCount();
  }, [propertyDetailFromRTK]);

  const HeaderTitle = () => {
    if (window?.location?.href?.includes("/revenue/")) {
      return "RevEVOLVE";
    } else if (window?.location?.href?.includes("/crm/")) {
      return "CRM";
    } else if (window?.location?.href?.includes("/configuration/")) {
      return "Configuration";
    } else if (window?.location?.href?.includes("/operation/")) {
      return "Operation";
    } else {
      return "Switchboard";
    }
  };

  return (
    <div className="header-main">
      <Container fluid>
        <Row className="header-row">
          <Col xl={3} lg={1} className="header-left-col">
            <div className="logo-sec d-flex">
              <div className="logo-main">
                <Link to={`${API_URL.BASE_API_URL.EMA_MAIN_DOMAIN}/dashboard`}>
                  <>
                    {API_URL.BASE_API_URL.EMA_REV_SUB_DOMAIN ===
                    "https://" + window.location.host ? (
                      <img
                        src={RevEvolve}
                        alt="logo"
                        title="Switchboard"
                        style={{
                          height: "60px",
                          width: "60px",
                        }}
                      />
                    ) : (
                      <img src={logo} alt="logo" title="Switchboard" />
                    )}
                  </>
                </Link>
              </div>
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  background: "#f2f4fc",
                  width: "76%",
                }}
              >
                <h2 className="logo-name">{HeaderTitle()}</h2>
                {HeaderTitle() !== "Switchboard" && (
                  <img
                    style={{
                      height: "20px",
                      marginRight: "25px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      dispatch(setSidebarCollapse());
                    }}
                    src={hamBurgerMenu}
                  />
                )}
              </div>
            </div>
          </Col>
          <Col xl={9} lg={11} className="header-right-col">
            <div className="header-right d-flex justify-content-between align-items-center gap-2">
              <div className="d-flex align-items-center gap-4">
                <div className="header-select-property">
                  {window?.location?.href?.includes("/revenue/") && (
                    <div className="multiselector">
                      <Form.Select
                        value={propertySelcted}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            localStorage.removeItem("property");
                            setPropertySelcted("");
                            dispatch(setPropertyInRedux(null));
                            dispatch(setAsOfDateForHeader(null));
                          } else {
                            let findProperty = propertyDetail?.filter(
                              (x) => x?.propertyid === parseInt(e.target.value)
                            );
                            commonService.setItem(
                              "property",
                              JSON.stringify(findProperty?.[0])
                            );

                            setPropertySelcted(
                              e.target.value
                                ? parseInt(e.target.value)
                                : e.target.value
                            );
                            dispatch(setPropertyInRedux(findProperty?.[0]));
                            // getAsOfDate();
                          }
                        }}
                      >
                        <option value="">Select Property</option>
                        {propertyDetail?.map((item, index) => (
                          <option value={item?.propertyid} key={index}>
                            {item?.propertyname}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  )}

                  {window?.location?.href?.includes("/crm/") && (
                    // !window?.location?.href?.includes("/Crm-Rateshop") &&
                    // !window?.location?.href?.includes("crm-events") &&
                    // !window?.location?.href?.includes("crm-rateiq") &&
                    <div className="multiselector">
                      <Form.Select
                        value={crmPropertySelcted}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            let allPropertyId = "";
                            crmProperty?.map((item, index) => {
                              allPropertyId += `${item?.propertyid},`;
                            });
                            commonService.setItem(
                              "crmProperty",
                              JSON.stringify({
                                propertyid: "",
                                propertycode: "ALL",
                                propertyname: "ALL",
                                allPropertyId,
                              })
                            );

                            setCrmPropertySelcted("");
                            dispatch(
                              setCrmPropertyInRedux({
                                propertyid: "",
                                propertycode: "ALL",
                                propertyname: "ALL",
                                allPropertyId,
                              })
                            );
                          } else {
                            let findProperty = crmProperty?.filter(
                              (x) => x?.propertyid === parseInt(e.target.value)
                            );
                            commonService.setItem(
                              "crmProperty",
                              JSON.stringify(findProperty?.[0])
                            );

                            setCrmPropertySelcted(
                              e.target.value
                                ? parseInt(e.target.value)
                                : e.target.value
                            );
                            dispatch(setCrmPropertyInRedux(findProperty?.[0]));
                          }
                        }}
                      >
                        <option value="">ALL</option>
                        {crmProperty?.map((item, index) => (
                          <option value={item?.propertyid} key={index}>
                            {item?.propertyname}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  )}

                  {/* {window?.location?.href?.substring(
                    window?.location?.href.lastIndexOf("/") + 1
                  ) === "Crm-Rateshop" ||
                  window?.location?.href?.substring(
                    window?.location?.href.lastIndexOf("/") + 1
                  ) === "crm-events" ||
                  window?.location?.href?.substring(
                    window?.location?.href.lastIndexOf("/") + 1
                  ) === "crm-rateiq" ? (
                    <div className="multiselector">
                      <Form.Select
                        value={propertySelcted}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            localStorage.removeItem("property");
                            setPropertySelcted("");
                            dispatch(setPropertyInRedux(null));
                          } else {
                            let findProperty = propertyDetail?.filter(
                              (x) => x?.propertyid === parseInt(e.target.value)
                            );
                            commonService.setItem(
                              "property",
                              JSON.stringify(findProperty?.[0])
                            );

                            setPropertySelcted(
                              e.target.value
                                ? parseInt(e.target.value)
                                : e.target.value
                            );
                            dispatch(setPropertyInRedux(findProperty?.[0]));
                            // getAsOfDate();
                          }
                        }}
                      >
                        <option value="">Select Property</option>
                        {propertyDetail?.map((item, index) => (
                          <option value={item?.propertyid} key={index}>
                            {item?.propertyname}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  ) : null} */}
                </div>
                {window?.location?.href?.includes("/revenue/") &&
                  propertyDetailFromRTK && (
                    <Link
                      className="headerEditIcon customHeaderIcon"
                      to={`/revenue/property-setting/${propertyDetailFromRTK?.propertyid}`}
                      state={{ propertyData: propertyDetailFromRTK }}
                    >
                      {/* <div> */}
                      <img src={editIcon} />
                      {/* </div> */}
                    </Link>
                  )}
                {window?.location?.href?.includes("/revenue/") && (
                  <div className="header-asof-date">
                    <DatePicker
                      className="width150"
                      id="asOfDate"
                      placeholderText="As Of Date"
                      maxDate={asOfDate ? new Date(asOfDate) : ""}
                      minDate={
                        minAsOfDateRef.current
                          ? new Date(minAsOfDateRef.current)
                          : ""
                      }
                      selected={
                        propertySelcted
                          ? selectedAsOfDate
                            ? commonService.convertDateInTimezone(
                                selectedAsOfDate
                              )
                            : ""
                          : ""
                      }
                      onChange={(date) => {
                        if (date !== null && date !== "") {
                          let month =
                            date.getMonth() + 1 > 9
                              ? date.getMonth() + 1
                              : `0${date.getMonth() + 1}`;
                          let dateFormat =
                            date?.getDate() > 9
                              ? date.getDate()
                              : `0${date.getDate()}`;
                          let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                          setSelectedAsOfDate(selectedDate);
                          dispatch(setAsOfDateForHeader(selectedDate));
                          dispatch(setBookingPaceMonth(""));
                        }
                      }}
                      disabled={
                        window?.location?.href?.includes("/revenue/event")
                          ? true
                          : false
                      }
                    />
                  </div>
                )}
              </div>

              <div className="right-menu d-flex align-items-center justify-content-end">
                {/* <div className="header-search-input">
                  <InputGroup className="search-group">
                    <Button className="border-0">
                      <img src={SearchIcon} />
                    </Button>
                    <FormControl
                      className="border-0"
                      placeholder="Search..."
                      aria-label="Search"
                      onChange={handleChange}
                    />
                  </InputGroup>
                </div> */}
                <div className="header-icons">
                  <ul className="list-unstyled p-0 m-0">
                    <li>
                      <Button
                        className="header-notification header-btn position-relative"
                        onClick={() => setShowNotification(true)}
                      >
                        <img src={notification} alt="notification" />
                        {/* <span className="background-color-theme"></span> */}
                        <span
                          style={{
                            color: "#fff",
                            fontSize: "12px",
                            borderRadius: "10px",
                            background: "#3565FC",
                            position: "absolute",
                            padding: "2px 5px",
                            top: "-5px",
                            left: "calc(100% - 5px)",
                            boxShadow: "0px 1px 8px 0px rgba(12, 0, 0, 0.08)",
                          }}
                        >
                          {notificationType
                            ? notificationType?.filter(
                                (x) => x?.associationtype === "All"
                              )?.[0]?.noofcount
                            : 0}
                        </span>
                      </Button>
                    </li>
                    <li>
                      <Button
                        className="header-to-do header-btn"
                        onClick={openModal}
                      >
                        <img src={todo} alt="to-do" />
                      </Button>
                    </li>
                  </ul>
                </div>
                <div className="header-drop-down d-flex align-items-center">
                  <img
                    className="header-avtar"
                    src={
                      userDetail?.profilepic
                        ? userDetail?.profilepic
                        : userPlaceHolder
                    }
                    alt=""
                  />
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title={userDetail?.displayname}
                  >
                    <NavDropdown.Item
                      onClick={() => {
                        setProfileUpdateModal(true);
                      }}
                    >
                      User Profile
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onClick={() => {
                        handleActionLog();
                      }}
                    >
                      Action Log
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      Log Out
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {showNotification && (
        <NotificationList
          showSideBar={showNotification}
          closeSideBar={closeNotificationModal}
        />
      )}
      {profileUpdateModal && (
        <UserProfileUpdateModal
          showModal={profileUpdateModal}
          closeModal={closeProfileUpdateModal}
        />
      )}
      {showModal && (
        <HeaderToDoList show={showModal} handleClose={closeModal} />
      )}
    </div>
  );
}

export default Header;
