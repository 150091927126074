export const API_URL = {
  BASE_API_URL: {
    UserManagement: process.env.REACT_APP_APIBASEPATH_USERMANAGEMENT,
    Configuration: process.env.REACT_APP_APIBASEPATH_CONFIGURATION,
    LogAndMessage: process.env.REACT_APP_APIBASEPATH_LOGANDMESSAGE,
    Notification: process.env.REACT_APP_APIBASEPATH_NOTIFICATION,
    RevenueManagement: process.env.REACT_APP_APIBASEPATH_REVENUECONFIGURATION,
    SnapShot: process.env.REACT_APP_SNAPSHOT,
    CRM: process.env.REACT_APP_APIBASEPATH_CRM,
    CRM_REPORT_PDF_BASEPATH: process.env.REACT_APP_APIBASEPATH_CRM_REPORT_PDF,
    REACT_WEB_BASEURL: process.env.REACT_APP_REACT_WEB_BASEURL,

    EMA_MAIN_DOMAIN:
      process.env.REACT_APP_ENVIORMENT === "local"
        ? process.env.REACT_APP_RUNNING_MAIN_DOMAIN
        : process.env.REACT_APP_ENVIORMENT === "sqt"
        ? process.env.REACT_APP_SQT_MAIN_DOMAIN
        : process.env.REACT_APP_EMA_MAIN_DOMAIN,

    EMA_REV_SUB_DOMAIN:
      process.env.REACT_APP_ENVIORMENT === "local"
        ? process.env.REACT_APP_RUNNING_REV_SUB_DOMAIN
        : process.env.REACT_APP_ENVIORMENT === "sqt"
        ? process.env.REACT_APP_SQT_REV_SUB_DOMAIN
        : process.env.REACT_APP_EMA_REV_SUB_DOMAIN,
  },
  LOGIN_URL: "v1/Login/getclientuserlogindetails",
  CLIENT_BY_PRIMARY_KEY: "v1/Client/getbyprimarykey",
  FORGOT_PASSWORD: "v1/Login/forgotpassword",
  UPDATE_PASSWORD: "v1/Login/updatepassword",
  LOGINSWITCHSET: "v1/Login/loginswitchset",
  LOGINSWITCHGET: "v1/Login/loginswitchget",
  CLIENT_USER_LOGIN_BY_ID: "v1/Login/getclientuserlogindetailsbyuserid",

  PROPERTY_TERM: {
    GET_PROPERTY_LIST: "v1/Properties/getlist",
    DELETE_PROPERTY_DATA: "v1/Properties/delete",
    Get_PROPERTY_DATA: "v1/Properties/getbyprimarykey",
    SAVE_PROPERTY_DATA: "v1/Properties/update",
    PROPERTY_ASSOCIATION_TAXES:
      "v1/PropertiesAssociationTaxes/getlistwithalltaxes",
    SAVE_PROPERTY_ASSOCIATION_TAXES: "v1/PropertiesAssociationTaxes/save",
    GET_PROPERTY_TAXES: "v1/PropertiesTaxes/getlist",
    SAVE_PROPERTY_TAX: "v1/PropertiesTaxes/save",
    GET_PROPERTY_ROOM_TYPES: "v1/PropertiesRoomTypes/getlist",
    SAVE_PROPERTY_ROOM_TYPES: "v1/PropertiesRoomTypes/save",
    GET_AV_EQUIPMENT_LIST: "v1/PropertiesAVEquipment/getlist",
    SAVE_AV_EQUIPMENT: "v1/PropertiesAVEquipment/save",
    GET_PROPERTY_FOOD_LIST: "v1/PropertiesFood/getlist",
    SAVE_PROPERTY_FOOD_LIST: "v1/PropertiesFood/save",
    GET_PROPERTY_BEVERAGES_LIST: "v1/PropertiesBeverage/getlist",
    SAVE_PROPERTY_BEVERAGES_LIST: "v1/PropertiesBeverage/save",
    SAVE_PROPERTIES_ROOM_MEETING: "v1/PropertiesRoomMeeting/save",
    GET_PROPERTIES_ROOM_MEETING: "v1/PropertiesRoomMeeting/getlist",
    GET_DROPDOWN_LIST: "v1/Properties/getlistdropdown",
    GET_USER_LIST_BY_PROPERTY_ID: "v1/Properties/getlistuserbypropertyid",
    GET_PROPERTY_LIST_BY_USER_ID: "v1/Properties/getlistpropertybyuserid",
    GET_PROPERTY_ALL_DETAILS_HOTEL_VIEW:
      "v1/Properties/getbyprimarykeywithalldetails",
    GET_DASHBOARD_COUNTS: "v1/Properties/getdashboardcountsbyprimarykey",
  },

  CRM_ATTACHMENTS: {
    SAVE_ATTACHMENTS_DATA: "v1/Attachments/save",
    GET_ATTACHMENTS_DATA: "v1/Attachments/getlist",
    DELETE_ATTACHMENTS_DATA: "v1/Attachments/delete",
    GET_ATTACHMENTS_DATA_WITH_PRIMARY_KEY: "v1/Attachments/getbyprimarykey",
  },

  CRM_ACCOUNTS: {
    GET_ACCOUNTS_DATA: "v1/Accounts/getlist",
    GET_ACCOUNTS_DATA_LAST_WORKED: "v1/Accounts/getlistlastworked",
    GET_ACCOUNT_DASHBOARD_COUNT: "v1/Accounts/getdashboardcounts",
    SAVE_ACCOUNTS_DATA: "v1/Accounts/save",
    GET_BY_PRIMARY_KEY: "v1/Accounts/getbyprimarykeywithdetails",
    ACCOUNT_OWNER_LIST: "v1/Properties/getlistuserbypropertyid",
    DELETE_ACCOUNT_DATA: "v1/Accounts/delete",
    UPDATE_TOP: "v1/Accounts/updatetop",
    UPDATE_TARGET: "v1/Accounts/updatetarget",
    UPDATE_TARGET_GROWTH: "v1/Accounts/updatetargetgrowth",
    GET_OPPORTUNITY_FROM_ACCOUNT: "v1/Accounts/getopportunityfromaccount",
    GET_NIGHT_SOLD_AND_ADR_DATA: "crm/get_property_summery",
    GET_RESERVATION_DETAIL: "crm/reservation_detail",
  },

  CRM_OPPORTUNITY: {
    GET_RFP_LIST: "v1/RFPOpportunity/getlist",
    DELETE_RFP_DATA: "v1/RFPOpportunity/delete",
    SAVE_RFP_DATA: "v1/RFPOpportunity/save",
    GET_BY_PRIMARY_KEY: "v1/RFPOpportunity/getbyprimarykeywithdetails",
    GET_LNR_LIST: "v1/LNROpportunity/getlist",
    DELETE_LNR_DATA: "v1/LNROpportunity/delete",
    LNR_GET_BY_PRIMARY_KEY: "v1/LNROpportunity/getbyprimarykeywithdetails",
    SAVE_LNR_DATA: "v1/LNROpportunity/save",
    GROUP_GET_BY_PRIMARY_KEY: "v1/GroupOpportunity/getbyprimarykeywithdetails",
    GROUP_SAVE_DATA: "v1/GroupOpportunity/save",
    OPPORTUNITY_OWNER_LIST: "v1/Properties/getlistuserbypropertyid",
    MEETING_AND_CATERING_GET_BY_PRIMARY_KEY:
      "v1/MeetingCateringOpportunity/getbyprimarykeywithdetails",
    MEETING_AND_CATERING_SAVE_DATA: "v1/MeetingCateringOpportunity/save",
    GET_GROUP_LIST: "v1/GroupOpportunity/getlist",
    DELETE_GROUP_DATA: "v1/GroupOpportunity/delete",
    GET_MEETING_AND_CATERING_LIST: "v1/MeetingCateringOpportunity/getlist",
    DELETE_MEETING_CATERING_DATA: "v1/MeetingCateringOpportunity/delete",
    RFP_COPY_DATA: "v1/RFPOpportunity/copy",
    RFP_STATUS_CHANGE: "v1/RFPOpportunity/statuschange",
    LNR_STATUS_CHANGE: "v1/LNROpportunity/statuschange",
    GROUP_STATUS_CHANGE: "v1/GroupOpportunity/statuschange",
    MEETING_CATERING_STATUS_CHANGE:
      "v1/MeetingCateringOpportunity/statuschange",
    LNR_COPY_DATA: "v1/LNROpportunity/copy",
    GROUP_COPY_DATA: "v1/GroupOpportunity/copy",
    MEETING_AND_CATERING_COPY_DATA: "v1/MeetingCateringOpportunity/copy",
    SAVE_ACTUAL_GRID_DATA: "v1/RFPOpportunity/saveactualratelist",
    GET_ACTUAL_GRID_DATA: "v1/RFPOpportunity/getactualratelist",
    GET_RFP_DASHBOARD_COUNT: "v1/RFPOpportunity/getdashboardcounts",
    GET_LNR_DASHBOARD_COUNT: "v1/LNROpportunity/getdashboardcounts",
    GET_GROUP_DASHBOARD_COUNT: "v1/GroupOpportunity/getdashboardcounts",
    GET_MEETING_AND_CATERING_DASHBOARD_COUNT:
      "v1/MeetingCateringOpportunity/getdashboardcounts",
    GET_MEETING_ROOM_TYPE_BY_PROPERTY_ID: "v1/PropertiesRoomMeeting/getlist",
    GET_ROOM_TYPE_BY_PROPERTY_ID: "v1/PropertiesRoomTypes/getlist",
    LNR_GENERATE_WORD_FILE: "v1/LNROpportunity/LNRGenerateWordFile",
    GROUP_GENERATE_WORD_FILE: "v1/GroupOpportunity/GroupGenerateWordFile",
    MEETING_AND_CATERING_GENERATE_WORD_FILE:
      "v1/MeetingCateringOpportunity/MeetingCateringGenerateWordFile",
  },

  CRM_DASHBOARD: {
    DASHBOARD_COUNT: "v1/Dashboard/getdashboardcounts",
    GET_SALES_FUNNEL: "v1/Dashboard/getsalesfunnel",
    GET_ACTIVITY_CHART_COUNT: "v1/Dashboard/dashboardactivitychartcount",
    GET_OPPORTUNITY_ANTICIPATED_CHART:
      "v1/Dashboard/getopportunityanticipatedchart",
    GET_ACTIVITY_GOAL_CHART: "v1/Dashboard/getactivitygoalchart",
    GET_RFP_SALES_FUNNEL: "v1/Dashboard/getrfpsalesfunnel",
    GET_LNR_SALES_FUNNEL: "v1/Dashboard/getlnrsalesfunnel",
    GET_GRP_SALES_FUNNEL: "v1/Dashboard/getgroupsalesfunnel",
    GET_MTCT_SALES_FUNNEL: "v1/Dashboard/getmeetingsalesfunnel",
    GET_DASHBOARD_CHART_ACTIVITY_LIST:
      "v1/Dashboard/dashboardchartlistactivity",
  },
  PROJECT_TERM: {
    GET_CATEGORY_LIST: "v1/ProjectTerm/getcategorylist",
    GET_LIST: "v1/ProjectTerm/getlist",
    GET_BY_PRIMARY_KEY: "v1/ProjectTerm/getbyprimarykey",
    SAVE: "v1/ProjectTerm/save",
    DELETE: "v1/ProjectTerm/delete",
    GET_LIST_BY_MULTIPLE_CATEGORY: "v1/ProjectTerm/getlistbymulticategory",
  },

  CRM_ACCOUNT_RULES: {
    GET_ACCOUNT_RULES_LIST: "v1/AccountRule/getlist",
    GET_ACCOUNT_RULE_SETTINGS: "v1/AccountRuleSettings/getbyprimarykey",
    SAVE_UPDATED_DETAILS: "v1/AccountRuleSettings/update",
    SAVE_DISABLED: "v1/AccountRuleSettings/updatedisabled",
  },

  ACCOUNT: {
    GET_ACCOUNT_LIST: "v1/Accounts/getlist",
    GET_ACCOUNT_DROPDOWN_LIST: "v1/Accounts/getlistdropdown",
    GET_BY_PRIMARY_KEY_ACCOUNT: "v1/Accounts/getbyprimarykey",
  },

  CONTACTS: {
    GET_CONTACTS_LIST: "v1/Contacts/getlist",
    DELETE_CONTACT: "v1/Contacts/delete",
    SAVE_CONTACT: "v1/Contacts/save",
    GET_BY_PRIMARY_KEY_CONTACT: "v1/Contacts/getbyprimarykey",
  },

  EMAIL_ACCOUNT: {
    GET_LIST: "v1/EmailAccounts/getlist",
    GET_BY_PRIMARY_KEY: "v1/EmailAccounts/getbyprimarykey",
    SAVE: "v1/EmailAccounts/save",
    DELETE: "v1/EmailAccounts/delete",
  },

  EMAIL_TEMPLATE: {
    GET_LIST: "v1/EmailTemplates/getlist",
    GET_BY_PRIMARY_KEY: "v1/EmailTemplates/getbyprimarykey",
    SAVE: "v1/EmailTemplates/save",
    GET_ACCOUNT_LIST: "v1/EmailTemplates/getemailaccountslist",
  },

  MODULE: {
    GET_MODULE_LIST: "v1/Module/getmoduleslist",
    GET_MODULE_WISE_PROPERTY_LIST: "v1/Module/getmoduleswiseproperylist",
  },

  PROPERTY: {
    GET_LIST: "v1/Property/getlist",
    SAVE: "v1/Property/save",
    GET_ALL_DETAILS_BY_ID: "v1/Property/getalldetailsbyid",
    DELETE: "v1/Property/delete",
    GET_PROPERTY_WISE_MODULE_LIST: "v1/Property/getpropertywisemoduleslist",
    GET_BY_PRIMARY_KEY: "v1/Property/getbyprimarykey",
  },

  PORTFOLIO: {
    GET_LIST: "v1/Portfolio/getlist",
    SAVE: "v1/Portfolio/save",
    DELETE: "v1/Portfolio/delete",
    GET_ALL_DETAILS_BY_ID: "v1/Portfolio/getalldetailsbyid",
  },

  ROLE: {
    GET_LIST: "v1/Role/getlist",
    SAVE: "v1/Role/save",
    DELETE: "v1/Role/delete",
    GET_ALL_DETAILS_BY_ID: "v1/Role/getalldetailsbyid",
    GET_LIST_BY_MODULE: "v1/Role/getlistbymodule",
  },

  RIGHTS: {
    GET_LIST: "v1/Rights/getlist",
  },

  USER: {
    GET_LIST: "v1/User/getlist",
    SAVE: "v1/User/save",
    GET_ALL_DETAILS_BY_ID: "v1/User/getalldetailsbyid",
    DELETE: "v1/User/delete",
    USER_PROFILE_UPDATE: "v1/User/userprofileupdate",
    GET_LIST_BY_MODULE: "v1/User/getlistbymodule",
    SALES_GROUP_USER_PROPERTY: "v1/SalesGroupUserProperty/getlist",
    SAVE_SALES_USER_PROPERTY: "v1/SalesGroupUserProperty/save",
  },

  USER_ROLE_PROPERTY: {
    SAVE: "v1/UserRoleProperties/save",
    DELETE: "v1/UserRoleProperties/delete",
    GET_LIST_BY_PROPERTY_AND_MODULE:
      "v1/UserRoleProperties/getlistbymoduleandpropertyparamodel",
    GET_LIST_BY_MODULE_AND_USER: "v1/UserRoleProperties/getlistbymoduleanduser",
    GET_USER_LIST_BY_MODULE_MULTI_PROPERTY:
      "v1/UserRoleProperties/getlistbymoduleandmultiplepropertyparamodel",
  },

  EMPLOYEE: {
    GET_LIST: "v1/Employee/getlist",
    SAVE: "v1/Employee/save",
    GET_BY_PRIMARY_KEY: "v1/Employee/getbyprimarykey",
    DELETE: "v1/Employee/delete",
  },

  NOTIFICATION: {
    GET_LIST: "v1/Notification/getlist",
    GET_INSIGHT_LIST: "v1/Notification/getinsightslist",
    GET_NOTIFICATION_READ_COUNT: "v1/Notification/getnotificationunreadcount",
    UPDATE_VIEW_BY: "v1/Notification/updateviewby",
  },
  ACTIONLOG: {
    GET_LIST: "v1/ActionLog/getlist",
    GET_BY_PRIMARY_KEY: "v1/ActionLog/getbyprimarykey",
    SAVE: "v1/ActionLog/save",
  },

  LOG_IN_LOGS: {
    GET_LIST: "v1/LoginLog/getlist",
  },

  REVENUE_PORTFOLIO: {
    GET_LIST: "v1/Portfolio/getlist",
  },

  REVENUE_PROPERTY: {
    GET_LIST: "v1/Property/getlist",
  },

  REVENUE_RMS_PROPERTY: {
    GET_BY_PROPERTY_ID: "v1/RMSProperty/getbypropertyid",
    SAVE: "v1/RMSProperty/save",
  },

  REVENUE_RATE_SHOP_CONFIG: {
    GET_BY_PROPERTY_ID: "v1/RateshopConfig/getbypropertyid",
    SAVE: "v1/RateshopConfig/save",
  },

  REVENUE_PROPERTY_COMPETITER: {
    GET_LIST: "v1/PropertyCompetiters/getlist",
    SAVE: "v1/PropertyCompetiters/save",
    GET_BY_PRIMARY_KEY: "v1/PropertyCompetiters/getbyprimarykey",
    DELETE: "v1/PropertyCompetiters/delete",
    BULK_SAVE: "v1/PropertyCompetiters/bulksave",
  },

  REVENUE_ROOM_TYPE: {
    GET_LIST: "v1/RoomType/getlist",
    SAVE: "v1/RoomType/save",
    GET_BY_PRIMARY_KEY: "v1/RoomType/getbyprimarykey",
    DELETE: "v1/RoomType/delete",
  },

  REVENUE_SEASON: {
    GET_LIST: "v1/Season/getlist",
    GET_BY_PRIMARY_KEY: "v1/Season/getbyprimarykey",
    SAVE: "v1/Season/save",
    DELETE: "v1/Season/delete",
  },

  REVENUE_RATE_PLAN: {
    GET_LIST: "v1/RatePlan/getlist",
    SAVE: "v1/RatePlan/save",
    GET_BY_PRIMARY_KEY: "v1/RatePlan/getbyprimarykey",
    DELETE: "v1/RatePlan/delete",
    FILE_UPLOAD: "v1/RatePlan/readrateplanfile",
    IMPORT_LIST: "v1/RatePlan/exportlist",
  },

  REVENUE_ROOM_TYPE_CONFIG: {
    GET_LIST: "v1/RoomTypeBarConfig/getlist",
    SAVE: "v1/RoomTypeBarConfig/save",
    GET_BY_PRIMARY_KEY: "v1/RoomTypeBarConfig/getbyprimarykey",
    DELETE: "v1/RoomTypeBarConfig/delete",
    DELETEMULTI: "v1/RoomTypeBarConfig/deletemulti",
  },
  REVENUE_ROOM_TYPE_CONFIG: {
    GET_LIST: "v1/RoomTypeBarConfig/getlist",
    SAVE: "v1/RoomTypeBarConfig/save",
    GET_BY_PRIMARY_KEY: "v1/RoomTypeBarConfig/getbyprimarykey",
    DELETE: "v1/RoomTypeBarConfig/delete",
    DELETEMULTI: "v1/RoomTypeBarConfig/deletemulti",
  },

  REVENUE_BAR_CONFIG: {
    GET_LIST: "v1/BarConfig/getlist",
    SAVE: "v1/BarConfig/save",
    GET_BY_PRIMARY_KEY: "v1/BarConfig/getbyprimarykey",
    DELETE: "v1/BarConfig/delete",
    DELETEMULTI: "v1/BarConfig/deletemulti",
  },
  REVENUE_BAR_CONFIG: {
    GET_LIST: "v1/BarConfig/getlist",
    SAVE: "v1/BarConfig/save",
    GET_BY_PRIMARY_KEY: "v1/BarConfig/getbyprimarykey",
    DELETE: "v1/BarConfig/delete",
    DELETEMULTI: "v1/BarConfig/deletemulti",
  },

  REVENUE_BUDGET: {
    GET_LIST: "v1/Budget/getlist",
    SAVE: "v1/Budget/save",
    DELETE: "v1/Budget/delete",
    SAVEBYDATE: "v1/Budget/savebydate",
  },

  REVENUE_FORE_CAST: {
    GET_LIST: "v1/Forecast/getlist",
    SAVE: "v1/Forecast/saveuserforcast",
    DELETE: "v1/Forecast/delete",
    SAVEBYDATE: "v1/Forecast/savebydate",
  },

  REVENUE_RATE_SHOP_COMPARISON: {
    GET_LIST: "v1/RateComparison/getlist",
    GET_BY_PRIMARY_KEY: "v1/RateComparison/getbyprimarykey",
    SAVE: "v1/RateComparison/save",
    DELETE: "v1/RateComparison/delete",
  },

  WIDGET: {
    GET_LIST: "v1/Widget/getlist",
    GET_BY_PRIMARY_KEY: "v1/Widget/getbyprimarykey",
    SAVE_GROUP_DATA: "widget/insert_group_data",
  },

  SNAPSHOT: {
    GET_LIST: "v1/Snapshot/getlist",
    GET_LIST_GROUP_BY_TITLE: "v1/Snapshot/getlistgroupbytitle",
    GET_BY_PRIMARY_KEY: "v1/Snapshot/getbyprimarykey",
    SAVE: "v1/Snapshot/save",
    DELETE: "v1/Snapshot/delete",
    STATUS_CHANGE: "v1/Snapshot/statuschange",
    GET_LIST_FOR_MENU: "v1/Snapshot/getlistformenu",
    SHOW_IN_MENU_CHANGE: "v1/Snapshot/isshowinmenuchange",
    SNAPSHOT_MAIL: "v1/Snapshot/snapshotmail",
    EMAIL_SCHEDULE_SAVE: "v1/Snapshot/snapshotemailschedulesave",
    COPY_SNAPSHOT: "v1/Snapshot/copysnapshort",
    EXPORT_WIDGET_WISE_EXCEL: "v1/Snapshot/exportexcelwidgetwise",
    GET_SNAPSHOT_COUNT: "snapshot/count",
    GET_CURRENT_DATE_RESERVATION: "/snapshot/current_date_reservation",
  },

  USER_SNAPSHOT: {
    GET_LIST: "v1/UserSnapshot/getlist",
    SAVE: "v1/UserSnapshot/save",
    DELETE: "v1/UserSnapshot/delete",
  },

  ANNUAL_SUMMARY: {
    ON_THE_BOOK: "snapshot/snp_annsmry_on_the_book",
    NET_STLY: "snapshot/snp_annsmry_net_stly",
    TOTAL_LY: "snapshot/snp_annsmry_total_ly",
    BUDGET: "snapshot/snp_annsmry_budget",
    CHART: "chart/snp_annsmry_chart",
    GET_VARIANCE: "snapshot/snp_annual_summary_variance",
    GET_BUDGET_VARIANCE: "snapshot/snp_annual_summary_variance_budget",
    GET_SYSTEM_FORECAST_VARIANCE:
      "snapshot/snp_annual_summary_variance_forecast",
    GET_USER_FORECAST_VARIANCE:
      "snapshot/snp_annual_summary_variance_user_forecast",
    GET_RMS_FORECAST_VARIANCE:
      "snapshot/snp_annual_summary_variance_rms_forecast",
    SEASONALITY_ANALYSIS: "snapshot/annsmry_on_the_book_ByYear",
    CURRENT_DATE_RESERVATION_DETAIL:
      "snapshot/current_date_reservation_details",
  },

  PICK_UP: {
    ONE_DAY: "snapshot/snp_pickup_one_day",
    ONE_DAY_FORECAST_CHANGE: "snapshot/snp_pickup_one_day_forcast_change",
    SEVEN_DAY: "snapshot/snp_pickup_seven_day",
    SEVEN_DAY_FORECAST_CHANGE: "snapshot/snp_pickup_seven_day_forcast_change",
    ONE_DAY_CHART: "chart/snp_pickup_one_day_chart",
    SEVEN_DAY_CHART: "chart/snp_pickup_seven_day_chart",
    DYNAMIC_PICKUP: "widget/snp_dynamic_pickup",
    SEVEN_DAY_BOOKING_RESERVATION: "widget/seven_day_booking_rooms",
    SEVEN_DAY_BOOKING_EXPECTED: "widget/seven_day_booking_expected",
    SEVEN_DAY_BOOKING_PACE: "widget/seven_day_booking_pace",
    SEVEN_WEEK_BOOKING_PACE: "widget/seven_week_booking_pace",
    THIRTY_DAY_PICKUP: "widget/dbd_30_days_pickup",
    FORECAST_USING_90_DAY_PICKUP: "widget/snp_dynamic_pickup_forecast",
    DYNAMIC_FORECAST_USING_90_DAY_PICKUP: "chart/forecast_using_90days_pickup",
  },

  TRANSIENT_GROUP: {
    GROUP: "snapshot/snp_tg_group",
    GROUP_LAST_YEAR: "snapshot/snp_tg_group_last_year",
    TRANSIENT: "snapshot/snp_tg_transient",
    TRANSIENT_LAST_YEAR: "snapshot/snp_tg_transient_last_year",
    CHART: "chart/snp_tg_chart",
  },

  DAILY_SUMMARY: {
    ORG: "snapshot/snp_ds_otb",
    PICKUP_FROM: "snapshot/snp_ds_pickup",
    RATESHOP_LIST: "rateShop/rate_shop_list_asofdate",
    SINGLE_DAY_GROUP_SUMMARY: "widget/Single_day_group_summary",
    SAVE_UPDATE: "snapshot/snp_notes_update",
    PRICING_FORECAST: "widget/dbd_forecast",
    BAR_BASED_STATS: "snapshot/snp_bar_based_stats",
  },
  DAILY_SUMMARY: {
    ORG: "snapshot/snp_ds_otb",
    PICKUP_FROM: "snapshot/snp_ds_pickup",
    RATESHOP_LIST: "rateShop/rate_shop_list_asofdate",
    SINGLE_DAY_GROUP_SUMMARY: "widget/Single_day_group_summary",
    SAVE_UPDATE: "snapshot/snp_notes_update",
    PRICING_FORECAST: "widget/dbd_forecast",
    BAR_BASED_STATS: "snapshot/snp_bar_based_stats",
    SINGLE_DATE_SUMMARY_DAY_WISE: "snapshot/snp_ds_single_date_summary_daywise",
    STRATEGY_BY_RESERVATION_PICKUP: "snapshot/dbd_pickup",
    STRATEGY_BY_RESERVATION_PICKUP_SINGLE_DATE_SUMMARY:
      "snapshot/dbd_pickup_single_date_summary",
  },

  RESERVATION_ACTIVITY: "snapshot/snp_ra_reservation_activity",

  LOS: {
    LOS_DAY_OF_WEEK: "widget/los_day_of_week_chart",
  },

  HEAT_MAP: "snapshot/snp_hm_heatmap",

  WIDGET_BUDGET: {
    WIDGET_BUDGET: "chart/widget_occ_rms_adr",
  },

  FORECAST: {
    SPIDER_FORECAST: "snapshot/snp_fc_spider",
    RMS_FORECAST: "snapshot/snp_fc_rms",
    USER_FORECAST: "snapshot/snp_fc_user",
    NOVA_FORECAST: "snapshot/snp_fc_nova",
    CHART: "chart/snp_fc_chart",
    EMA_FORECAST_V2: "widget/snp_fc_ema_v2",
    EMA_FORECAST_V3: "widget/snp_fc_ema_v3",
    FORECAST_VS_OTB_CHART: "/rateShop/dbd_room_forecast_vs_otb",
    PAST_FORECAST_CHART: "chart/past_forecast_chart",
  },

  CHOICE_RES: {
    CHOICE_RES: "widget/choice_res",
  },

  MST_RESERVATION: {
    MST_RESERVATION: "widget/mst_reservation",
  },

  DAILY_DATA_TRANSACTION: {
    DAILY_DATA_TRANSACTION: "widget/dailydata_transaction",
  },

  AS_OF_DATE: {
    AS_OF_DATE: "widget/last_data_refresh",
    ALL_LAST_REFRESH_DATE: "crm/lastrefreshdates",
  },

  BOOKING_PACE: {
    CHART: "snapshot/snp_bp_bookingpace",
    CANCELLATION_CHART: "chart/booking_pace_comparison",
    COMPARISON_CHART: "chart/booking_pace_comparison_chart",
  },

  MARKET_SEGMENT: {
    CHART: "chart/marketSegment",
    DAY_BY_DAY_PICKUP: "widget/dbd_ms_pickup",
    DAY_BY_DAY_STLY: "widget/dbd_ms_stly",
    ROOM_TYPE_STLY: "widget/dbd_room_type_stly",
    ROOM_TYPE_PICKUP: "widget/dbd_room_type_pickup",
    GROUP: "widget/dbd_group",
    SINGLE_DATE_ROOM_TYPE_SUMMARY: "widget/dbd_room_type_single_date_summary",
    LENGTH_OF_STAY_PICKUP: "widget/dbd_los_type_pickup",
    LENGTH_OF_STAY_STLY: "widget/dbd_los_type_stly",
    CHANNEL_STLY: "widget/dbd_channel_stly",
    CHANNEL_PICKUP: "widget/dbd_channel_pickup",
    RESERVATION_ANAYLTICS_CHART: "chart/marketsegmentwisereservation_analytic",
    RESERVATION_ANAYLTICS_CHART_NEW:
      "chart/marketsegmentwisereservation_analytic_new",
  },

  WIDGET_MODULE: {
    CHOICE_OCCUPANCY: "widget/choice_occ",
    HOTEL_KEY_RESERVATION: "widget/hotel_key_res",
    HOTEL_KEY_OCCUPANCY_SNAPSHOT: "widget/hotel_key_occ",
    BEST_WESTERN_GUEST_NAME: "widget/bw_guest_name",
    BEST_WESTERN_RESERVATION_AGENT: "widget/bw_res_agent",
    BEST_WESTERN_RESERVATION_FORECAST: "widget/bw_res_forecast",
    BEST_WESTERN_ROOM_MAINTAINCE: "widget/bw_room_maintenance_ooo",
    EVENT: "event/rev_event_list",
    SINGLE_DATE_SUMMARY: "snapshot/snp_ds_single_date_summary",
    CHOICE_CANCELATION_SUMMARY: "widget/choice_cancellation",
    ADR_VS_RATE_CHART: "chart/adr_vs_rate_chart",
    WIDGET_PICKUP: "widget/widget_pickup",
    CHOICE_BUSINESS_ON_THE_BOOK: "widget/choice_busi_on_books",
    CHOICE_REVENUE: "widget/choice_revenue",
    RMS_SINGLE_DATE_SUMMARY: "widget/dbd_ms_single_date_summary",
    REV_YOY_CHART: "chart/rev_yoy_month_chart",
    EVENT_STRETEGY: "event/event_strategy",
  },

  EVENTS: {
    REVENUE_DASHBOARD_CALENDAR_LIST:
      "v1/Events/getrevenuedashboardcalendardatalist",
    GET_LIST: "v1/Events/getlist",
    DELETE: "v1/Events/delete",
    SAVE: "v1/Events/save",
    GET_BY_PRIMARY_KEY: "v1/Events/getbyprimarykey",
    IMPORT: "v1/Events/import",
    GET_ALL_EVENT_SOURCE_ID: "v1/Events/getalleventsourceid",
    GET_EVENT_BY_SINGLE_DATE: "v1/Events/getlistbydate",
    EVENT_RATEIQ: "v1/Events/eventrateiqratevaluechange",
    DELETE_REVENUE_DASHBOARD_EVENT: "v1/Events/deletedashboardcalendardata",
    DATE_WISE_BOOKING: "event/eventdate_wise_booking",
  },

  RATESHOP: {
    RATESHOP_LIST: "rateShop/rate_shop_list",
    COMPETITOR_LIST: "rateShop/competitor_list",
    HISTORY_CHART: "rateShop/dbd_rate_shop_history",
    GET_PERITY_CHECK: "rateShop/channel_parity",
    GET_RATE_EVOLUTION: "rateShop/rate_evolution",
  },

  RATEPLAN: {
    DAY_BY_DAY_STLY: "widget/dbd_rate_plan_stly",
    DAY_BY_DAY_PICKUP: "widget/dbd_rate_plane_pickup",
    TOP_10_CHART: "widget/top_10_rateplane",
  },

  REMINDER: {
    GET_LIST: "v1/Reminder/getlist",
    DELETE: "v1/Reminder/delete",
    SAVE: "v1/Reminder/save",
    GET_BY_PRIMARY_KEY: "v1/Reminder/getbyprimarykey",
    IMPORT: "v1/Reminder/import",
    GET_EVENT_BY_SINGLE_DATE: "v1/Reminder/getlistbydate",
  },

  CRM_PROSPECTS: {
    GET_LIST: "v1/Lead/getlist",
    GET_BY_PRIMARY_KEY_WITH_DETAILS: "v1/Lead/getbyprimarykeywithdetails",
    SAVE: "v1/Lead/save",
    DELETE: "v1/Lead/delete",
    CONVERT_TO_ACCOUNT: "v1/Lead/converttoaccount",
    LEAD_DASHBOARD_COUNT: "v1/Lead/getleaddashboardcount",
  },

  ACTIVITIES: {
    GET_LIST: "v1/Activities/getlist",
    SAVE: "v1/Activities/save",
    DELETE: "v1/Activities/delete",
    GET_BY_PRIMARY_KEY: "v1/Activities/getbyprimarykey",
    GET_BY_PRIMARY_KEY_WITH_DETAILS: "v1/Activities/getbyprimarykeywithdetails",
    GET_ACTIVITY_DASHBOARD_COUNT: "v1/Activities/getactivitydashboardcount",
  },

  SEGMENT_DRILLDOWN: {
    FILTER: "widget/dbd_drilldown_filter",
    GET_MARKETSEGMENT: "widget/dbd_drilldown_marketsegment",
    GET_RATECODE: "widget/dbd_drilldown_ratecode",
    GET_COMPANY: "widget/dbd_drilldown_company",
    GET_GUEST: "widget/dbd_drilldown_guestName",
  },

  REV_PAK_90_DAY_OUTLOOK: {
    GET_LY: "widget/dbd_revpak_90_days_outlook_LY",
    GET_NET_STLY: "widget/dbd_revpak_90_days_outlook_STLY",
    GET_MARKETSEGMENT: "widget/dbd_revpak_90_days_outlook_ms",
    GET_RATECODE: "widget/dbd_revpak_90_days_outlook_ratecode",
    GET_COMPANY: "widget/dbd_revpak_90_days_outlook_company",
    GET_GUEST: "widget/dbd_revpak_90_days_outlook_guestName",
    GET_OUTLOOK_TOTAL: "widget/dbd_revpak_90_days_outlook_Total",
  },

  LAST_YEAR_AND_FORECAST: {
    LAST_YEAR_AND_FORECAST: "widget/dbd_last_year_forecast",
    GET_BUDGET_DATA: "widget/dbd_last_year_forecast_budget",
  },

  CANCELLATION: {
    GET_ANN_SUMMARY: "snapshot/snp_annsmry_cancellation",
    CANCELLATION_PACE: "snapshot/snp_cancellation_pace",
    GET_CY_CANCELLATION_BOOKING_REV_LOSS:
      "widget/dbd_Cancelled_Booking_Revenue_Loss_CY",
    GET_LY_CANCELLATION_BOOKING_REV_LOSS:
      "widget/dbd_Cancelled_Booking_Revenue_Loss_LY",
    GET_STLY_CANCELLATION_BOOKING_REV_LOSS:
      "widget/dbd_Cancelled_Booking_Revenue_Loss_STLY",
    MONTHLY_CANCELLATION_PACE: "widget/dbd_monthly_cancellation_pace",
    GET_CANCELLATION_DAY_BY_DAY: "widget/dbd_cancellation_day_by_day",
  },

  SNAPSHOT_ACCOUNT_WIDGET: {
    GET_FILTER: "widget/dbd_Accounts_filter",
    GET_RATECODE: "widget/dbd_Accounts_ratecode",
    GET_RATECODE_DESCRIPTION: "widget/dbd_Accounts_ratecodedescript",
    GET_COMPANY: "widget/dbd_Accounts_company",
    GET_MARKET_SEGMENT: "widget/dbd_Accounts_marketsegment",
    GET_CHANNEL: "widget/dbd_Accounts_channel",
  },

  REV_PAK_90_DAY_SEGMENT: {
    GET_SEGMENT: "widget/dbd_revpak_90_days_segments",
  },

  TOP_50_BY_REVENUE: {
    GET_TOP_50_BY_REVENUE: "widget/dbd_top_50_revenue",
  },

  RATEIQ: {
    GET_LIST: "widget/dbd_rateshop_iq",
    FORECAST_LIST: "widget/dbd_ml_room",
    GET_CHART: "rateShop/dbd_rate_shop_history_ml_rate",
    GET_RATE_GAIN_DATA_FROM_THIRD_PARTY: "rateShop/get_rates_from_rategain",
  },

  WIDGET_NOTE: {
    SAVE: "v1/WidgetNote/save",
    GET_LIST: "v1/WidgetNote/getlist",
    DELETE: "v1/WidgetNote/delete",
    GET_LIST_PAGINATION: "v1/WidgetNote/getlistpagination",
    GET_BY_DATA_LIST: "v1/WidgetNote/getbydatalist",
    SAVE_BY_ASSOCIATION: "v1/WidgetNote/savebyassociation",
    GET_BY_ASSOCIATION: "v1/WidgetNote/getlistassociation",
  },

  TODO: {
    GET_LIST: "v1/Todo/getlist",
    SAVE: "v1/Todo/save",
    DELETE: "v1/Todo/delete",
    UPDATE_TODO: "v1/Todo/statuschange",
    GET_BY_PRIMARY_KEY: "v1/Todo/getdetailsbyprimarykey",
  },

  RATESHOP_SOLDOUT: {
    GET_LIST: "v1/RateShopSoldoutDetails/getlist",
    SAVE: "v1/RateShopSoldoutDetails/save",
    DELETE: "v1/RateShopSoldoutDetails/delete",
  },

  EMAIL_WIDGET: {
    DAILY_DATA_WIDGET_ONE: "widget/dbd_Daily_Data_Widget1",
    DAILY_DATA_WIDGET_TWO: "widget/dbd_Daily_Data_Widget2",
    DAILY_DATA_WIDGET_THREE: "widget/dbd_Daily_Data_Widget3",
    WEEKLY_DATA_WIDGET_ONE: "widget/dbd_Weekly_Data_Widget1",
    WEEKLY_DATA_WIDGET_TWO: "widget/dbd_Weekly_Data_Widget2",
    WEEKLY_DATA_WIDGET_THREE: "widget/dbd_Weekly_Data_Widget3",
    WEEKLY_MS: "widget/dbd_Weekly_Data_ms",
    WEEKLY_RATECODE: "widget/dbd_Weekly_Data_ratecode",
    WEEKLY_COMPANY: "widget/dbd_Weekly_Data_company",
    WEEKLY_GUESTNAME: "widget/dbd_Weekly_Data_guestName",
    MONTHLY_MS: "widget/dbd_Monthly_Data_ms",
    MONTHLY_RATECODE: "widget/dbd_Monthly_Data_ratecode",
    MONTHLY_COMPANY: "widget/dbd_Monthly_Data_company",
    MONTHLY_GUESTNAME: "widget/dbd_Monthly_Data_guestName",
  },
  CRM_REPORT: {
    DASHBOARD_WEEKLY_REPORT: "v1/Report/DashBoardWeeklyReport",
    ACTIVITIES_REPORT: "v1/Report/ActivitiesReportList",
    ACTIVITY_GOAL_REPORT: "v1/Report/ActivityGoalsReportList",
    LOST_BUSSINESS_GRID_REPORT: "v1/Report/LostBusinessGridReportList",
    MONTHLY_ROLL_UP_REPORT: "v1/Report/MonthlyRollupReportList",
    TRACE_REPORT: "v1/Report/TraceReportList",
    SALES_FUNNEL_GRID_REPORT: "v1/Report/SalesFunnnelGridReportList",
    MEETING_AND_CATERING_BY_DATE_REPORT:
      "v1/Report/MeetingCateringByDateReportList",
    RFP_GRID_REPORT: "v1/Report/RFPGridReportList",
    LNR_GRID_REPORT: "v1/Report/LNRGridReportList",
    GROUP_GRID_REPORT: "v1/Report/GroupGridReportList",
    MEETING_CATERING_GRID_REPORT: "v1/Report/MeetingCateringGridReportList",
    MARKETING_PLAN_REPORT: "v1/Report/MarketingPlanReport",
    OPPORTUNITY_BY_SOURCE_REPORT: "v1/Report/OpportunityBySourceReportList",
    GROUP_ROOM_COUNT_REPORT: "v1/Report/GroupRoomCountReportList",
    MEETING_ROOM_BOOKED_REPORT: "v1/Report/MeetingRoomBookedReportList",
    GROUP_PACE_REPORT: "v1/Report/GroupPaceReport",
    MEETING_PACE_REPORT: "v1/Report/MeetingPaceReport",
    LEAD_REPORT: "v1/Report/LeadReport",
    WEEKLY_REPORT: "v1/Report/WeeklyReport",
  },
  STR_REPORT_REVPAK: {
    GET_RUNNING_STR_REPORT: "widget/snp_running_28_star",
    GET_WEEKLY_REPORT: "widget/snp_weekly_star",
    RUNNING_STR_SAVE: "widget/insert_and_update_running_28_star",
    WEEKLY_STR_SAVE: "widget/insert_and_update_weekly_star",
    READ_STR_ANALYSIS: "v1/STRAnalysis/readstranalysisfile",
    GET_MONTHLY_REPORT: "widget/snp_monthly_star",
  },

  SCHEDULE_REVENUE: {
    SAVE: "v1/Schedule/save",
    GET_DETAIL_BYID: "v1/Schedule/getdetailbyid",
  },

  RATEIQ_WEEKDAY: {
    GET_LIST: "v1/RateIQ_WeekDays/getlist",
    SAVE: "v1/RateIQ_WeekDays/save",
  },

  RATEIQ_CUSTOMER_TYPE: {
    GET_LIST: "v1/RateIQ_CustomerType/getlist",
    SAVE: "v1/RateIQ_CustomerType/save",
    DELETE: "v1/RateIQ_CustomerType/delete",
  },

  RATEIQ_LOS: {
    GET_LIST: "v1/RateIQ_Los/getlist",
    SAVE: "v1/RateIQ_Los/save",
    DELETE: "v1/RateIQ_Los/delete",
  },

  RATEIQ_YIELD: {
    GET_LIST: "v1/RateIQ_Yield/getlist",
    SAVE: "v1/RateIQ_Yield/save",
    GET_OCCUPANCY: "rateShop/rateiq_yeild_occupancy",
    GET_OCCUPANCY_BY_DATES: "rateShop/rateiq_yeild_occupancy_bydates",
  },

  STR: {
    DAY_BY_DAY: "widget/dbd_str",
  },

  MONTHLY_SEGMENT_DRILLDOWN: {
    GET_MARKETSEGMENT: "widget/dbd_monthly_drilldown_ms",
    GET_RATECODE: "widget/dbd_monthly_drilldown_ratecode",
    GET_COMPANY: "widget/dbd_monthly_drilldown_company",
    GET_GUEST: "widget/dbd_monthly_drilldown_guestname",
  },

  DISPLACEMENT: {
    GET_LIST: "widget/dbd_displacement",
    NEW_DISPLACEMENT_LIST: "widget/dbd_displacement_new",
  },

  PROPERTY_NOTIFICATION_CONFIG: {
    GET_LIST: "v1/PropertyNotificationConfig/getlist",
    SAVE: "v1/PropertyNotificationConfig/save",
  },

  CRM_SNAPSHOT: {
    REVENUE_BY_COMPANY: "crm/revenue_by_company",
    REVENUE_BY_ROOMTYPE: "crm/revenue_by_roomType",
    REVENUE_BY_MARKETSEGMENT: "crm/revenue_by_marketsegment",
    CANCELLATION_ACTIVITY: "crm/Cancellation_activity",
    COMPANY_DRILLDOWN: "crm/dbd_company_monthly_drilldown",
    TODAY_ARRIVAL: "crm/today_arrial",
    TODAY_DEPARTURE: "crm/today_departure",
    GET_FLIGHT_DATA: "crm/get_flight_data",
    GET_NEAR_BY_AIRPORT: "crm/get_nearby_airport",
    ANNUAL_SUMMARY_CHAT_BOT: "crm/annual_summary_keypoint",
    OTHER_SUMMARY_CHAT_BOT: "crm/onesummery",
    GET_PROPERTY_STATUS: "crm/property_status",
    GET_THREAD_FOR_CHAT: "crm/threadcreate",
    CREATE_CRM_ASSITANT: "crm/createassistant",
  },

  ZOOM_INFO: {
    SEARCH_COMPANY: "v1/ZoomInfo/searchcompany",
    COMAPNY_DETAILS: "v1/ZoomInfo/companydetails",
    CONTACT_DETAILS: "v1/ZoomInfo/contactdetails",
  },

  CHANNEL_SEGMENT_DRILLDOWN: {
    GET_MONTHLY_CHANNEL: "widget/dbd_channel_monthly_drilldown",
    GET_MONTHLY_RATECODE: "widget/dbd_channel_monthly_drilldown_ratecode",
    GET_MONTHLY_COMPANY: "widget/dbd_channel_monthly_drilldown_company",
    GET_MONTHLY_GUEST: "widget/dbd_channel_monthly_drilldown_guestname",
  },

  REVPAK_EXCEL_EXPORT: {
    GET_EXCEL_FILE: "v1/ExportToExcel/getexcelfile",
    GET_WIDGET_WISE_EXCEL: "v1/ExportToExcel/exportexcelwidgetwise",
  },

  SNAPSHOT_LOG: {
    GET_LOG: "widget/rms_job_list_log",
    GET_WIDGET_DETAILS: "widget/rms_job_widget_status",
  },

  OTHER_NOTES: {
    SAVE: "v1/OtherNotes/save",
  },

  FLIGHT: {
    NEARBYAIRPORT: "v1/Flight/nearbyairport",
    AIRPORTTIMETABLESEARCHMODEL: "v1/Flight/airporttimetablesearchmodel",
  },

  PORTFOLIO_ROLLUP: {
    WIDGET_COUNT: "portfolio/widget_count",
    MONTHLY_SUMMARY_CHART: "portfolio/monthly_summary",
    MONTHLY_SUMMARY_TABLE: "portfolio/monthly_summary_by_dates",
    MARKET_SEGMENT_WISE_REVENUE: "portfolio/marketsegment_wise_monthly_chart",
    DAY_BY_DAY: "portfolio/portfolio_day_by_day",
    PORTFOLIO_RATE: "portfolio/portfolio_rate",
    PORTFOLIO_SUMMARY: "portfolio/portfolio_reports",
  },

  ARCHIVE: {
    GETLIST: "v1/archivedata/getlist",
    SAVE: "v1/archivedata/save",
    GETARCHIVEHTML: "v1/archivedata/getarchivehtml",
  },

  BOOKING_WINDOW: {
    MONTHLY_BOOKING_PACE: "widget/dbd_monthly_booking_pace",
    DAY_BY_DAY_STLY: "widget/dbd_booking_day_by_day_stly",
    DAY_BY_DAY_PICKUP: "widget/dbd_booking_day_by_day_pickup",
  },

  ANALYTICS: {
    GOOGLE_RATINGS: "chart/propertywise_google_ratings",
    REVIEWS: "chart/propertywise_google_reviews",
    RATES: "chart/propertywise_google_rates",
    RATING_COUNT: "chart/propertywise_google_rates_count",
    SENTIMATE_REVIEW_COUNT: "chart/propertywise_google_sentimentreview_count",
  },

  REVENUE_GLANCE: {
    DASHBOARD: "dashboard/dashboard_revglance",
    COMP_RATE_VARIANCE_WITH_OCC_SELF:
      "dashboard/comp_rate_variance_with_occ_self",
    HIGH_DEMAND_DATES: "dashboard/highDemandDates",
    LOW_DEMAND_DATES: "dashboard/lowDemandDates",
    TOP_10_EVENT_STRETERGY: "dashboard/top_10_event_strategy",
    CREATE_THREAD_FOR_MODULE: "chatbot/createthreadformodule",
    SEND_MSG_FOR_MODULE: "chatbot/sendmessageformodule",
    TOP_10_MARKET_SEGMENT_DRILLDOWN: "dashboard/top10marketsegment_drilldown",
    TOP_RATECODE_DRILLDOWN: "dashboard/topratecode_drilldown",
  },
};
