import moment, { weekdays } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { apiResponse } from "../../utils/apiResponse";
import { API_URL } from "../../utils/apiRoutes";
import { apiCall } from "../../utils/axiosService";
import { commonService } from "../../utils/commonService";
import { Col, Container, Row } from "react-bootstrap";
import tentIcon from "../../Assets/Images/Revenue-management/circus-tent.svg";
import ToolsEventModal from "./ToolsEventModal";
import EventViewModal from "../CommonComponent/EventViewModal";

const EventWeekView = () => {
  let date_today = new Date();

  let first_day_of_the_week = new Date(
    date_today.setDate(date_today.getDate() - date_today.getDay())
  );

  let last_day_of_the_week = new Date(
    date_today.setDate(date_today.getDate() - date_today.getDay() + 6)
  );

  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );
  const [weekRange, setWeekRange] = useState({
    start: first_day_of_the_week,
    end: last_day_of_the_week,
  });
  const [eventList, setEventList] = useState([]);
  const [calenderEventList, setCalenderEventList] = useState(null);
  const nodeEventListRef = useRef([]);
  const dbEventListRef = useRef([]);
  const calenderNodeEventListRef = useRef([]);
  const calenderDbEventListRef = useRef([]);
  const eventDetailRef = useRef();
  const importEventDetailRef = useRef();
  const [showEventModal, setShowEventModal] = useState(false);
  const allEventDataRef = useRef([]);
  const [allEvents, setAllEvents] = useState(null);
  const allEventSourceIdFromDbRef = useRef();
  const eventListPropsRef = useRef();
  const selectedEventDateRef = useRef();

  const closeEventModal = () => {
    setShowEventModal(false);
  };

  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const getEventFromNodeSide = async () => {
    let eventListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.WIDGET_MODULE.EVENT,
        body: {
          propertyCode: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertycode
            : propertyDetailFromRTK?.propertycode,
          startDate: "",
          endDate: "",
          category: "",
          rankFrom: "",
          rankTo: "",
          attendanceFrom: "",
          attendanceTo: "",
          localrankFrom: "",
          localrankTo: "",
        },
      },
      false
    );

    let response = apiResponse(false, eventListResponse);

    if (response?.isValidate) {
      nodeEventListRef.current = response?.data?.data;
      let dataForCalender = response?.data?.data?.map((item, index) => {
        return {
          id: item?.event_id,
          title: item?.title,
          start: moment(item?.start_date).format("YYYY-MM-DD"),
          end: moment(item?.end_date).format("YYYY-MM-DD"),
          backgroundColor: "#E5F5FF",
          textColor: "black",
        };
      });
      calenderNodeEventListRef.current = dataForCalender;
      setEventList([...dbEventListRef.current, ...response?.data?.data]);
      setCalenderEventList([
        ...dataForCalender,
        ...calenderDbEventListRef.current,
      ]);
    }
  };

  const getEventFromDb = async () => {
    let eventListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: `${new Date(weekRange?.start).getFullYear()}`,
        },
      },
      false
    );

    let response = apiResponse(false, eventListResponse);

    if (response?.isValidate) {
      let filterdData = response?.data?.data?.filter(
        (x) => x?.eventsourceid === null
      );
      let importEventData = response?.data?.data?.filter(
        (x) => x?.eventsourceid !== null
      );
      importEventDetailRef.current = importEventData;
      dbEventListRef.current = filterdData;
      let dataForCalender = filterdData?.map((item, index) => {
        return {
          id: item?.eventid,
          title: item?.eventname,
          start: moment(item?.startdate).format("YYYY-MM-DD"),
          end: moment(item?.enddate).format("YYYY-MM-DD"),
          backgroundColor: "#E5F5FF",
          textColor: "black",
        };
      });
      calenderDbEventListRef.current = dataForCalender;
      setEventList([...nodeEventListRef.current, ...filterdData]);
      setCalenderEventList([
        ...dataForCalender,
        ...calenderNodeEventListRef.current,
      ]);
    }
  };

  const getEventSourceIdFromDb = async () => {
    let eventSourceIdResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.EVENTS.GET_ALL_EVENT_SOURCE_ID,
        body: {
          id: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, eventSourceIdResponse);

    if (response?.isValidate) {
      let splitArray = response?.data?.data?.split(",");
      allEventSourceIdFromDbRef.current = splitArray;
    }

    if (!response?.isValidate) {
      allEventSourceIdFromDbRef.current = null;
    }
  };

  const getEventFromNode = async () => {
    let nodeEventListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.WIDGET_MODULE.EVENT,
        body: {
          propertyCode: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertycode
            : propertyDetailFromRTK?.propertycode,
          startDate: "",
          endDate: "",
          category: "",
          rankFrom: "",
          rankTo: "",
          attendanceFrom: "",
          attendanceTo: "",
          localrankFrom: "",
          localrankTo: "",
        },
      },
      false
    );

    let response = apiResponse(false, nodeEventListResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let eventNotExistsInDb = [];
      dataFromApi?.forEach((item) => {
        let eventExistsInDb = allEventSourceIdFromDbRef.current?.filter(
          (x) => x === item?.event_id
        );
        if (eventExistsInDb?.length <= 0) {
          let singleEventObj = {
            id: item?.event_id,
            name: item?.title,
            detail: item?.description,
            startDate: item?.start_date,
            endDate: item?.end_date,
            start: item?.start_date,
            end: item?.end_date,
            title: item?.title,
            labels: item?.labels,
            rank: item?.rank ? item?.rank : 0,
            local_rank: item?.local_rank ? item?.local_rank : 0,
            phq_attendance: item?.phq_attendance ? item?.phq_attendance : 0,
            geo_lat: item?.geo_lat ? item?.geo_lat : "",
            geo_long: item?.geo_long ? item?.geo_long : "",
            backgroundColor: "#e1e8ff",
            textColor: "black",
            className: "poiter-event-none",
            eventJson: item,
          };
          eventNotExistsInDb.push(singleEventObj);
        }
      });
      allEventDataRef.current = [
        ...allEventDataRef.current,
        ...eventNotExistsInDb,
      ];
      setAllEvents(allEventDataRef.current);
    }

    if (!response?.isValidate) {
    }
  };

  const getEventFromDotNet = async () => {
    let eventListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: `${new Date(weekRange?.start).getFullYear()}`,
        },
      },
      false
    );

    let response = apiResponse(false, eventListResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let eventsList = [];

      dataFromApi?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          start: item?.startdate,
          end: item?.enddate,
          title: item?.eventname,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
          backgroundColor: "#ffebeb",
          textColor: "black",
          className: "poiter-event-none",
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventsList.push(singleEventObj);
      });

      allEventDataRef.current = [...allEventDataRef.current, ...eventsList];
      setAllEvents(allEventDataRef.current);
    }

    if (!response?.isValidate) {
    }
  };

  const fnNextPrev = (value) => {
    let startDate = "",
      endDate = "";
    if (value === "next") {
      startDate = new Date(weekRange.start);
      endDate = new Date(weekRange?.end);

      startDate.setDate(startDate.getDate() + 7);
      endDate.setDate(endDate.getDate() + 7);
    } else {
      startDate = new Date(weekRange.start);
      endDate = new Date(weekRange?.end);

      startDate.setDate(startDate.getDate() - 7);
      endDate.setDate(endDate.getDate() - 7);
    }
    setWeekRange({
      start: startDate,
      end: endDate,
    });
  };

  const getAllEventDetails = async () => {
    allEventDataRef.current = [];
    await getEventSourceIdFromDb();
    // getEventFromNode();
    getEventFromDotNet();
  };

  useEffect(() => {
    // getEventFromNodeSide();
  }, [propertyDetailFromRTK, crmPropertyDetailFromRTK]);

  useEffect(() => {
    // getEventFromDb();
    getAllEventDetails();
  }, [propertyDetailFromRTK, crmPropertyDetailFromRTK]);

  return (
    <div className="RmEventsWeekViewMain">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <div className="calender_buttons mt-md-4 mt-sm-4">
              <div className="text_start">
                <button
                  className="prev btn_css"
                  onClick={() => {
                    fnNextPrev("prev");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chevron-left"
                    width="21"
                    height="21"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#ffffff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M15 6l-6 6l6 6"></path>
                  </svg>
                </button>
                <div className="text_center">
                  <h4>{`${moment(weekRange.start).format(
                    "MMMM DD, yyyy"
                  )} - ${moment(weekRange.end).format("MMMM DD, yyyy")}`}</h4>
                </div>
                <button
                  className="next btn_css"
                  onClick={() => {
                    fnNextPrev("next");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chevron-right"
                    width="21"
                    height="21"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#ffffff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M9 6l6 6l-6 6"></path>
                  </svg>
                </button>
              </div>
            </div>
          </Col>
          <Col xl={12}>
            <div className="RmEventsWeekViewInner">
              {weekDays?.map((item, index) => {
                let currentDate = new Date(weekRange?.start);
                currentDate.setDate(currentDate.getDate() + index);

                let currentDateEventList = allEvents?.filter((x) => {
                  let checkingDate = moment(
                    moment(currentDate).format("MM/DD/YYYY")
                  );
                  let startingDate = moment(
                    moment(x?.startDate).format("MM/DD/YYYY")
                  );
                  let endingDate = moment(
                    moment(x?.endDate).format("MM/DD/YYYY")
                  );
                  if (
                    checkingDate.isSameOrAfter(startingDate) &&
                    checkingDate.isSameOrBefore(endingDate)
                  ) {
                    return x;
                  }
                });
                return (
                  <div className="WeekViewDayMain">
                    <h5 key={item}>{item}</h5>
                    <div className="WeekViewDayInner">
                      <div className="WeekViewDayEventWrapper">
                        <h6 className="WeekViewDayDate">
                          {currentDate.getDate()}
                        </h6>
                        <div className="WeekViewDayEventCardWrapper">
                          {currentDateEventList?.map(
                            (eventItem, eventIndex) => {
                              return (
                                <div
                                  className="RmToolEventCardMain"
                                  style={{ background: "#E5F5FF" }}
                                  onClick={() => {
                                    selectedEventDateRef.current = currentDate;
                                    eventListPropsRef.current = [eventItem];
                                    setShowEventModal(true);
                                  }}
                                >
                                  <img src={tentIcon} alt="tentIcon" />
                                  <p>{eventItem?.title}</p>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
      {showEventModal && (
        <EventViewModal
          showModal={showEventModal}
          closeModal={closeEventModal}
          eventDetails={eventListPropsRef.current}
          selectedDate={selectedEventDateRef.current}
          getEventList={getAllEventDetails}
        />
      )}
    </div>
  );
};

export default EventWeekView;
