import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Row, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import { colValue, compName, month } from "../../../../utils/constant";
import Loader from "../../../Loader/Loader";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import moment from "moment";
import DatePicker from "react-datepicker";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import WidgetNote from "../../WidgetNote";
import WidgetDetailModal from "../../WidgetDetailModal";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import { errorToastMessage } from "../../../../utils/toastMessage";

const ForecastCommon = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const widgetDetailRef = useRef();
  const userDetail = commonService.getLoginUserData();
  const [widgetModal, setWidgetModal] = useState(false);
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const OTBTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0 },
  ];
  const EmaTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
  ];
  const RmsTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
  ];
  const UserTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
  ];
  const NovaTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
  ];
  const budgetTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0 },
  ];

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [OTBData, setOTBData] = useState(null);
  const [emaData, setEmaData] = useState(null);
  const [rmsData, setRmsData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [novaData, setNovaData] = useState(null);
  const [budgetData, setBudgetData] = useState(null);

  const [selectAsOfDate, setSelectAsOfDate] = useState();

  const [otbLoading, setOtbLoading] = useState(false);
  const [emaLoading, setEmaLoading] = useState(false);
  const [rmsLoading, setRmsLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [novaLoading, setNovaLoading] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  let otbocc = 0,
    otbrms = 0,
    otbadr = 0,
    otbrev = 0,
    emaOcc = 0,
    emaRms = 0,
    emaAdr = 0,
    emaRev = 0,
    emaTableVar = 0,
    rmsOcc = 0,
    rmsRms = 0,
    rmsAdr = 0,
    rmsRev = 0,
    rmsTableVar = 0,
    UserOcc = 0,
    UserRms = 0,
    UserAdr = 0,
    UserRev = 0,
    UserTableVar = 0,
    NovaOcc = 0,
    NovaRms = 0,
    NovaAdr = 0,
    NovaRev = 0,
    NovaTableVar = 0,
    budgetOcc = 0,
    budgetRms = 0,
    budgetAdr = 0,
    budgetRev = 0;

  const getOnTheBookDetail = async () => {
    setOtbLoading(true);
    let onTheBookResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.ANNUAL_SUMMARY.ON_THE_BOOK,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, onTheBookResponse);
    if (response?.isValidate) {
      setOTBData(response?.data?.data);
      setOtbLoading(false);
    }

    if (!response?.isValidate) {
      setOTBData(OTBTableData);
      setOtbLoading(false);
    }
  };
  const getEmaDetail = async () => {
    setEmaLoading(true);
    let emaDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.SPIDER_FORECAST,
        body: {
          AsOfDate: selectAsOfDate
            ? moment(selectAsOfDate).format("YYYY-MM-DD")
            : para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, emaDetailResponse);
    if (response?.isValidate) {
      setEmaData(response?.data?.data);
      setEmaLoading(false);
    }

    if (!response?.isValidate) {
      setEmaData(EmaTableData);
      setEmaLoading(false);
    }
  };
  const getRmsDetail = async () => {
    setRmsLoading(true);
    let rmsDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.RMS_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, rmsDetailResponse);
    if (response?.isValidate) {
      setRmsData(response?.data?.data);
      setRmsLoading(false);
    }

    if (!response?.isValidate) {
      setRmsData(RmsTableData);
      setRmsLoading(false);
    }
  };
  const getUserDetail = async () => {
    setUserLoading(true);
    let userDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.USER_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, userDetailResponse);
    if (response?.isValidate) {
      setUserData(response?.data?.data);
      setUserLoading(false);
    }

    if (!response?.isValidate) {
      setUserData(UserTableData);
      setUserLoading(false);
    }
  };
  const getNovaDetail = async () => {
    setNovaLoading(true);
    let novaDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.NOVA_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, novaDetailResponse);
    if (response?.isValidate) {
      setNovaData(response?.data?.data);
      setNovaLoading(false);
    }

    if (!response?.isValidate) {
      setNovaData(NovaTableData);
      setNovaLoading(false);
    }
  };
  const getBudgetDetail = async () => {
    setBudgetLoading(true);
    let budgetDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.ANNUAL_SUMMARY.BUDGET,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, budgetDetailResponse);
    if (response?.isValidate) {
      setBudgetData(response?.data?.data);
      setBudgetLoading(false);
    }

    if (!response?.isValidate) {
      setBudgetData(budgetTableData);
      setBudgetLoading(false);
    }
  };

  const [tblids, settblids] = useState({
    ForecastMonth: commonService.GenerateGUID(),
    onThebookWidgetFC: commonService.GenerateGUID(),
    emaWidgetFC: commonService.GenerateGUID(),
    rmsWidgetFC: commonService.GenerateGUID(),
    userWidgetFC: commonService.GenerateGUID(),
    novaWidgetFC: commonService.GenerateGUID(),
    budgetWidgetFC: commonService.GenerateGUID(),
  });

  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.onThebookWidgetFC} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.emaWidgetFC} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.budgetWidgetFC} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.userWidgetFC} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.novaWidgetFC} .perbackground-rev`
  );

  useEffect(() => {
    if (api === 0) {
      setOTBData(OTBTableData);
      setRmsData(RmsTableData);
      setUserData(UserTableData);
      setNovaData(NovaTableData);
      setBudgetData(budgetTableData);
    } else {
      if (para?.asOfDate) {
        getOnTheBookDetail();
        getRmsDetail();
        getUserDetail();
        getNovaDetail();
        getBudgetDetail();
      }
    }
  }, [para?.asOfDate, selectedYear]);

  useEffect(() => {
    if (api === 0) {
      setEmaData(EmaTableData);
    } else {
      if (para?.asOfDate) {
        getEmaDetail();
      }
    }
  }, [para?.asOfDate, selectedYear, selectAsOfDate]);

  let AllWidgetContainer = document.getElementById("ForecastAllWidgetInSingle");
  if (AllWidgetContainer !== null) {
    let CW = AllWidgetContainer.clientWidth;
    CW = CW - 120;
    CW = Math.round(CW / 5);
    if (CW < 250) {
      CW = 250;
    }
    let Widget = AllWidgetContainer.getElementsByClassName(
      "allTableTogather-widget"
    );
    if (Widget !== null && Widget.length !== 0) {
      for (let i = 0; i < Widget.length; i++) {
        Widget[i].style.width = CW + "px";
      }
    }
  }

  const handleExport = async (type) => {
    let tableId = document.querySelector("#ForecastAllWidgetInSingle");

    if (tableId.querySelector(".loding-animation")) {
      errorToastMessage("Wait for the data loading.");
      return;
    }

    let groupingJson = {
      data: [],
      filename: `${para?.propertyCode}-AnnualSummary-${moment(
        new Date()
      ).format("MMDDYYYY")}`,
    };

    let groupingRowData = [
      {
        widgetname: "ForecastCommon",
        rowdata: [],
      },
    ];
    let totalJson = [
      {
        widgetname: "BlankCommon",
        rowdata: [{ data: ["Total"] }],
      },
    ];

    let rowItem = document.querySelector("#ForecastAllWidgetInSingle");

    groupingRowData[0].rowdata.push({
      data: para?.rowTitle ? [para?.rowTitle] : [],
    });
    groupingRowData[0].rowdata.push({
      data: [],
    });

    let findMonthTable = rowItem?.querySelector(
      ".allTableTogatherWrapper .ViewPropertySetupTable table"
    );

    let findMonthTableHeader = findMonthTable?.querySelectorAll("thead tr");
    let findMonthTableTbody = findMonthTable?.querySelectorAll("tbody tr");

    findMonthTableHeader?.forEach((tblHeaderItem, tblHeaderIndex) => {
      if (tblHeaderIndex === 0) {
        let text = tblHeaderItem.querySelector("tr th span").innerText;
        groupingRowData[0].rowdata.push({
          data: [text ? text : ""],
        });
      } else {
        let text = tblHeaderItem.querySelector("tr th").innerText;
        groupingRowData[0].rowdata.push({
          data: [text ? text : ""],
        });
      }
    });

    findMonthTableTbody?.forEach((tblBodyItem, tblBodyIndex) => {
      let text = tblBodyItem.querySelector("td").innerText;

      if (findMonthTableTbody?.length - 1 === tblBodyIndex) {
      } else {
        groupingRowData[0].rowdata.push({
          data: [text ? text : ""],
        });
      }
    });

    const getAllTableData = (tbl) => {
      let findMonthTable = tbl?.querySelector(
        ".allTableTogather-widget .ViewPropertySetupTable table"
      );

      let findMonthTableHeader = findMonthTable?.querySelectorAll("thead tr");
      let findMonthTableTbody = findMonthTable?.querySelectorAll("tbody tr");

      findMonthTableHeader.forEach((tblHeaderItem, tblHeaderIndex) => {
        let tblTh = tblHeaderItem.querySelectorAll("tr th");
        let tblSpan = "";
        if (rowIndex === 0) {
          tblSpan = tblHeaderItem.querySelector("tr th span");
        }

        tblTh?.forEach((tblThItem) => {
          let datePickerExists = tblThItem.querySelector(
            ".react-datepicker-wrapper"
          );

          if (!datePickerExists) {
            let text = tblSpan ? tblSpan?.innerText : tblThItem?.innerText;
            groupingRowData[0].rowdata[tblHeaderIndex + 2].data.push(
              text ? (text === "ema" ? "SYSTEM" : text) : ""
            );
          }
        });
      });

      findMonthTableTbody.forEach((tblBodyItem, tblBodyIndex) => {
        let tblTd = tblBodyItem.querySelectorAll("td");

        tblTd?.forEach((tblTdItem, tblTdIndex) => {
          let text = tblTdItem?.innerText;
          if (findMonthTableTbody?.length - 1 === tblBodyIndex) {
            totalJson[0].rowdata[0].data.push(text ? text : "");
          } else {
            groupingRowData[0].rowdata[tblBodyIndex + 4].data.push(
              text ? text : ""
            );
          }
        });
      });
    };

    let findOtherTable = rowItem?.querySelectorAll(
      ".allTableTogather .allTableTogather-widget"
    );

    findOtherTable?.forEach((otherTblItem) => {
      getAllTableData(otherTblItem);
    });

    groupingJson.data = [...groupingJson.data, ...groupingRowData];
    groupingJson.data = [...groupingJson.data, ...totalJson];
    groupingJson = {
      ...groupingJson,
      snapshotid: snapshotId ? parseInt(snapshotId) : 0,
      sendtoemails: userDetail?.email ? userDetail?.email : "",
      actiontype: type,
      asofdata: para?.asOfDate
        ? commonService.getDateInDBFormat(para?.asOfDate)
        : "",
    };
    await commonService.widgetWiseExportExcel(groupingJson);
  };

  const handleForecastChat = () => {
    let tableId = document?.querySelector(`#ForecastAllWidgetInSingle`);

    if (tableId?.querySelector(".loding-animation")) {
      errorToastMessage("Wait for the data loading.");
      return;
    }
    let groupingJson = {
      propertyid: para?.propertyDetail?.propertyid,
      clientid: userDetail?.clientid,
      userid: userDetail?.userid,
      prompt: "Please generate summary",
      json: {},
    };

    let getAllTable = tableId?.querySelectorAll(
      ".allTableTogather .allTableTogather-widget .ViewPropertySetupTable .stripped__table table"
    );

    getAllTable?.forEach((tableItem, tableIndex) => {
      let getTbody = tableItem?.querySelectorAll("tbody tr");
      let tableName = "On The Books";
      if (tableIndex === 1) {
        tableName = "System Forecast";
      } else if (tableIndex === 2) {
        tableName = "RMS Forecast";
      } else if (tableIndex === 3) {
        tableName = "USER Forecast";
      } else if (tableIndex === 4) {
        tableName = "Budget";
      }
      let arr = [];
      const removeSymbol = (value) => {
        let data = value?.replaceAll("$", "");
        let removePercentage = data?.replaceAll("%", "");
        let finalValue = removePercentage?.replaceAll(",", "");
        return finalValue;
      };

      getTbody?.forEach((tbodyItem, tbodyIndex) => {
        if (tbodyIndex !== getTbody?.length - 1) {
          let getAllTds = tbodyItem?.querySelectorAll("td");
          let obj = {
            propertyCode: para?.propertyCode,
            AsOfDate: para?.asOfDate,
            year: selectedYear,
            month: month[tbodyIndex],
          };

          getAllTds?.forEach((tdItem, tdIndex) => {
            if (tbodyIndex !== getTbody?.length - 1) {
              if (tdIndex == 0) {
                obj.occ = removeSymbol(tdItem?.innerText);
              } else if (tdIndex === 1) {
                obj.rms = removeSymbol(tdItem?.innerText);
              } else if (tdIndex === 2) {
                obj.adr = removeSymbol(tdItem?.innerText);
              } else if (tdIndex === 3) {
                obj.rev = removeSymbol(tdItem?.innerText);
              }
            }
          });
          arr.push(obj);
        }
      });
      groupingJson.json[tableName] = arr;
    });
  };

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                if (
                  window.location.href.includes(
                    "Annual%20Summary%20(RevPAK)"
                  ) ||
                  window.location.href.includes("RevPAK%20Summary")
                ) {
                  handleExport("EMAIL");
                } else {
                  commonService.fnSendWidgetMail(
                    tblids.ForecastMonth +
                      "," +
                      tblids.onThebookWidgetFC +
                      "," +
                      tblids.emaWidgetFC +
                      "," +
                      tblids.rmsWidgetFC +
                      "," +
                      tblids.userWidgetFC +
                      "," +
                      tblids.budgetWidgetFC,
                    "Forecast",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.forecastCommon,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={
                tblids.ForecastMonth +
                "," +
                tblids.onThebookWidgetFC +
                "," +
                tblids.emaWidgetFC +
                "," +
                tblids.rmsWidgetFC +
                "," +
                tblids.userWidgetFC +
                "," +
                tblids.budgetWidgetFC
              }
              onClick={() => {
                if (
                  window.location.href.includes(
                    "Annual%20Summary%20(RevPAK)"
                  ) ||
                  window.location.href.includes("RevPAK%20Summary")
                ) {
                  handleExport("DOWNLOAD");
                } else {
                  commonService.fnExportInCSV(
                    tblids.ForecastMonth +
                      "," +
                      tblids.onThebookWidgetFC +
                      "," +
                      tblids.emaWidgetFC +
                      "," +
                      tblids.rmsWidgetFC +
                      "," +
                      tblids.userWidgetFC +
                      "," +
                      tblids.budgetWidgetFC,
                    // "Forecast"
                    `${para?.propertyCode}-Forecast-${moment(new Date()).format(
                      "MMDDYYYY"
                    )}`
                  );
                }
              }}
            />
          </Button>
        </div>
      )}
      <Row>
        <div
          className="d-flex allTableTogatherWrapper"
          id="ForecastAllWidgetInSingle"
        >
          <div style={{ width: "110px" }} className="pe-0">
            <div className="ViewPropertySetupTable monthSelectTable">
              <div className="stripped__table">
                <Table id={tblids.ForecastMonth} responsive>
                  <thead>
                    <tr>
                      <th>
                        <div>
                          <Form.Select
                            value={selectedYear}
                            onChange={(e) => {
                              setSelectedYear(e.target.value);
                            }}
                            style={{ padding: 0 }}
                          >
                            <option value="">Select</option>
                            {yearsArr?.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                          <span style={{ display: "none" }}>
                            {selectedYear}
                          </span>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>Month</th>
                    </tr>
                  </thead>
                  <tbody>
                    {month?.map((item, index) => (
                      <tr key={item}>
                        <td>{item}</td>
                      </tr>
                    ))}
                    <tr>
                      <td className="boldTd">Total</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="allTableTogather">
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.onThebookWidgetFC} responsive>
                    <thead>
                      <tr>
                        <th colSpan={4} className="text-center">
                          On The Books
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        OTBData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === OTBData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : otbLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        OTBData &&
                        month?.map((item, index) => {
                          let findMonthData = OTBData?.filter(
                            (x) => x.month === item
                          );

                          if (findMonthData?.[0]?.occ) {
                            otbocc = otbocc + findMonthData?.[0]?.occ;
                          } else {
                            otbocc = otbocc + 0;
                          }
                          if (findMonthData?.[0]?.rms) {
                            otbrms = otbrms + findMonthData?.[0]?.rms;
                          } else {
                            otbrms = otbrms + 0;
                          }
                          if (findMonthData?.[0]?.adr) {
                            otbadr = otbadr + findMonthData?.[0]?.adr;
                          } else {
                            otbadr = otbadr + 0;
                          }
                          if (findMonthData?.[0]?.rev) {
                            otbrev = otbrev + findMonthData?.[0]?.rev;
                          } else {
                            otbrev = otbrev + 0;
                          }

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.occ
                                    ? findMonthData?.[0]?.occ
                                    : 0}
                                  %
                                </td>
                                <td className="text-center">
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(
                                        findMonthData?.[0]?.rms
                                      )
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.adr
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.rev
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              {index === 11 && (
                                <tr key={index}>
                                  <td className="boldTd">
                                    {parseInt(otbocc / 12)}%
                                  </td>
                                  <td className="boldTd text-center">
                                    {commonService.formateRoom(otbrms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {otbrev !== 0 && otbrms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(otbrev / otbrms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(otbrev)}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.emaWidgetFC} responsive>
                    <thead>
                      <tr>
                        <th>
                          <DatePicker
                            id="asOfDate"
                            selected={commonService.convertDateInTimezone(
                              selectAsOfDate ? selectAsOfDate : para?.asOfDate
                            )}
                            onChange={(date) => {
                              setSelectAsOfDate(date);
                            }}
                            // maxDate={para?.asOfDate}
                            className="width100"
                            placeholderText="As Of Date"
                          />
                        </th>
                        <th colSpan={3} className="text-center">
                          System Forecast
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                        {/* <th>VAR</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        emaData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                              {/* <td>{item?.var}%</td> */}
                            </tr>
                            {index === emaData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                                {/* <td className="boldTd">0%</td> */}
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : emaLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        emaData &&
                        month?.map((item, index) => {
                          let findMonthData = emaData?.filter(
                            (x) => x.month === item
                          );

                          emaOcc =
                            emaOcc +
                            (findMonthData?.[0]?.occ
                              ? findMonthData?.[0]?.occ
                              : 0);
                          emaRms =
                            emaRms +
                            (findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0);
                          emaAdr =
                            emaAdr +
                            (findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0);
                          emaRev =
                            emaRev +
                            (findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0);
                          emaTableVar =
                            emaTableVar +
                            (findMonthData?.[0]?.var
                              ? findMonthData?.[0]?.var
                              : 0);

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.occ
                                    ? findMonthData?.[0]?.occ
                                    : 0}
                                  %
                                </td>
                                <td className="text-center">
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(
                                        findMonthData?.[0]?.rms
                                      )
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.adr
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.rev
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                {/* <td>
                                  {findMonthData?.[0]?.var
                                    ? findMonthData?.[0]?.var
                                    : 0}
                                  %
                                </td> */}
                              </tr>
                              {index === 11 && (
                                <tr>
                                  <td className="boldTd">
                                    {emaOcc > 0 ? parseInt(emaOcc / 12) : 0}%
                                  </td>
                                  <td className="boldTd text-center">
                                    {commonService.formateRoom(emaRms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {emaRev !== 0 && emaRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(emaRev / emaRms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(emaRev)}
                                  </td>
                                  {/* <td className="boldTd">
                                    {emaTableVar > 0
                                      ? parseInt(emaTableVar / 12)
                                      : 0}
                                    %
                                  </td> */}
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.rmsWidgetFC} responsive>
                    <thead>
                      <tr>
                        <th colSpan={4} className="text-center">
                          RMS Forecast
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                        {/* <th className="text-center">VAR</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        rmsData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                              {/* <td className="text-center">{item?.var}%</td> */}
                            </tr>
                            {index === rmsData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                                {/* <td className="boldTd text-center">0%</td> */}
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : rmsLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        rmsData &&
                        month?.map((item, index) => {
                          let findMonthData = rmsData?.filter(
                            (x) => x.month === item
                          );

                          rmsOcc = rmsOcc + findMonthData?.[0]?.occ;
                          rmsRms = rmsRms + findMonthData?.[0]?.rms;
                          rmsAdr = rmsAdr + findMonthData?.[0]?.adr;
                          rmsRev = rmsRev + findMonthData?.[0]?.rev;
                          rmsTableVar = rmsTableVar + findMonthData?.[0]?.var;

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>{findMonthData?.[0]?.occ}%</td>
                                <td className="text-center">
                                  {commonService.formateRoom(
                                    findMonthData?.[0]?.rms
                                  )}
                                </td>
                                <td className="text-end">
                                  {commonService.formateAmount(
                                    findMonthData?.[0]?.adr
                                  )}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {commonService.formateAmount(
                                    findMonthData?.[0]?.rev
                                  )}
                                </td>
                                {/* <td className="text-center">
                                  {findMonthData?.[0]?.var}%
                                </td> */}
                              </tr>
                              {index === rmsData?.length - 1 && (
                                <tr>
                                  <td className="boldTd">
                                    {parseInt(rmsOcc / 12)}%
                                  </td>
                                  <td className="boldTd text-center">
                                    {commonService.formateRoom(rmsRms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {rmsRev !== 0 && rmsRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(rmsRev / rmsRms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(rmsRev)}
                                  </td>
                                  {/* <td className="boldTd text-center">
                                    {parseInt(rmsTableVar / 12)}%
                                  </td> */}
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.userWidgetFC} responsive>
                    <thead>
                      <tr>
                        <th colSpan={4} className="text-center">
                          User Forecast
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                        {/* <th className="text-center">VAR</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        userData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                              {/* <td className="text-center">{item?.var}%</td> */}
                            </tr>
                            {index === userData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                                {/* <td className="boldTd text-center">0%</td> */}
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : userLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        userData &&
                        month?.map((item, index) => {
                          let findMonthData = userData?.filter(
                            (x) => x.month === item
                          );

                          UserOcc =
                            UserOcc +
                            (findMonthData?.[0]?.occ
                              ? findMonthData?.[0]?.occ
                              : 0);
                          UserRms =
                            UserRms +
                            (findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0);
                          UserAdr =
                            UserAdr +
                            (findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0);
                          UserRev =
                            UserRev +
                            (findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0);
                          UserTableVar =
                            UserTableVar +
                            (findMonthData?.[0]?.var
                              ? findMonthData?.[0]?.var
                              : 0);

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.occ
                                    ? findMonthData?.[0]?.occ
                                    : 0}
                                  %
                                </td>
                                <td className="text-center">
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(
                                        findMonthData?.[0]?.rms
                                      )
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.adr
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.rev
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                {/* <td className="text-center">
                                  {findMonthData?.[0]?.var}%
                                </td> */}
                              </tr>
                              {index === 11 && (
                                <tr>
                                  <td className="boldTd">
                                    {parseInt(UserOcc / 12)}%
                                  </td>
                                  <td className="boldTd text-center">
                                    {commonService.formateRoom(UserRms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {UserRev !== 0 && UserRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(UserRev / UserRms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(UserRev)}
                                  </td>
                                  {/* <td className="boldTd text-center">
                                    {parseInt(UserTableVar / 12)}%
                                  </td> */}
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.novaWidgetFC} responsive>
                    <thead>
                      <tr>
                        <th colSpan={4} className="text-center">
                          Nova Forecast
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        novaData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === novaData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : novaLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        novaData &&
                        month?.map((item, index) => {
                          let findMonthData = novaData?.filter(
                            (x) => x.month?.trim() === item?.trim()
                          );

                          NovaOcc =
                            NovaOcc +
                            (findMonthData?.[0]?.occ
                              ? findMonthData?.[0]?.occ
                              : 0);
                          NovaRms =
                            NovaRms +
                            (findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0);
                          NovaAdr =
                            NovaAdr +
                            (findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0);
                          NovaRev =
                            NovaRev +
                            (findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0);
                          NovaTableVar =
                            NovaTableVar +
                            (findMonthData?.[0]?.var
                              ? findMonthData?.[0]?.var
                              : 0);

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.occ
                                    ? findMonthData?.[0]?.occ
                                    : 0}
                                  %
                                </td>
                                <td className="text-center">
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(
                                        findMonthData?.[0]?.rms
                                      )
                                    : commonService.formateRoom(0)}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.adr
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.rev
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              {index === 11 && (
                                <tr>
                                  <td className="boldTd">
                                    {parseInt(NovaOcc / 12)}%
                                  </td>
                                  <td className="boldTd text-center">
                                    {commonService.formateRoom(NovaRms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {NovaRev !== 0 && NovaRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(NovaRev / NovaRms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(NovaRev)}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.budgetWidgetFC} responsive>
                    <thead>
                      <tr>
                        <th colSpan={4} className="text-center">
                          Budget
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">REV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        budgetData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === budgetData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : budgetLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        budgetData &&
                        month?.map((item, index) => {
                          let findMonthData = budgetData?.filter(
                            (x) => x.month?.trim() === item
                          );

                          budgetOcc =
                            budgetOcc +
                            (findMonthData?.[0]?.occ
                              ? findMonthData?.[0]?.occ
                              : 0);
                          budgetRms =
                            budgetRms +
                            (findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0);
                          budgetAdr =
                            budgetAdr +
                            (findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0);
                          budgetRev =
                            budgetRev +
                            (findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0);

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  {findMonthData?.[0]?.occ
                                    ? findMonthData?.[0]?.occ
                                    : 0}
                                  %
                                </td>
                                <td className="text-center">
                                  {findMonthData?.[0]?.rms
                                    ? commonService.formateRoom(
                                        findMonthData?.[0]?.rms
                                      )
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {findMonthData?.[0]?.adr
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.adr
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td
                                  className="text-end perbackground perbackground-rev"
                                  value={findMonthData?.[0]?.rev}
                                >
                                  {findMonthData?.[0]?.rev
                                    ? commonService.formateAmount(
                                        findMonthData?.[0]?.rev
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              {index === 11 && (
                                <tr key={index}>
                                  <td className="boldTd">
                                    {budgetOcc > 0
                                      ? parseInt(budgetOcc / 12)
                                      : 0}
                                    %
                                  </td>
                                  <td className="boldTd text-center">
                                    {commonService.formateRoom(budgetRms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {budgetRev !== 0 && budgetRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(budgetRev / budgetRms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(budgetRev)}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
      {api !== 0 && (
        <Comments widgetDetails={para} editorId={"ForecastCommon"} />
      )}

      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default ForecastCommon;
