import React, { useEffect, useRef, useState } from "react";
import { commonService } from "../../utils/commonService";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import premonth from "../../Assets/Images/Revenue-management/preMonth.svg";
import nextmonth from "../../Assets/Images/Revenue-management/nextMonth.svg";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import { apiCall } from "../../utils/axiosService";
import smallTent from "../../Assets/Images/Revenue-management/smallTent.svg";
import ToolsEventModal from "./ToolsEventModal";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import tentIcon from "../../Assets/Images/Revenue-management/circus-tent.svg";
import EventViewModal from "../CommonComponent/EventViewModal";
const lib = ["places"];

const EventMonthView = ({ showData }) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );

  const date = new Date();
  const startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const endDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );

  const [eventList, setEventList] = useState([]);
  const [activeMonth, setActiveMonth] = useState({
    start: "",
    end: "",
    current: "",
  });
  const [calenderEventList, setCalenderEventList] = useState(null);
  const [showEventModal, setShowEventModal] = useState(false);
  const [markers, setMarkers] = useState(null);
  const [eventDetail, setEventDetail] = useState(null);
  const [nodeEventList, setNodeEventList] = useState([]);
  const [dbEventList, setDbEventList] = useState([]);
  const [importEventDetail, setImportEventDetail] = useState(null);
  const [calenderNodeEventList, setCalenderNodeEventList] = useState([]);
  const [calenderDbEventList, setCalenderDbEventList] = useState([]);
  const [centerPosition, setCenterPosition] = useState({
    lat: 38.21347,
    lng: -90.407188,
  });
  const [propertyDetail, setPropertyDetail] = useState(null);
  const allEventSourceIdFromDbRef = useRef();
  const eventFromNodeRef = useRef();
  const eventFromDotNetRef = useRef();
  const allEventDataRef = useRef([]);
  const [allEvents, setAllEvents] = useState(null);
  const selectedEventDateRef = useRef();
  const eventListPropsRef = useRef();
  const calendarRef = useRef();
  const [eventOfDb, setEventOfDb] = useState(null);
  const [eventOfNode, setEventOfNode] = useState(null);

  const closeEventModal = () => {
    setShowEventModal(false);
  };

  const getEventSourceIdFromDb = async () => {
    let eventSourceIdResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.EVENTS.GET_ALL_EVENT_SOURCE_ID,
        body: {
          id: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, eventSourceIdResponse);

    if (response?.isValidate) {
      let splitArray = response?.data?.data?.split(",");
      allEventSourceIdFromDbRef.current = splitArray;
    }

    if (!response?.isValidate) {
      allEventSourceIdFromDbRef.current = null;
    }
  };

  const getEventFromDotNet = async (data=[]) => {
    let eventListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: activeMonth?.current
            ? `${new Date(activeMonth?.current).getFullYear()}`
            : `${new Date(startDate).getFullYear()}`,
        },
      },
      false
    );

    let response = apiResponse(false, eventListResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let eventsList = [];

      dataFromApi?.forEach((item) => {
        let startDateForUi = moment(item?.startdate).format("YYYY-MM-DD");
        let endDateForUi = moment(item?.enddate).format("YYYY-MM-DD");
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          start: moment(item?.startdate).format("YYYY-MM-DD"),
          end: `${
            startDateForUi === endDateForUi
              ? `${moment(item?.enddate).format("YYYY-MM-DD")}`
              : `${moment(item?.enddate).format("YYYY-MM-DD")}T01:00:00`
          }`,
          title: item?.eventname,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
          backgroundColor: "#ffebeb",
          textColor: "black",
          className: "poiter-event-none",
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventsList.push(singleEventObj);
      });

      // allEventDataRef.current = [...allEventDataRef.current, ...eventsList];
      allEventDataRef.current = [...data, ...eventsList];
      setAllEvents(allEventDataRef.current);
    }

    if (!response?.isValidate) {
    }
  };

  const getEventFromNode = async () => {
    let nodeEventListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.WIDGET_MODULE.EVENT,
        body: {
          propertyCode: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertycode
            : propertyDetailFromRTK?.propertycode,
          startDate: "",
          endDate: "",
          category: "",
          rankFrom: "",
          rankTo: "",
          attendanceFrom: "",
          attendanceTo: "",
          localrankFrom: "",
          localrankTo: "",
        },
      },
      false
    );

    let response = apiResponse(false, nodeEventListResponse);
    let eventNotExistsInDb = [];
    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;

      dataFromApi?.forEach((item) => {
        let eventExistsInDb = allEventSourceIdFromDbRef.current?.filter(
          (x) => x === item?.event_id
        );
        if (eventExistsInDb?.length <= 0) {
          let startDateForUi = moment(item?.start_date).format("YYYY-MM-DD");
          let endDateForUi = moment(item?.end_date).format("YYYY-MM-DD");
          let singleEventObj = {
            id: item?.event_id,
            name: item?.title,
            detail: item?.description,
            startDate: item?.start_date,
            endDate: item?.end_date,
            start: moment(item?.start_date).format("YYYY-MM-DD"),
            end: `${
              startDateForUi === endDateForUi
                ? `${moment(item?.end_date).format("YYYY-MM-DD")}`
                : `${moment(item?.end_date).format("YYYY-MM-DD")}T01:00:00`
            }`,
            title: item?.title,
            labels: item?.labels,
            rank: item?.rank ? item?.rank : 0,
            local_rank: item?.local_rank ? item?.local_rank : 0,
            phq_attendance: item?.phq_attendance ? item?.phq_attendance : 0,
            geo_lat: item?.geo_lat ? item?.geo_lat : "",
            geo_long: item?.geo_long ? item?.geo_long : "",
            backgroundColor: "#e1e8ff",
            textColor: "black",
            className: "poiter-event-none",
            eventJson: item,
          };
          eventNotExistsInDb.push(singleEventObj);
        }
      });
      // allEventDataRef.current = [
      //     ...allEventDataRef.current,
      //     ...eventNotExistsInDb,
      // ];
      // setAllEvents(allEventDataRef.current);
      getEventFromDotNet(eventNotExistsInDb);
    }

    if (!response?.isValidate) {
      getEventFromDotNet(eventNotExistsInDb);
    }
  };

  const getAllEventDetails = async () => {
    allEventDataRef.current = [];
    await getEventSourceIdFromDb();
    // getEventFromNode();
    getEventFromDotNet([])
  };

  const handleDateClick = (info) => {
    let checkEvent = allEvents?.filter((x) => {
      let checkingDate = moment(info?.date);
      let startingDate = moment(x?.startDate);
      let endingDate = moment(x?.endDate);
      if (
        checkingDate.isSameOrAfter(startingDate) &&
        checkingDate.isSameOrBefore(endingDate)
      ) {
        return x;
      }
    });
    eventListPropsRef.current = checkEvent;
    selectedEventDateRef.current = info?.date;
    if (checkEvent?.length > 0) {
      setShowEventModal(true);
    }
  };

  const handleEventClick = (info) => {
    let event = info.event;
    let findSingleEvent = allEvents?.filter((x) => x?.id == event?.id);
    selectedEventDateRef.current = info.event.startStr;
    eventListPropsRef.current = findSingleEvent;

    setShowEventModal(true);
  };

  useEffect(() => {
    if (activeMonth?.start) {
      getAllEventDetails();
    }
  }, [activeMonth, propertyDetailFromRTK, crmPropertyDetailFromRTK]);

  useEffect(() => {
    allEventDataRef.current = [];
  }, [activeMonth]);

  const MARKER_OFFSET = 0.1;

  const marketDataGetByClick = (id, position) => {
    let AllDIV = document.getElementsByClassName("RmEventDetailCardMain");
    if (AllDIV !== null && AllDIV.length > 0) {
      for (let i = 0; i < AllDIV.length; i++) {
        AllDIV[i].style.background = "#f3f6ff";
      }
    }
    if (document.getElementById(id) !== null) {
      document.getElementById(id).scrollIntoView();
      document.getElementById(id).style.background = "rgb(255, 235, 235)";
    }
  };

  return (
    <div className="RMRateShopMain">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <div className="fullcalendar-container event-fullcalendar mt-md-4 mt-sm-4">
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: "",
                  center: "prev,title,next",
                  right: "",
                }}
                buttonIcons={false}
                buttonText={{
                  prev: "",
                  next: "",
                }}
                initialView="dayGridMonth"
                themeSystem="standard"
                eventClick={handleEventClick}
                dateClick={(arg) => {
                  handleDateClick(arg);
                }}
                events={allEvents}
                dayMaxEvents={3}
                datesSet={(dateInfo) => {
                  setActiveMonth({
                    start: dateInfo.startStr,
                    end: dateInfo.endStr,
                    current: dateInfo?.view?.currentStart,
                  });
                }}
                displayEventTime={false}
              />
            </div>
          </Col>
        </Row>
        {showEventModal && (
          <EventViewModal
            showModal={showEventModal}
            closeModal={closeEventModal}
            eventDetails={eventListPropsRef.current}
            selectedDate={selectedEventDateRef.current}
            getEventList={getAllEventDetails}
          />
        )}
      </Container>
    </div>
  );
};

export default EventMonthView;
