import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Button, Form, Overlay, Popover, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import SingleDateSummaryModal from "../../SingleDateSummaryModal";
import DatePicker from "react-datepicker";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import darkNoteIcon from "../../../../Assets/Images/CRM/darkNotes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import Loader from "../../../Loader/Loader";
import moment from "moment";
import { errorToastMessage } from "../../../../utils/toastMessage";
import ticket from "../../../../Assets/Images/Revenue-management/tableTicket.svg";
import ToolsSingleTicketPopUp from "../../../CommonComponent/ToolsSingleTicketPopUp";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import { FixColumn } from "../../../../utils/dataTable";
import EventViewModal from "../../../CommonComponent/EventViewModal";

const Group = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  let ooo = 0,
    RmsAvailable = 0,
    leftToSell = 0,
    otb = 0,
    occPercentage = 0,
    adr = 0,
    rev = 0,
    revPar = 0,
    groupBlock = 0,
    groupBlockVar = 0,
    groupOtb = 0,
    groupOtbVar = 0,
    groupRev = 0,
    groupAdr = 0,
    forecastRms = 0;

  const date = commonService.convertDateInTimezone(new Date());
  const startDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth(), 1)
    )
  );
  const endDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    )
  );
  const [groupData, setGroupData] = useState(null);
  const [otbData, setOtbData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [otbLoading, setOtbLoading] = useState(false);
  const [selectPickupDate, setSelectPickupDate] = useState();
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [maxDateForPickupFrom, setMaxDateForPickupFrom] = useState();
  const [ONTHEBOOKS, setONTHEBOOKS] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const [selectedMarketSegment, setSelectedMarketSegment] = useState("");
  const [marketSegmentData, setMarketSegmentData] = useState(null);
  const dateRangeObj = useRef();
  const maxDateRef = useRef();
  const ratePlanData = useRef();
  const selectedTdIndexRef = useRef();
  const singleDateSelectedDateRef = useRef();
  const singleDatePickupDateRef = useRef();
  const ratePlanTotal = useRef();
  const [forecastData, setForecastData] = useState(null);
  const [forecastLoading, setForecastLoading] = useState(null);
  const [selectedForecast, setSelectedForecast] = useState("EMA");
  const [noteLoading, setNoteLoading] = useState(false);
  const dateForNoteRef = useRef();
  const userNoteRef = useRef();
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const selectedEventDateRef = useRef();
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const clickedDateForNote = useRef();
  const [noteDetail, setNoteDetail] = useState(null);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const closeNoteModal = () => {
    setAddNoteModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const [showTicketModal, setShowTicketModal] = useState(false);
  if (maxDateForPickupFrom) {
    let date = new Date(maxDateForPickupFrom);
    maxDateRef.current = date.setDate(date.getDate() - 1);
  }

  const handleFilterBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setONTHEBOOKS({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };
  const closeSingleDate = () => {
    selectedTdIndexRef.current = -1;
    setONTHEBOOKS({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  const htmlToText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    const plainText = tempElement.innerText;
    return plainText;
  };

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = commonService.getDateForNode(
        commonService.convertDateInTimezone(startSelectedDate)
      );
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = commonService.getDateForNode(
        commonService.convertDateInTimezone(endSelectedDate)
      );
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // setGroupData(null);
      // setForecastData(null);
      // dateRangeObj.current = commonService.datesBetweenTwoDate(
      //   startRangeDateRef.current ? startRangeDateRef.current : startDate,
      //   endRangeDateRef.current ? endRangeDateRef.current : endDate
      // );
      // getGroupDetail();
      // getORGDetail();
      // getForecastDetail();
    }
  };

  const diffDayStartDate = new Date(
    commonService.getDateInFormat(
      startRangeDateRef.current ? startRangeDateRef.current : startDate
    )
  );
  const diffDayEndDate = new Date(
    commonService.getDateInFormat(
      endRangeDateRef.current ? endRangeDateRef.current : endDate
    )
  );
  const diffDayInTime = diffDayEndDate.getTime() - diffDayStartDate.getTime();
  const diffDays = diffDayInTime / (1000 * 3600 * 24);

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!eventListResponse?.isValidate) {
      eventListRef.current = [];
    }
  };

  const getORGDetail = async () => {
    setOtbLoading(true);

    await getEventList();

    let orgDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.ORG,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, orgDetailResponse);

    if (response?.isValidate) {
      setOtbData(response?.data?.data);
      setOtbLoading(false);
    }

    if (!response?.isValidate) {
      setOtbLoading(false);
    }
  };

  const getGroupDetail = async () => {
    setLoading(true);
    let groupDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.MARKET_SEGMENT.GROUP,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, groupDetailResponse);

    if (response?.isValidate) {
      setLoading(false);
      setGroupData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setGroupData([]);
    }
  };

  const getForecastDetail = async () => {
    setForecastLoading(true);
    let forecastResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.DAILY_SUMMARY.PRICING_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
          ForcastType: selectedForecast,
        },
      },
      false
    );

    let response = apiResponse(false, forecastResponse);

    if (response?.isValidate) {
      setForecastData(response?.data?.data);
      setForecastLoading(false);
    }

    if (!response?.isValidate) {
      setForecastData([]);
      setForecastLoading(false);
    }
  };

  const saveNote = async () => {
    let saveResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.SAVE_UPDATE,
        body: {
          propertyCode: para?.propertyCode,
          Dates: dateForNoteRef.current,
          UserNotes: userNoteRef.current,
        },
      },
      false
    );

    let response = apiResponse(true, saveResponse, setNoteLoading);

    if (!response?.isValidate) {
      errorToastMessage(response?.data?.message);
    }
  };

  const getNoteByDataList = async () => {
    let noteByDataListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.WIDGET_NOTE.GET_BY_DATA_LIST,
        body: {
          propertyid: propertyDetailFromRTK?.propertyid,
          // widgetid: para?.widgetId,
          widgetid: 0,
          startdate: startRangeDateRef.current
            ? commonService.getDateInDBFormat(startRangeDateRef.current)
            : commonService.getDateInDBFormat(startDate),
          enddate: endRangeDateRef.current
            ? commonService.getDateInDBFormat(endRangeDateRef.current)
            : commonService.getDateInDBFormat(endDate),
        },
      },
      false
    );

    let response = apiResponse(false, noteByDataListResponse);

    if (response?.isValidate) {
      setNoteDetail(response?.data?.data);
    }

    if (!response?.isValidate) {
      setNoteDetail(null);
    }
  };

  useEffect(() => {
    if (api === 0) {
      dateRangeObj.current = commonService.datesBetweenTwoDate(
        startRangeDateRef.current
          ? commonService.convertDateInTimezone(startRangeDateRef.current)
          : commonService.convertDateInTimezone(startDate),
        endRangeDateRef.current
          ? commonService.convertDateInTimezone(endRangeDateRef.current)
          : commonService.convertDateInTimezone(endDate)
      );
      let d = new Date(para?.asOfDate);
      d.setDate(d.getDate() - 1);
      singleDatePickupDateRef.current = commonService.convertDateInTimezone(d);
      setMaxDateForPickupFrom(new Date(para?.asOfDate));
      setOtbData([]);
      setGroupData([]);
    } else {
      if (para?.asOfDate) {
        dateRangeObj.current = commonService.datesBetweenTwoDate(
          startRangeDateRef.current
            ? commonService.convertDateInTimezone(startRangeDateRef.current)
            : commonService.convertDateInTimezone(startDate),
          endRangeDateRef.current
            ? commonService.convertDateInTimezone(endRangeDateRef.current)
            : commonService.convertDateInTimezone(endDate)
        );
        let d = new Date(para?.asOfDate);
        d.setDate(d.getDate() - 7);
        singleDatePickupDateRef.current =
          commonService.convertDateInTimezone(d);
        setMaxDateForPickupFrom(new Date(para?.asOfDate));
        getORGDetail();
        getGroupDetail();
        getForecastDetail();
        getNoteByDataList();
      }
    }
  }, [para?.asOfDate, selectedForecast]);

  const [tblids, settblids] = useState({
    tblDayByDayGroup: commonService.GenerateGUID(),
  });

  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.tblDayByDayGroup} .groupEvent-otb`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblDayByDayGroup} .groupEvent-adr`
  );
  commonService.setBackColorPerByTDValue(
    "#fdaf0e",
    "Red",
    `#${tblids.tblDayByDayGroup} .groupEvent-groupBlock`
  );
  commonService.setBackColorPerByTDValue(
    "#fdaf0e",
    "Red",
    `#${tblids.tblDayByDayGroup} .groupEvent-groupOtb`
  );

  FixColumn(tblids.tblDayByDayGroup, [1]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                clickedDateForNote.current = "";
                // setShowNoteSidebar(true);
                setAddNoteModal(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblDayByDayGroup,
                  "Day By Day Group",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.dayByDayGroup,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblDayByDayGroup}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblDayByDayGroup,
                  // "Day By Day Group"
                  `${para?.propertyCode}-Day By Day Group-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table id={tblids.tblDayByDayGroup}>
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  <th>Date</th>
                  <th className="text-center border-right" colSpan={5}>
                    On The Books
                  </th>
                  <th className="text-center border-right" colSpan={2}>
                    Revenue
                  </th>
                  <th className="text-center border-right" colSpan={2}></th>
                  <th className="border-right text-center" colSpan={6}>
                    Group
                  </th>
                  <th className="border-right"></th>
                </tr>
                <tr className="table-main-header-tr">
                  <th
                    className="width200"
                    onClick={() => {
                      setOpenDatePicker(true);
                    }}
                  >
                    Date{" "}
                    <DatePicker
                      onChange={(event) => handleDateChange(event)}
                      startDate={startRangeDate}
                      endDate={endRangeDate}
                      selectsRange
                      monthsShown={2}
                      open={openDatePicker}
                      shouldCloseOnSelect={false}
                    >
                      <div
                        className="w-100"
                        style={{ display: "inline-block" }}
                      >
                        <div className="content-inside-calendar">
                          <p>
                            {startRangeDate
                              ? commonService.getDateInFormat(startRangeDate)
                              : ""}
                            {startRangeDate && endRangeDate ? " - " : ""}
                            {endRangeDate
                              ? commonService.getDateInFormat(endRangeDate)
                              : ""}
                          </p>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="secondary-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setStartRangeDate("");
                                setEndRangeDate("");
                                setOpenDatePicker(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={
                                startRangeDate && endRangeDate ? false : true
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  startRangeDateRef.current &&
                                  endRangeDateRef.current
                                ) {
                                  setGroupData(null);
                                  setForecastData(null);
                                  dateRangeObj.current =
                                    commonService.datesBetweenTwoDate(
                                      startRangeDateRef.current
                                        ? commonService.convertDateInTimezone(
                                            startRangeDateRef.current
                                          )
                                        : commonService.convertDateInTimezone(
                                            startDate
                                          ),
                                      endRangeDateRef.current
                                        ? commonService.convertDateInTimezone(
                                            endRangeDateRef.current
                                          )
                                        : commonService.convertDateInTimezone(
                                            endDate
                                          )
                                    );
                                  getGroupDetail();
                                  getORGDetail();
                                  getForecastDetail();
                                  getNoteByDataList();
                                }

                                setOpenDatePicker(false);
                              }}
                              className="primary-btn"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </DatePicker>
                    {startRangeDate && endRangeDate ? (
                      <span
                        style={{ display: "none" }}
                      >{`${commonService.getDateInFormat(
                        startRangeDate
                      )} - ${commonService.getDateInFormat(
                        endRangeDate
                      )}`}</span>
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="width100 border-right">OOO/Rooms Available</th>
                  <th className="width100 text-center">Left To sell</th>
                  <th className="width100 text-center">On The Books</th>
                  <th className="">Total OCC%</th>
                  <th className="text-end text-center">ADR</th>
                  <th className="border-right text-end">Rev</th>
                  <th className="text-end">RevPAr</th>
                  <th className="border-right text-end">Rate</th>
                  <th className="text-center" style={{ width: "62px" }}>
                    <div>
                      <Form.Select
                        value={selectedForecast}
                        onChange={(e) => {
                          setSelectedForecast(e.target.value);
                        }}
                      >
                        <option value="EMA">SYSTEM</option>
                        <option value="RMS">RMS</option>
                        <option value="User">USER</option>
                        <option value="NOVA">NOVA</option>
                      </Form.Select>
                      <span style={{ display: "none" }}>
                        {selectedForecast}
                      </span>
                    </div>
                  </th>
                  <th className="border-right text-center">Block</th>
                  <th className="text-center">Block Var</th>
                  <th className="text-center">OTB</th>
                  <th className="text-center">OTB Var</th>
                  <th className="text-end">REV</th>
                  <th className="text-end">ADR</th>
                  <th className="border-right text-center">Notes</th>
                </tr>
              </thead>
              <tbody>
                {loading === true ||
                otbLoading === true ||
                forecastLoading === true ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  // otbData &&
                  // groupData &&
                  // forecastData &&
                  dateRangeObj.current &&
                  Object.keys(dateRangeObj.current)?.map((item, index) => {
                    let singleOTBData = otbData?.filter(
                      (x) => x?.Date === item
                    );
                    let singleGroupData = groupData?.filter(
                      (x) =>
                        moment(x?.Dates).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                    );
                    let singleForecastData = forecastData?.filter(
                      (x) => x?.Dates === item
                    );
                    let dayName = false;

                    ooo = ooo + singleOTBData?.[0]?.OOO;
                    RmsAvailable =
                      RmsAvailable + singleOTBData?.[0]?.RoomAvailable;
                    leftToSell = leftToSell + singleOTBData?.[0]?.LeftToSell;
                    otb = otb + singleOTBData?.[0]?.OnTheBook;
                    occPercentage =
                      occPercentage + singleOTBData?.[0]?.TotalOCCPercentage;
                    adr = adr + singleOTBData?.[0]?.ADR;
                    rev = rev + singleOTBData?.[0]?.REV;
                    revPar = revPar + singleOTBData?.[0]?.RevPAR;
                    if (singleForecastData?.[0]?.RMS) {
                      forecastRms =
                        forecastRms + parseInt(singleForecastData?.[0]?.RMS);
                    }
                    if (singleGroupData?.[0]?.GroupBlock) {
                      groupBlock =
                        groupBlock + parseInt(singleGroupData?.[0]?.GroupBlock);
                    }
                    if (singleGroupData?.[0]?.["Block Var"]) {
                      groupBlockVar =
                        groupBlockVar +
                        parseInt(singleGroupData?.[0]?.["Block Var"]);
                    }
                    if (singleGroupData?.[0]?.GroupOTB) {
                      groupOtb =
                        groupOtb + parseInt(singleGroupData?.[0]?.GroupOTB);
                    }
                    if (singleGroupData?.[0]?.["OTB Var"]) {
                      groupOtbVar =
                        groupOtbVar +
                        parseInt(singleGroupData?.[0]?.["OTB Var"]);
                    }
                    if (singleGroupData?.[0]?.GroupRevenue) {
                      groupRev =
                        groupRev + parseInt(singleGroupData?.[0]?.GroupRevenue);
                    }
                    if (singleGroupData?.[0]?.GroupADR) {
                      groupAdr =
                        groupAdr + parseInt(singleGroupData?.[0]?.GroupADR);
                    }

                    if (
                      moment(
                        commonService.convertDateInTimezone(item),
                        "l"
                      ).format("ddd") === "Fri" ||
                      moment(
                        commonService.convertDateInTimezone(item),
                        "l"
                      ).format("ddd") === "Sat"
                    ) {
                      dayName = true;
                    }

                    let checkEvent = eventListRef.current?.filter((x) => {
                      let checkingDate = moment(item);
                      let startingDate = moment(x?.startDate);
                      let endingDate = moment(x?.endDate);
                      if (
                        checkingDate.isSameOrAfter(startingDate) &&
                        checkingDate.isSameOrBefore(endingDate)
                      ) {
                        return x;
                      }
                    });

                    let checkNoteExists = noteDetail?.notedata?.filter(
                      (x) =>
                        moment(x?.datadate).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                    );

                    return (
                      <React.Fragment key={item}>
                        <tr
                          key={item}
                          className={`${
                            dayName ? "backgroundTr" : ""
                          } shadowOnHover`}
                        >
                          <td>
                            {/* {commonService.getDateInFormat(item)}{" "}
                          {moment(new Date(item), "l").format("ddd")} */}
                            <div className="d-flex gap-4">
                              {commonService.getDateInFormat(
                                commonService.convertDateInTimezone(item)
                              )}{" "}
                              {moment(
                                commonService.convertDateInTimezone(item),
                                "l"
                              ).format("ddd")}
                              <div className="d-flex justify-content-between align-items-center gap-2 ticketAlarmReload">
                                {checkEvent?.length > 0 && (
                                  <img
                                    src={ticket}
                                    alt="ticket"
                                    onClick={() => {
                                      selectedEventDateRef.current = item;
                                      eventListPropsRef.current = checkEvent;
                                      setShowTicketModal(true);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="width100 border-right">
                            {singleOTBData?.[0]?.OOO
                              ? commonService.formateRoom(
                                  singleOTBData?.[0]?.OOO
                                )
                              : 0}
                            /
                            {singleOTBData?.[0]?.RoomAvailable
                              ? commonService.formateRoom(
                                  singleOTBData?.[0]?.RoomAvailable
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {singleOTBData?.[0]?.LeftToSell
                              ? commonService.formateRoom(
                                  singleOTBData?.[0]?.LeftToSell
                                )
                              : 0}
                          </td>
                          <td
                            className="text-primary text-center perbackground groupEvent-otb"
                            value={singleOTBData?.[0]?.OnTheBook}
                          >
                            <span
                              className={`${
                                selectedTdIndexRef.current === index
                                  ? "singleDateSummarySpan"
                                  : ""
                              }`}
                              onClick={(e) => {
                                if (api !== 0) {
                                  singleDateSelectedDateRef.current = item;
                                  handleFilterBtnClick(e);
                                  selectedTdIndexRef.current = index;
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {singleOTBData?.[0]?.OnTheBook
                                ? commonService.formateRoom(
                                    singleOTBData?.[0]?.OnTheBook
                                  )
                                : 0}
                            </span>
                          </td>

                          <td className="">
                            {singleOTBData?.[0]?.TotalOCCPercentage
                              ? `${singleOTBData?.[0]?.TotalOCCPercentage}%`
                              : `0%`}
                          </td>
                          <td
                            className="text-end perbackground groupEvent-adr"
                            value={singleOTBData?.[0]?.ADR}
                          >
                            {singleOTBData?.[0]?.ADR
                              ? `${commonService.formateAmount(
                                  singleOTBData?.[0]?.ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="text-end border-right">
                            {singleOTBData?.[0]?.REV
                              ? `${commonService.formateAmount(
                                  singleOTBData?.[0]?.REV
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="text-end">
                            {singleOTBData?.[0]?.RevPAR
                              ? `${commonService.formateAmount(
                                  singleOTBData?.[0]?.RevPAR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="border-right text-end">
                            {commonService.formateAmount(0)}
                          </td>
                          <td className="text-center">
                            {singleForecastData?.[0]?.RMS
                              ? commonService.formateRoom(
                                  singleForecastData?.[0]?.RMS
                                )
                              : 0}
                          </td>
                          <td
                            className="border-right text-center perbackground groupEvent-groupBlock"
                            value={singleGroupData?.[0]?.GroupBlock}
                          >
                            {singleGroupData?.[0]?.GroupBlock
                              ? commonService.formateRoom(
                                  singleGroupData?.[0]?.GroupBlock
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {(singleGroupData?.[0]?.["GroupBlock"]
                              ? singleGroupData?.[0]?.["GroupBlock"]
                              : 0) -
                              (singleGroupData?.[0]?.["7 day block pickup"]
                                ? singleGroupData?.[0]?.["7 day block pickup"]
                                : 0)}
                          </td>
                          <td
                            className="text-center perbackground groupEvent-groupOtb"
                            value={singleGroupData?.[0]?.GroupOTB}
                          >
                            {singleGroupData?.[0]?.GroupOTB
                              ? commonService.formateRoom(
                                  singleGroupData?.[0]?.GroupOTB
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {(singleGroupData?.[0]?.["GroupOTB"]
                              ? singleGroupData?.[0]?.["GroupOTB"]
                              : 0) -
                              (singleGroupData?.[0]?.["7 day OTB pickup"]
                                ? singleGroupData?.[0]?.["7 day OTB pickup"]
                                : 0)}
                          </td>
                          <td className="text-end">
                            {singleGroupData?.[0]?.GroupRevenue
                              ? `${commonService.formateAmount(
                                  singleGroupData?.[0]?.GroupRevenue
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="text-end">
                            {singleGroupData?.[0]?.GroupADR
                              ? `${commonService.formateAmount(
                                  singleGroupData?.[0]?.GroupADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="border-right">
                            <div>
                              {checkNoteExists?.length > 0 ? (
                                <img
                                  className="cursorPointer"
                                  src={darkNoteIcon}
                                  onClick={() => {
                                    clickedDateForNote.current = item;
                                    setShowNoteSidebar(true);
                                  }}
                                  alt="note"
                                />
                              ) : (
                                <img
                                  className="cursorPointer"
                                  src={NoteIcon}
                                  onClick={() => {
                                    clickedDateForNote.current = item;
                                    setShowNoteSidebar(true);
                                  }}
                                  alt="note"
                                />
                              )}
                              <div style={{ display: "none" }}>
                                {checkNoteExists?.map((item, index) => {
                                  return (
                                    <>
                                      <span>{`${
                                        item?.displayname
                                      } - ${htmlToText(
                                        item?.widgetnotes
                                      )}`}</span>{" "}
                                      <br />
                                    </>
                                  );
                                })}
                              </div>
                              {/* <Form.Control
                                                            type="text"
                                                            defaultValue={
                                                                singleOTBData?.[0]?.UserNotes
                                                                    ? singleOTBData?.[0]?.UserNotes
                                                                    : ""
                                                            }
                                                            onBlur={(e) => {
                                                                let oldUserNote = singleOTBData?.[0]?.UserNotes
                                                                    ? singleOTBData?.[0]?.UserNotes
                                                                    : "";
                                                                if (api !== 0) {
                                                                    dateForNoteRef.current =
                                                                        moment(item).format("YYYY-MM-DD");
                                                                    userNoteRef.current = e.target.value;
                                                                    if (oldUserNote !== e.target.value) {
                                                                        saveNote();
                                                                    }
                                                                }
                                                            }}
                                                        /> */}
                            </div>
                          </td>
                        </tr>
                        {index ===
                          Object.keys(dateRangeObj.current)?.length - 1 && (
                          <tr className="shadowOnHover">
                            <td className="boldTd">Total</td>
                            <td className="boldTd border-right">
                              {commonService.formateRoom(ooo)}/
                              {commonService.formateRoom(RmsAvailable)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(leftToSell)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(otb)}
                            </td>
                            <td className="boldTd">
                              {occPercentage > 0
                                ? `${parseInt(occPercentage / diffDays)}%`
                                : `0%`}
                            </td>
                            <td className="boldTd text-end">
                              {rev > 0 && otb > 0
                                ? `${commonService.formateAmount(
                                    parseInt(rev / otb)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-end border-right">
                              {commonService.formateAmount(rev)}
                            </td>
                            <td className="boldTd text-end">
                              {revPar > 0
                                ? `${commonService.formateAmount(
                                    parseInt(revPar / diffDays)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="border-right text-end boldTd">
                              {commonService.formateAmount(0)}
                            </td>
                            <td className="text-center boldTd">
                              {commonService.formateRoom(forecastRms)}
                            </td>
                            <td className="border-right boldTd text-center">
                              {commonService.formateRoom(groupBlock)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(groupBlockVar)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(groupOtb)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(groupOtbVar)}
                            </td>
                            <td className="text-end boldTd">
                              {commonService.formateAmount(groupRev)}
                            </td>
                            <td className="text-end boldTd">
                              {groupAdr > 0
                                ? commonService.formateAmount(
                                    Math.round(groupAdr / diffDays)
                                  )
                                : commonService.formateAmount(groupAdr)}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {showTicketModal && (
          <EventViewModal
            showModal={showTicketModal}
            closeModal={closeTicketModal}
            eventDetails={eventListPropsRef.current}
            selectedDate={selectedEventDateRef.current}
            getEventList={getEventList}
          />
        )}
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"GroupMs"} />}

      <Overlay
        show={ONTHEBOOKS.IsDisplay}
        target={ONTHEBOOKS.Target}
        placement={ONTHEBOOKS.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
        >
          <div className="horizontal-menu-option active">
            <SingleDateSummaryModal
              closeModal={closeSingleDate}
              asOfDate={para?.asOfDate}
              selectedDate={singleDateSelectedDateRef.current}
              pickupDate={
                selectPickupDate
                  ? commonService.convertDateInTimezone(selectPickupDate)
                  : commonService.convertDateInTimezone(
                      singleDatePickupDateRef.current
                    )
              }
              propertyDetail={{ propertyCode: para?.propertyCode }}
            />
          </div>
        </Popover>
      </Overlay>
      {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          // widgetId={para?.widgetId}
          widgetId={0}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
          dateForNote={clickedDateForNote.current}
        />
      )}
      {addNoteModal && (
        <AddWidgetModal
          showModal={addNoteModal}
          closeModal={closeNoteModal}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default Group;
