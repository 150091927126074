import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { commonService } from "../../../../utils/commonService";
import { Modal, Table, Overlay, Popover } from "react-bootstrap";
import FilterModal from "../ReservationActivity/FilterModal";
import Loader from "../../../Loader/Loader";
import moment from "moment";

const ReservationActivityModal = ({ showModal, closeModal, payload }) => {
  const [reservationData, setReservationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tblids, settblids] = useState({
    tblCurrentReservation: commonService.GenerateGUID(),
  });
  const allFilterDataRef = useRef();
  const allSelectedDataRef = useRef();
  const selectedFilterRef = useRef();
  const sortingRef = useRef({
    column: "0",
    order: "desc",
  });
  const [filterModal, setFilterModal] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const getReservationData = async () => {
    setLoading(true);
    const reservationDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.DAILY_SUMMARY
            .STRATEGY_BY_RESERVATION_PICKUP_SINGLE_DATE_SUMMARY,
        body: {
          AsOfDate: payload?.AsOfDate,
          PickUpDate: payload?.PickUpDate
            ? commonService.getDateForNode(payload?.PickUpDate)
            : "",
          propertyCode: payload?.propertyCode,
          SelectedDate: payload?.SelectedDate
            ? commonService.getDateForNode(payload?.SelectedDate)
            : "",
          pickupType: payload?.pickupType ? payload?.pickupType : "",
        },
      },
      false
    );

    const response = apiResponse(false, reservationDataResponse);

    if (response?.isValidate) {
      let groupingJson = {
        rateCode: {},
        company: {},
        combinedChannel: {},
        status: {},
        marketSegment: {},
      };
      const handleObj = (key, value) => {
        if (!groupingJson[key][value]) {
          groupingJson[key][value?.trim()] = value?.trim();
        }
      };
      response?.data?.data?.forEach((item) => {
        let status = "";
        if (item?.Status === "R") {
          status = "reserved";
        } else if (item?.Status === "I") {
          status = "in house";
        } else if (item?.Status === "O") {
          status = "checked out";
        } else if (item?.Status === "C") {
          status = "cancelled";
        } else if (item?.Status === "N") {
          status = "no show";
        }
        handleObj("rateCode", item?.RateCode);
        handleObj("company", item?.Company);
        handleObj("combinedChannel", item?.CombinedChannel);
        handleObj("marketSegment", item?.MarketSegment);
        if (status) {
          handleObj("status", status);
        }
      });

      allFilterDataRef.current = { ...groupingJson };
      allSelectedDataRef.current = { ...groupingJson };

      setReservationData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setReservationData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    getReservationData();
  }, []);

  const handleFilterBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setFilterModal({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };

  const closeFilterModal = () => {
    setFilterModal({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
    selectedFilterRef.current = "";
  };

  const handleSearch = () => {
    let rateCodeSearchInput = document.getElementById("tblRateCode");
    let marketSegmentSearchInput = document.getElementById("tblMarketSegment");
    let companySearchInput = document.getElementById("tblCompany");
    let guestNameSearchInput = document.getElementById("tblGuestName");
    let confirmationNumberSearchInput = document.getElementById(
      "tblConfirmationNumber"
    );
    let statusSearchInput = document.getElementById("tblStatus");
    let roomTypeSearchInput = document.getElementById("tblRoomType");
    let channelSearchInput = document.getElementById("tblChannel");
    let sourceSearchInput = document.getElementById("tblSource");

    let table = document.getElementById(`${tblids.tblCurrentReservation}`);
    let tr = table?.getElementsByTagName("tr");

    for (let i = 0; i < tr?.length; i++) {
      let td = tr[i]?.getElementsByTagName("td");

      if (td?.length > 0) {
        let rateCodeTxtValue = td?.[2]?.innerText || "";
        let marketSegmentTxtValue = td?.[3]?.innerText || "";
        let companyTxtValue = td?.[4]?.innerText || "";
        let guestNameTxtValue = td?.[5]?.innerText || "";
        let confirmationNumberTxtValue = td?.[6]?.innerText || "";
        let statusTxtValue = td?.[10]?.innerText || "";
        let roomTypeTxtValue = td?.[11]?.innerText || "";
        let channelTxtValue = td?.[12]?.innerText || "";
        let sourceTxtValue = td?.[13]?.innerText || "";

        if (
          rateCodeTxtValue
            .toLowerCase()
            .includes(rateCodeSearchInput?.value?.toLowerCase()) &&
          allSelectedDataRef.current?.rateCode?.[rateCodeTxtValue] &&
          marketSegmentTxtValue
            .toLowerCase()
            .includes(marketSegmentSearchInput?.value?.toLowerCase()) &&
          allSelectedDataRef.current?.marketSegment?.[marketSegmentTxtValue] &&
          companyTxtValue
            .toLowerCase()
            .includes(companySearchInput?.value?.toLowerCase()) &&
          allSelectedDataRef.current?.company?.[companyTxtValue] &&
          guestNameTxtValue
            .toLowerCase()
            .includes(guestNameSearchInput?.value?.toLowerCase()) &&
          confirmationNumberTxtValue
            .toLowerCase()
            .includes(confirmationNumberSearchInput?.value?.toLowerCase()) &&
          statusTxtValue
            .toLowerCase()
            .includes(statusSearchInput?.value?.toLowerCase()) &&
          allSelectedDataRef.current?.status?.[statusTxtValue] &&
          roomTypeTxtValue
            .toLowerCase()
            .includes(roomTypeSearchInput?.value?.toLowerCase()) &&
          channelTxtValue
            .toLowerCase()
            .includes(channelSearchInput?.value?.toLowerCase()) &&
          allSelectedDataRef.current?.combinedChannel?.[channelTxtValue] &&
          sourceTxtValue
            .toLowerCase()
            .includes(sourceSearchInput?.value?.toLowerCase())
        ) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  };

  const handleSort = (n, trClassName) => {
    var table,
      rows,
      switching,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      switchcount = 0;
    table = document.getElementById(`${tblids.tblCurrentReservation}`);
    switching = true;
    dir = sortingRef.current.order;
    while (switching) {
      switching = false;
      rows = table?.rows;

      for (i = 2; i < rows?.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName("TD")[n]?.innerHTML || "";
        y = rows[i + 1].getElementsByTagName("TD")[n]?.innerHTML || "";

        x = x?.replace("$", "");
        y = y?.replace("$", "");

        if (dir == "asc") {
          if (n !== 0 && parseInt(x) > parseInt(y)) {
            shouldSwitch = true;
            break;
          }

          if (n === 0) {
            let currentDate = new Date(x);
            let nextDate = new Date(y);
            if (currentDate > nextDate) {
              shouldSwitch = true;
              break;
            }
          }
        } else if (dir == "desc") {
          if (n !== 0 && parseInt(x) < parseInt(y)) {
            shouldSwitch = true;
            break;
          }

          if (n === 0) {
            let currentDate = new Date(x);
            let nextDate = new Date(y);
            if (currentDate < nextDate) {
              shouldSwitch = true;
              break;
            }
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }

    let upIconArr = document.querySelectorAll(".up-arrow-icon");
    for (let j = 0; j < upIconArr?.length; j++) {
      upIconArr?.[j]?.setAttribute("fill", "#DFDFDF");
    }

    let downIconArr = document.querySelectorAll(".down-arrow-icon");
    for (let j = 0; j < upIconArr?.length; j++) {
      downIconArr?.[j]?.setAttribute("fill", "#DFDFDF");
    }

    let upIcon = document.querySelectorAll(`.${trClassName} .up-arrow-icon`);
    let downIcon = document.querySelectorAll(
      `.${trClassName} .down-arrow-icon`
    );

    if (sortingRef.current.order === "asc") {
      upIcon?.[0].setAttribute("fill", "#000000");
      downIcon?.[0].setAttribute("fill", "#DFDFDF");
    } else {
      downIcon?.[0].setAttribute("fill", "#000000");
      upIcon?.[0].setAttribute("fill", "#DFDFDF");
    }
  };

  const updateFilterModal = (key, value) => {
    allSelectedDataRef.current[key] = value;
    closeFilterModal();

    handleSearch();
  };

  useEffect(() => {
    let upIconArr = document.querySelectorAll(".up-arrow-icon");
    for (let j = 0; j < upIconArr?.length; j++) {
      upIconArr?.[j]?.setAttribute("fill", "#DFDFDF");
    }

    let downIconArr = document.querySelectorAll(".down-arrow-icon");
    for (let j = 0; j < upIconArr?.length; j++) {
      downIconArr?.[j]?.setAttribute("fill", "#DFDFDF");
    }

    let upIcon = document.querySelectorAll(`.bookingDateTr .up-arrow-icon`);
    let downIcon = document.querySelectorAll(`.bookingDateTr .down-arrow-icon`);

    if (sortingRef.current.order === "asc") {
      upIcon?.[0]?.setAttribute("fill", "#000000");
      downIcon?.[0]?.setAttribute("fill", "#DFDFDF");
    } else {
      downIcon?.[0]?.setAttribute("fill", "#000000");
      upIcon?.[0]?.setAttribute("fill", "#DFDFDF");
    }
  }, [reservationData]);

  return (
    <Modal
      className="AddNotificationConfigModal-main NotificationConfig-main"
      size="xl"
      centered
      show={showModal}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Reservation Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="ViewPropertySetupTable">
          <div className="stripped__table">
            <div
              className="table-responsive"
              style={{ overflowY: "auto", maxHeight: "400px" }}
            >
              <Table className="maxHeight400" id={tblids.tblCurrentReservation}>
                <thead className="tbl-header-sticky">
                  <tr className="table-main-header-tr">
                    <th
                      className="border_right width125"
                      onClick={() => {
                        let orderForSort = "";
                        if (sortingRef.current.column === 0) {
                          orderForSort =
                            sortingRef.current.order === "asc" ? "desc" : "asc";
                        } else {
                          orderForSort = "asc";
                        }
                        sortingRef.current = {
                          column: 0,
                          order: orderForSort,
                        };
                        handleSort(0, "bookingDateTr");
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <p>Booking Date</p>
                        <div className="d-flex flex-column bookingDateTr">
                          <svg
                            className="up-arrow-icon"
                            fill="#DFDFDF"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9px"
                            height="9px"
                            viewBox="0 0 52 52"
                            enable-background="new 0 0 52 52"
                          >
                            <path d="M43.7,38H8.3c-1,0-1.7-1.3-0.9-2.2l17.3-21.2c0.6-0.8,1.9-0.8,2.5,0l17.5,21.2C45.4,36.7,44.8,38,43.7,38z" />
                          </svg>
                          <svg
                            className="down-arrow-icon"
                            fill="#DFDFDF"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9px"
                            height="9px"
                            viewBox="0 0 52 52"
                            enable-background="new 0 0 52 52"
                          >
                            <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z" />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-end border_right"
                      onClick={() => {
                        let orderForSort = "";
                        if (sortingRef.current.column === 1) {
                          orderForSort =
                            sortingRef.current.order === "asc" ? "desc" : "asc";
                        } else {
                          orderForSort = "asc";
                        }
                        sortingRef.current = {
                          column: 1,
                          order: orderForSort,
                        };
                        handleSort(1, "rateTr");
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <p>Rate</p>
                        <div className="d-flex flex-column rateTr">
                          <svg
                            className="up-arrow-icon"
                            fill="#DFDFDF"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9px"
                            height="9px"
                            viewBox="0 0 52 52"
                            enable-background="new 0 0 52 52"
                          >
                            <path d="M43.7,38H8.3c-1,0-1.7-1.3-0.9-2.2l17.3-21.2c0.6-0.8,1.9-0.8,2.5,0l17.5,21.2C45.4,36.7,44.8,38,43.7,38z" />
                          </svg>
                          <svg
                            className="down-arrow-icon"
                            fill="#DFDFDF"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9px"
                            height="9px"
                            viewBox="0 0 52 52"
                            enable-background="new 0 0 52 52"
                          >
                            <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z" />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th className="border_right">
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <p>Rate Code</p>
                        <svg
                          onClick={(e) => {
                            if (selectedFilterRef.current === "rateCode") {
                              closeFilterModal();
                            } else {
                              selectedFilterRef.current = "rateCode";
                              handleFilterBtnClick(e);
                            }
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-caret-down-filled cursorPointer"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#000000"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
                            stroke-width="0"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </th>
                    <th className="border_right">
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <p>MarkestSegment</p>
                        <svg
                          onClick={(e) => {
                            if (selectedFilterRef.current === "marketSegment") {
                              closeFilterModal();
                            } else {
                              selectedFilterRef.current = "marketSegment";
                              handleFilterBtnClick(e);
                            }
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-caret-down-filled cursorPointer"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#000000"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
                            stroke-width="0"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </th>
                    <th className="border_right">
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <p>Company</p>
                        <svg
                          onClick={(e) => {
                            if (selectedFilterRef.current === "company") {
                              closeFilterModal();
                            } else {
                              selectedFilterRef.current = "company";
                              handleFilterBtnClick(e);
                            }
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-caret-down-filled cursorPointer"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#000000"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
                            stroke-width="0"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </th>
                    <th className="border_right">Guest Name</th>
                    <th className="border_right">CONFIRMATION NUMBER</th>
                    <th className="border_right">ARRIVAL DATE</th>
                    <th className="border_right">Departure Date</th>
                    <th
                      className="text-center  border_right"
                      onClick={() => {
                        let orderForSort = "";
                        if (sortingRef.current.column === 9) {
                          orderForSort =
                            sortingRef.current.order === "asc" ? "desc" : "asc";
                        } else {
                          orderForSort = "asc";
                        }
                        sortingRef.current = {
                          column: 9,
                          order: orderForSort,
                        };
                        handleSort(9, "losTr");
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <p>LOS</p>
                        <div className="d-flex flex-column losTr">
                          <svg
                            className="up-arrow-icon"
                            fill="#DFDFDF"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9px"
                            height="9px"
                            viewBox="0 0 52 52"
                            enable-background="new 0 0 52 52"
                          >
                            <path d="M43.7,38H8.3c-1,0-1.7-1.3-0.9-2.2l17.3-21.2c0.6-0.8,1.9-0.8,2.5,0l17.5,21.2C45.4,36.7,44.8,38,43.7,38z" />
                          </svg>
                          <svg
                            className="down-arrow-icon"
                            fill="#DFDFDF"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9px"
                            height="9px"
                            viewBox="0 0 52 52"
                            enable-background="new 0 0 52 52"
                          >
                            <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z" />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th className="border_right">
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <p>Status</p>
                        <svg
                          onClick={(e) => {
                            if (selectedFilterRef.current === "status") {
                              closeFilterModal();
                            } else {
                              selectedFilterRef.current = "status";
                              handleFilterBtnClick(e);
                            }
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-caret-down-filled cursorPointer"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#000000"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
                            stroke-width="0"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </th>
                    <th className="border_right">Room Type</th>
                    <th className="border_right">
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <p>Channel</p>
                        <svg
                          onClick={(e) => {
                            if (
                              selectedFilterRef.current === "combinedChannel"
                            ) {
                              closeFilterModal();
                            } else {
                              selectedFilterRef.current = "combinedChannel";
                              handleFilterBtnClick(e);
                            }
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-caret-down-filled cursorPointer"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#000000"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
                            stroke-width="0"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </th>
                    <th className="border_right">Source / User</th>
                    <th className="border_right">Stay Date</th>
                  </tr>
                  <tr>
                    <th className="border_right"></th>
                    <th className="border_right"></th>
                    <th className="border_right">
                      <div class="search-group-for-table input-group">
                        <input
                          aria-label="Search"
                          class="border-0 form-control"
                          id="tblRateCode"
                          onChange={() => {
                            handleSearch();
                          }}
                        />
                      </div>
                    </th>
                    <th className="border_right">
                      <div class="search-group-for-table input-group">
                        <input
                          aria-label="Search"
                          class="border-0 form-control"
                          id="tblMarketSegment"
                          onChange={() => {
                            handleSearch();
                          }}
                        />
                      </div>
                    </th>
                    <th className="border_right">
                      <div class="search-group-for-table input-group">
                        <input
                          aria-label="Search"
                          class="border-0 form-control"
                          id="tblCompany"
                          onChange={() => {
                            handleSearch();
                          }}
                        />
                      </div>
                    </th>
                    <th className="border_right">
                      <div class="search-group-for-table input-group">
                        <input
                          aria-label="Search"
                          class="border-0 form-control"
                          id="tblGuestName"
                          onChange={() => {
                            handleSearch();
                          }}
                        />
                      </div>
                    </th>
                    <th className="border_right">
                      <div class="search-group-for-table input-group">
                        <input
                          aria-label="Search"
                          class="border-0 form-control"
                          id="tblConfirmationNumber"
                          onChange={() => {
                            handleSearch();
                          }}
                        />
                      </div>
                    </th>
                    <th className="border_right"></th>
                    <th className="border_right"></th>
                    <th className="border_right"></th>
                    <th className="border_right">
                      <div class="search-group-for-table input-group">
                        <input
                          aria-label="Search"
                          class="border-0 form-control"
                          id="tblStatus"
                          onChange={() => {
                            handleSearch();
                          }}
                        />
                      </div>
                    </th>
                    <th className="border_right">
                      <div class="search-group-for-table input-group">
                        <input
                          aria-label="Search"
                          class="border-0 form-control"
                          id="tblRoomType"
                          onChange={() => {
                            handleSearch();
                          }}
                        />
                      </div>
                    </th>
                    <th className="border_right">
                      <div class="search-group-for-table input-group">
                        <input
                          aria-label="Search"
                          class="border-0 form-control"
                          id="tblChannel"
                          onChange={() => {
                            handleSearch();
                          }}
                        />
                      </div>
                    </th>
                    <th className="border_right">
                      <div class="search-group-for-table input-group">
                        <input
                          aria-label="Search"
                          class="border-0 form-control"
                          id="tblSource"
                          onChange={() => {
                            handleSearch();
                          }}
                        />
                      </div>
                    </th>
                    <th className="border_right"></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : reservationData ? (
                    reservationData?.map((item) => {
                      let status = "";
                      if (item?.Status === "R") {
                        status = "reserved";
                      } else if (item?.Status === "I") {
                        status = "in house";
                      } else if (item?.Status === "O") {
                        status = "checked out";
                      } else if (item?.Status === "C") {
                        status = "cancelled";
                      } else if (item?.Status === "N") {
                        status = "no show";
                      }

                      let time = "";
                      if (item?.BookingTime) {
                        let localDate = moment
                          .utc(`${item?.BookingDate}T${item?.BookingTime}Z`)
                          .local();
                        time = localDate.format("hh:mm A");
                      }

                      return (
                        <tr key={item?.id} className="shadowOnHover">
                          <td className="border_right">
                            {item?.BookingDate
                              ? `${commonService.getDateInFormat(
                                  item?.BookingDate
                                )}  ${time}`
                              : ""}
                          </td>
                          <td className="text-end border_right">
                            {commonService.formateAmount(
                              Math.round(item?.Rate)
                            )}
                          </td>
                          <td className="border_right">{item?.RateCode}</td>
                          <td className="border_right">
                            {item?.MarketSegment}
                          </td>
                          <td className="border_right">{item?.Company}</td>
                          <td className="border_right">{item?.GuestName}</td>
                          <td className="border_right">{item?.Account}</td>
                          <td className="border_right">
                            {item?.ArrivalDate
                              ? commonService.getDateInFormat(item?.ArrivalDate)
                              : ""}
                          </td>
                          <td className="border_right">
                            {item?.DepartureDate
                              ? commonService.getDateInFormat(
                                  item?.DepartureDate
                                )
                              : ""}
                          </td>
                          <td className="border_right">{item?.LOS}</td>
                          <td className="border_right">{status}</td>
                          <td className="border_right">{item?.RoomType}</td>
                          <td className="border_right">
                            {item?.CombinedChannel}
                          </td>
                          <td className="border_right">{item?.User}</td>

                          <td className="border_right">
                            {commonService.getDateInFormat(item?.StayDate)}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={15} className="text-center">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <Overlay
          show={filterModal.IsDisplay}
          target={filterModal.Target}
          placement={filterModal.Position}
        >
          <Popover
            id="popover-contained"
            className="SingleDateSummaryModalPopoverMain"
          >
            <div className="horizontal-menu-option active reservationFilter">
              <FilterModal
                keyName={selectedFilterRef.current}
                list={allFilterDataRef.current?.[selectedFilterRef.current]}
                selectedData={
                  allSelectedDataRef.current?.[selectedFilterRef.current]
                }
                updateFilter={updateFilterModal}
              />
            </div>
          </Popover>
        </Overlay>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ReservationActivityModal;
