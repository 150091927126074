export const validationRule = {
  required: "required",
  email: "email",
  phone: "phone",
  dropDown: "dropDown",
  max: "max",
  min: "min",
};

export const compName = {
  budget: "Budget",
  netStly: "NetStly",
  onTheBook: "OnTheBook",
  reservationActivity: "ReservationActivity",
  totalLy: "TotalLy",
  userForecast: "UserForecast",
  oneDay: "OneDay",
  oneDayForecastChange: "OneDayForecastChange",
  sevenDay: "SevenDay",
  sevenDayForecastChange: "SevenDayForecastChange",
  group: "Group",
  groupLastYear: "GroupLastYear",
  transient: "Transient",
  transientLastYear: "TransientLastYear",
  org: "ORG",
  barBasedStats: "BarBasedStats",
  pickupFrom: "PickUpFrom",
  pricingAndForecast: "PricingAndForecast",
  heatMap: "HeatMap",
  widgetBudgetGraph: "WidgetBudgetGraph",
  spiderForecast: "SpiderForecast",
  rmsForecast: "RMSForecast",
  userForecast: "UserForecast",
  choiceRes: "ChoiceRes",
  mstReservation: "MstReservation",
  dailyDataTransaction: "DailyDataTransaction",
  emaForecast: "EMAForecast",
  annualSummaryChart: "AnnualSummaryChart",
  forecastChart: "ForecastChart",
  pickupChart: "PickupChart",
  groupChart: "GroupChart",
  bookingPaceChart: "BookingPace",
  marketSegmentChart: "MarketSegmentChart",
  widgetChoiceOccupancy: "WidgetChoiceOccupancy",
  widgetHotelKeyReservation: "WidgetHotelKeyReservation",
  widgetHotelKeyOccupancySnapshot: "WidgetHotelKeyOccupancySnapshot",
  widgetBestWesternGuestName: "WidgetBestWesternGuestName",
  widgetBestWesternReservationAgent: "WidgetBestWesternReservationAgent",
  widgetBestWesternReservationForecast: "WidgetBestWesternReservationForecast",
  widgetBestWesternRoomMaintaince: "WidgetBestWesternRoomMaintaince",
  widgetChoiceCancelationSummary: "WidgetChoiceCancelationSummary",
  adrVsRateChart: "AdrVsRateChart",
  widgetPickup: "WidgetPickup",
  bookingPaceTable: "BookingPaceTable",
  annualSummary: "AnnualSummary",
  widgetChoiceBusinessOTB: "WidgetChoiceBusinessOTB",
  widgetChoiceRevenue: "WidgetChoiceRevenue",
  marketSegmentDayByDay: "MarketSegmentDayByDay",
  marketSegmentRoomType: "MarketSegmentRoomType",
  ratePlanDayByDay: "RatePlanDayByDay",
  dynamicPickup: "DynamicPickup",
  losDayOfWeek: "LosDayOfWeek",
  top10Chart: "Top10Chart",
  dayByDayGroup: "DayByDayGroup",
  sevenDayBookingReservation: "SevenDayBookingReservation",
  sevenDayBookingExpected: "SevenDayBookingExpected",
  sevenDayBookingPace: "SevenDayBookingPace",
  sevenWeekBookingPace: "SevenWeekBookingPace",
  lengthOfStay: "LengthOfStay",
  emaForecastV2: "EmaForecastV2",
  emaForecastV3: "EmaForecastV3",
  forecastCommon: "ForecastCommon",
  pickupCommon: "PickupCommon",
  groupCommon: "GroupCommon",
  revYoYChart: "RevYoYChart",
  segmentDrillDown: "SegmentDrillDown",
  ninetyDayOutlook: "NinetyDayOutlook",
  lastYearAndForecast: "LastYearAndForecast",
  annSummaryCancellation: "AnnSummaryCancellation",
  cancellationPace: "CancellationPace",
  account: "Account",
  cyCancellationBookingRevLoss: "CyCancellationBookingRevLoss",
  lyCancellationBookingRevLoss: "LyCancellationBookingRevLoss",
  stlyCancellationBookingRevLoss: "StlyCancellationBookingRevLoss",
  cy2CancellationBookingRevLoss: "Cy2CancellationBookingRevLoss",
  annCancellationSummary: "AnnCancellationSummary",
  monthlyCancellationPace: "MonthlyCancellationPace",
  monthlyCancellationPaceChart: "MonthlyCancellationPaceChart",
  ninetyDaySegment: "NinetyDaySegment",
  cancellationDayByDay: "CancellationDayByDay",
  top50ByRevenue: "Top50ByRevenue",
  dailyDataForEmail: "DailyDataForEmail",
  weeklyDataForEmail: "WeeklyDataForEmail",
  monthlyDataForEmail: "MonthlyDataForEmail",
  runningStrReport: "RunningStrReport",
  weeklyStrReport: "WeeklyStrReport",
  strAnalysis: "StrAnalysis",
  strDayByDay: "StrDayByDay",
  monthlySegmentDrilldown: "MonthlySegmentDrilldown",
  pastDynamicPickup: "PastDynamicPickup",
  bookingPaceComparisonChart: "BookingPaceComparisonChart",
  thirtyDayPickup: "ThirtyDayPickup",
  bookingPaceComparison: "BookingPaceComparison",
  commentWidget: "CommentWidget",
  forecastUsing90DayPickup: "ForecastUsing90DayPickup",
  crmRevnueByCompany: "CrmRevnueByCompany",
  crmRevenueByRoomType: "CrmRevenueByRoomType",
  crmRevenueByMarketSegment: "CrmRevenueByMarketSegment",
  forecastvsOtbChart: "ForecastvsOtbChart",
  perityCheck: "PerityCheck",
  channelMonthlyDrilldown: "ChannelMonthlyDrilldown",
  dayByDayChannel: "Channel",
  crmCancellationActivity: "CrmCancellationActivity",
  crmCompanyDrilldown: "CrmCompanyDrilldown",
  crmArrialDeparture: "CrmArrialDeparture",
  rateShopBenchmarking: "RateShopBenchmarking",
  toolRateShop: "ToolRateShop",
  toolReminder: "ToolReminder",
  toolEvent: "ToolEvent",
  toolRateIq: "ToolRateIq",
  toolDisplacementCalculator: "ToolDisplacementCalculator",
  toolPerityCheck: "ToolPerityCheck",
  heatMapdaywise: "HeatMapdaywise",
  eventStretegyWidget: "EventStretegyWidget",
  pastForecastChart: "PastForecastChart",
  weatherWidget: "WeatherWidget",
  bookingPaceForecastComparison: "BookingPaceForecastComparison",
  bookingPaceMonthlyForecast: "BookingPaceMonthlyForecast",
  dynamicForecastUsingNinentyDayPickup: "DynamicForecastUsingNinentyDaysPickup",
  rateShopChart: "RateShopChart",
  seasonalityAnalysis: "SeasonalityAnalysis",
  marketSegmentWiseReservation: "MarketSegmentWiseReservation",
  monthlyBookingWindow: "MonthlyBookingWindow",
  dayByDayBookingWindow: "DayByDayBookingWindow",
  googleRatings: "GoogleRatings",
  analyticsReview: "AnalyticsReview",
  analyticsRates: "AnalyticsRates",
  analyticsRatesCount: "AnalyticsRatesCount",
  analyticsSentimateReview: "AnalyticsSentimateReview",
  strategyByReservation: "StrategyByReservation",
};

export const colValue = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const week = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const widgetData = [
  {
    noofcol: 1,
    rowtitle: "",
    data: [
      {
        comp: "",
        col: "col-12",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
    ],
    boxDesign: (
      <>
        <div className="gridSelectionBox"></div>
      </>
    ),
  },
  {
    noofcol: 2,
    rowtitle: "",
    data: [
      {
        comp: "",
        col: "col-6",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-6",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
    ],
    boxDesign: (
      <>
        <div className="gridSelectionBox"></div>
        <div className="gridSelectionBox"></div>
      </>
    ),
  },
  {
    noofcol: 3,
    rowtitle: "",
    data: [
      {
        comp: "",
        col: "col-4",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-4",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-4",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
    ],
    boxDesign: (
      <>
        <div className="gridSelectionBox"></div>
        <div className="gridSelectionBox"></div>
        <div className="gridSelectionBox"></div>
      </>
    ),
  },
  {
    noofcol: 4,
    rowtitle: "",
    data: [
      {
        comp: "",
        col: "col-3",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-3",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-3",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-3",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
    ],
    boxDesign: (
      <>
        <div className="gridSelectionBox"></div>
        <div className="gridSelectionBox"></div>
        <div className="gridSelectionBox"></div>
        <div className="gridSelectionBox"></div>
      </>
    ),
  },
  {
    noofcol: 2,
    rowtitle: "",
    data: [
      {
        comp: "",
        col: "col-4",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-8",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
    ],
    boxDesign: (
      <>
        <div className="gridSelectionBox gridSelectionBoxSm"></div>
        <div className="gridSelectionBox"></div>
      </>
    ),
  },
  {
    noofcol: 2,
    rowtitle: "",
    data: [
      {
        comp: "",
        col: "col-8",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-4",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
    ],
    boxDesign: (
      <>
        <div className="gridSelectionBox "></div>
        <div className="gridSelectionBox gridSelectionBoxSm"></div>
      </>
    ),
  },
  {
    noofcol: 3,
    rowtitle: "",
    data: [
      {
        comp: "",
        col: "col-6",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-3",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-3",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
    ],
    boxDesign: (
      <>
        <div className="gridSelectionBox "></div>
        <div className="gridSelectionBox gridSelectionBoxSm"></div>
        <div className="gridSelectionBox gridSelectionBoxSm"></div>
      </>
    ),
  },
  {
    noofcol: 3,
    rowtitle: "",
    data: [
      {
        comp: "",
        col: "col-3",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-3",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-6",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
    ],
    boxDesign: (
      <>
        <div className="gridSelectionBox gridSelectionBoxSm"></div>
        <div className="gridSelectionBox gridSelectionBoxSm"></div>
        <div className="gridSelectionBox "></div>
      </>
    ),
  },
  {
    noofcol: 3,
    rowtitle: "",
    data: [
      {
        comp: "",
        col: "col-3",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-6",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
      {
        comp: "",
        col: "col-3",
        widgetid: 0,
        orderno: 0,
        rowno: 0,
        colno: 0,
        snapshotwidgetid: 0,
        setting: "",
      },
    ],
    boxDesign: (
      <>
        <div className="gridSelectionBox gridSelectionBoxSm"></div>
        <div className="gridSelectionBox "></div>
        <div className="gridSelectionBox gridSelectionBoxSm"></div>
      </>
    ),
  },
];
export const Time = [
  "12:00 AM",
  "12:05 AM",
  "12:10 AM",
  "12:15 AM",
  "12:20 AM",
  "12:25 AM",
  "12:30 AM",
  "12:35 AM",
  "12:40 AM",
  "12:45 AM",
  "12:50 AM",
  "12:55 AM",
  "01:00 AM",
  "01:05 AM",
  "01:10 AM",
  "01:15 AM",
  "01:20 AM",
  "01:25 AM",
  "01:30 AM",
  "01:35 AM",
  "01:40 AM",
  "01:45 AM",
  "01:50 AM",
  "01:55 AM",
  "02:00 AM",
  "02:05 AM",
  "02:10 AM",
  "02:15 AM",
  "02:20 AM",
  "02:25 AM",
  "02:30 AM",
  "02:35 AM",
  "02:40 AM",
  "02:45 AM",
  "02:50 AM",
  "02:55 AM",
  "03:00 AM",
  "03:05 AM",
  "03:10 AM",
  "03:15 AM",
  "03:20 AM",
  "03:25 AM",
  "03:30 AM",
  "03:35 AM",
  "03:40 AM",
  "03:45 AM",
  "03:50 AM",
  "03:55 AM",
  "04:00 AM",
  "04:05 AM",
  "04:10 AM",
  "04:15 AM",
  "04:20 AM",
  "04:25 AM",
  "04:30 AM",
  "04:35 AM",
  "04:40 AM",
  "04:45 AM",
  "04:50 AM",
  "04:55 AM",
  "05:00 AM",
  "05:05 AM",
  "05:10 AM",
  "05:15 AM",
  "05:20 AM",
  "05:25 AM",
  "05:30 AM",
  "05:35 AM",
  "05:40 AM",
  "05:45 AM",
  "05:50 AM",
  "05:55 AM",
  "06:00 AM",
  "06:05 AM",
  "06:10 AM",
  "06:15 AM",
  "06:20 AM",
  "06:25 AM",
  "06:30 AM",
  "06:35 AM",
  "06:40 AM",
  "06:45 AM",
  "06:50 AM",
  "06:55 AM",
  "07:00 AM",
  "07:05 AM",
  "07:10 AM",
  "07:15 AM",
  "07:20 AM",
  "07:25 AM",
  "07:30 AM",
  "07:35 AM",
  "07:40 AM",
  "07:45 AM",
  "07:50 AM",
  "07:55 AM",
  "08:00 AM",
  "08:05 AM",
  "08:10 AM",
  "08:15 AM",
  "08:20 AM",
  "08:25 AM",
  "08:30 AM",
  "08:35 AM",
  "08:40 AM",
  "08:45 AM",
  "08:50 AM",
  "08:55 AM",
  "09:00 AM",
  "09:05 AM",
  "09:10 AM",
  "09:15 AM",
  "09:20 AM",
  "09:25 AM",
  "09:30 AM",
  "09:35 AM",
  "09:40 AM",
  "09:45 AM",
  "09:50 AM",
  "09:55 AM",
  "10:00 AM",
  "10:05 AM",
  "10:10 AM",
  "10:15 AM",
  "10:20 AM",
  "10:25 AM",
  "10:30 AM",
  "10:35 AM",
  "10:40 AM",
  "10:45 AM",
  "10:50 AM",
  "10:55 AM",
  "11:00 AM",
  "11:05 AM",
  "11:10 AM",
  "11:15 AM",
  "11:20 AM",
  "11:25 AM",
  "11:30 AM",
  "11:35 AM",
  "11:40 AM",
  "11:45 AM",
  "11:50 AM",
  "11:55 AM",
  "12:00 PM",
  "12:05 PM",
  "12:10 PM",
  "12:15 PM",
  "12:20 PM",
  "12:25 PM",
  "12:30 PM",
  "12:35 PM",
  "12:40 PM",
  "12:45 PM",
  "12:50 PM",
  "12:55 PM",
  "01:00 PM",
  "01:05 PM",
  "01:10 PM",
  "01:15 PM",
  "01:20 PM",
  "01:25 PM",
  "01:30 PM",
  "01:35 PM",
  "01:40 PM",
  "01:45 PM",
  "01:50 PM",
  "01:55 PM",
  "02:00 PM",
  "02:05 PM",
  "02:10 PM",
  "02:15 PM",
  "02:20 PM",
  "02:25 PM",
  "02:30 PM",
  "02:35 PM",
  "02:40 PM",
  "02:45 PM",
  "02:50 PM",
  "02:55 PM",
  "03:00 PM",
  "03:05 PM",
  "03:10 PM",
  "03:15 PM",
  "03:20 PM",
  "03:25 PM",
  "03:30 PM",
  "03:35 PM",
  "03:40 PM",
  "03:45 PM",
  "03:50 PM",
  "03:55 PM",
  "04:00 PM",
  "04:05 PM",
  "04:10 PM",
  "04:15 PM",
  "04:20 PM",
  "04:25 PM",
  "04:30 PM",
  "04:35 PM",
  "04:40 PM",
  "04:45 PM",
  "04:50 PM",
  "04:55 PM",
  "05:00 PM",
  "05:05 PM",
  "05:10 PM",
  "05:15 PM",
  "05:20 PM",
  "05:25 PM",
  "05:30 PM",
  "05:35 PM",
  "05:40 PM",
  "05:45 PM",
  "05:50 PM",
  "05:55 PM",
  "06:00 PM",
  "06:05 PM",
  "06:10 PM",
  "06:15 PM",
  "06:20 PM",
  "06:25 PM",
  "06:30 PM",
  "06:35 PM",
  "06:40 PM",
  "06:45 PM",
  "06:50 PM",
  "06:55 PM",
  "07:00 PM",
  "07:05 PM",
  "07:10 PM",
  "07:15 PM",
  "07:20 PM",
  "07:25 PM",
  "07:30 PM",
  "07:35 PM",
  "07:40 PM",
  "07:45 PM",
  "07:50 PM",
  "07:55 PM",
  "08:00 PM",
  "08:05 PM",
  "08:10 PM",
  "08:15 PM",
  "08:20 PM",
  "08:25 PM",
  "08:30 PM",
  "08:35 PM",
  "08:40 PM",
  "08:45 PM",
  "08:50 PM",
  "08:55 PM",
  "09:00 PM",
  "09:05 PM",
  "09:10 PM",
  "09:15 PM",
  "09:20 PM",
  "09:25 PM",
  "09:30 PM",
  "09:35 PM",
  "09:40 PM",
  "09:45 PM",
  "09:50 PM",
  "09:55 PM",
  "10:00 PM",
  "10:05 PM",
  "10:10 PM",
  "10:15 PM",
  "10:20 PM",
  "10:25 PM",
  "10:30 PM",
  "10:35 PM",
  "10:40 PM",
  "10:45 PM",
  "10:50 PM",
  "10:55 PM",
  "11:00 PM",
  "11:05 PM",
  "11:10 PM",
  "11:15 PM",
  "11:20 PM",
  "11:25 PM",
  "11:30 PM",
  "11:35 PM",
  "11:40 PM",
  "11:45 PM",
  "11:50 PM",
  "11:55 PM",
];

export const revGlanceChatDescription = {
  copmSetVarianceData: `Data compares competitor rates, occupancy, ADR, and BAR for check-in dates differing in rates over two AsOfDates, showing rate differences, percentage changes, and metrics for client's property.
`,
  dashboardData: `This data analyzes hotel performance across occupancy, revenue, reservations, ratecodes, and competitor rates, offering metrics like ADR, RevPAR, cancellations, and benchmarking for optimizing revenue and booking strategies.
`,
  eventStretergyData: `This SQL script processes hotel data, aggregating metrics like OTB, pickups, forecasts, competitor rates, LY/STLY data, and event details for revenue, occupancy, and booking insights over a specified period.
`,
  highDemandData: `This query identifies the top 10 high-demand days for a hotel using occupancy trends, booking changes, day-of-week rankings, and event attendance, scoring each factor to prioritize key performance dates.`,
  lowDemandData: `The query identifies low-demand dates by comparing current vs. forecasted occupancy, considering last year's lead time trends, helping optimize pricing, promotions, and inventory for underperforming periods.
`,
};
