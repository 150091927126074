import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import Comments from "../Comments/Comment";
import Loader from "../../../Loader/Loader";
import { Col, Form, Row } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import DatePicker from "react-datepicker";
import leftArrow from "../../../../Assets/Images/Revenue-management/leftArrow.svg";
import rightArrow from "../../../../Assets/Images/Revenue-management/rightArrow.svg";

const ForecastVsOTBChart = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    para?.asOfDate ? para?.asOfDate : ""
  );
  let startDate = new Date(para?.asOfDate);
  let endDate = new Date(para?.asOfDate);
  endDate.setDate(endDate.getDate() - 30);
  const [startRangeDate, setStartRangeDate] = useState(endDate);
  const [endRangeDate, setEndRangeDate] = useState(startDate);
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const datesArrRef = useRef();

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      getChartDetail();
    }
  };

  const getChartDetail = async () => {
    setLoading(true);
    let chartResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.FORECAST.FORECAST_VS_OTB_CHART,
        body: {
          CheckInDate: commonService.getDateForNode(new Date(selectedDate)),
          propertyCode: para?.propertyCode,
          AsOfstartDate: startRangeDateRef.current
            ? commonService.getDateForNode(startRangeDateRef.current)
            : commonService.getDateForNode(startRangeDate),
          AsOfendDate: endRangeDateRef.current
            ? commonService.getDateForNode(endRangeDateRef.current)
            : commonService.getDateForNode(endRangeDate),
        },
      },
      false
    );

    let response = apiResponse(false, chartResponse);

    if (response?.isValidate) {
      let startDate = startRangeDateRef.current
        ? new Date(startRangeDateRef.current)
        : new Date(startRangeDate);
      let endDate = endRangeDateRef.current
        ? new Date(endRangeDateRef.current)
        : new Date(endRangeDate);

      let datesArr = commonService.getDateBetweenTwoDates(
        commonService.convertDateInTimezone(startDate),
        commonService.convertDateInTimezone(endDate)
      );
      datesArrRef.current = datesArr;
      let labeslsArr = [];

      // let startDate = commonService.convertDateInTimezone(selectedDate);
      // let ed = moment(startDate).toDate();
      // ed.setDate(ed.getDate() - 31);
      // let endDate = commonService.convertDateInTimezone(ed);

      // let datesArr = commonService.getDateBetweenTwoDates(
      //   commonService.convertDateInTimezone(endDate),
      //   commonService.convertDateInTimezone(startDate)
      // );
      // datesArrRef.current = datesArr;

      let chartDetailObj = {
        // labels: [
        //   31, 30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15,
        //   14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0,
        // ],
        labels: [],
        datasets: [
          {
            type: "line",
            label: "Forecast",
            data: [],
            fill: false,
            backgroundColor: "#152f5a",
            borderColor: "#152f5a",
            borderWidth: 2,
            order: 0,
            pointRadius: 5,
            pointBorderWidth: 4,
            pointBorderColor: "#ffffff",
            pointBorderRadius: 10,
          },
          {
            type: "line",
            label: "OTB",
            data: [],
            fill: false,
            backgroundColor: "#bd70e4",
            borderColor: "#bd70e4",
            borderWidth: 2,
            order: 0,
            pointRadius: 5,
            pointBorderWidth: 4,
            pointBorderColor: "#ffffff",
            pointBorderRadius: 10,
          },
          {
            type: "line",
            label: "Nova",
            data: [],
            fill: false,
            backgroundColor: "#4F98CA",
            borderColor: "#4F98CA",
            borderWidth: 2,
            order: 0,
            pointRadius: 5,
            pointBorderWidth: 4,
            pointBorderColor: "#ffffff",
            pointBorderRadius: 10,
          },
        ],
      };

      let dataFromApi = response?.data?.data?.data;

      datesArr?.map((item, index) => {
        labeslsArr.push(index);
        let findDataFromApi = dataFromApi?.filter(
          (x) =>
            moment(x?.AsOfDate).format("YYYY-MM-DD") ===
            moment(item).format("YYYY-MM-DD")
        );

        // chartDetailObj?.labels.push(datesArr?.length - index);

        chartDetailObj?.datasets?.[0]?.data?.push(
          findDataFromApi?.[0]?.forecast_room
            ? findDataFromApi?.[0]?.forecast_room
            : 0
        );

        chartDetailObj?.datasets?.[1]?.data?.push(
          findDataFromApi?.[0]?.actual_room
            ? findDataFromApi?.[0]?.actual_room
            : 0
        );

        chartDetailObj?.datasets?.[2]?.data?.push(
          findDataFromApi?.[0]?.nova_forecast_room
            ? findDataFromApi?.[0]?.nova_forecast_room
            : 0
        );
      });
      chartDetailObj.labels = labeslsArr?.reverse();

      setChartData(chartDetailObj);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setChartData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate && selectedDate) {
        // setSelectedDate(para?.asOfDate);
        getChartDetail();
      }
    }
  }, [selectedDate, para?.asOfDate]);

  let options = {};
  if (chartData) {
    options = {
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          title: {
            display: false,
            text: "LeadTime",
          },
          grid: {
            display: false,
          },
          // ticks: {
          //   callback: function (value, index) {
          //     return da index;
          //   },
          // },
        },
        y: {
          stacked: false,
          beginAtZero: true,
          type: "linear",
          position: "left",
          ticks: {
            stepSize: 10,
            callback: function (value, index, values) {
              return `${value}`;
            },
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          align: "center",
          position: "bottom",
          paddingTop: 10,
          maxWidth: 20,
          maxHeight: 100,
          labels: {
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        tooltip: {
          enabled: true,
          mode: "index",
          callbacks: {
            title: function (context) {
              let labelText = context[0].label || "";
              let index = context[0].dataIndex;
              let date = datesArrRef.current[index];
              let formatedDate = moment(date).format("dddd MMMM D YYYY");
              return `${formatedDate}`;
            },
            label: function (context) {
              let label = context.dataset.label || "";
              return `${label}: ${context.raw}`;
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 4 / 1,
    };
  }
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );

  return (
    <div className="widget-container">
      <Col xl={12}>
        <div className="d-flex">
          <div
            className="d-flex align-items-center gap-2"
          >
            <Form.Label className="width75" style={{ marginBottom: 0 }}>
              Check In Date
            </Form.Label>
            <div className="width100 from-groups no-border-break">
              <DatePicker
                id="checkInDate"
                selected={selectedDate ? new Date(selectedDate) : ""}
                onChange={(date) => {
                  setSelectedDate(date);
                }}
              />
            </div>
          </div>
        </div>
      </Col>

      <div className="snapShotHorizontalChart">
        <div className="ForecastHorizontalChartInner">
          {loading ? (
            <Loader />
          ) : chartData ? (
            <Line data={chartData} options={options} />
          ) : (
            <div className="d-flex justify-content-center">
              <p>No Data Found</p>
            </div>
          )}
        </div>
      </div>
      {api !== 0 && (
        <Comments widgetDetails={para} editorId={"ForecastVsOTBChart"} />
      )}
    </div>
  );
};

export default ForecastVsOTBChart;
