import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Row, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import { colValue, compName, month } from "../../../../utils/constant";
import Loader from "../../../Loader/Loader";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import WidgetNote from "../../WidgetNote";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import WidgetDetailModal from "../../WidgetDetailModal";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import { errorToastMessage } from "../../../../utils/toastMessage";
import AnnualSummaryChatModal from "./AnnualSummaryChatModal";
import chatIcon from "../../../../Assets/Images/Revenue-management/chat.svg";
import moment from "moment";
const AnnualSummary = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const userDetail = commonService.getLoginUserData();
  const widgetDetailRef = useRef();
  const [widgetModal, setWidgetModal] = useState(false);
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const OTBTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0 },
  ];
  const netStlyTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0 },
  ];
  const totalLyTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0 },
  ];
  const budgetTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0 },
  ];
  const emaForecastTableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
  ];
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [OTBData, setOTBData] = useState(null);
  const [netStlyData, setNetStlyData] = useState(null);
  const [totalLyData, setTotalLyData] = useState(null);
  const [budgetData, setBudgetData] = useState(null);
  const [emaForecastData, setEmaForecastData] = useState(null);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  //   const [loading, setLoading] = useState({
  //     otb: false,
  //     netStly: false,
  //     totalLy: false,
  //     budget: false,
  //     emaForecast: false,
  //   });

  const [otbLoading, setOtbLoading] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [netStlyLoading, setNetStlyLoading] = useState(false);
  const [totalLyLoading, setTotalLyLoading] = useState(false);
  const [emaForecastLoading, setEmaForecastLoading] = useState(false);
  const [selectedForecast, setSelectedForecast] = useState("ema");
  const [showVariance, setShowVariance] = useState(false);
  const [varianceData, setVarianceData] = useState(null);
  const [selectedBudgetVariance, setSelectedBudgetVariance] = useState("");
  const [budgetVarianceData, setBudgetVarianceData] = useState(null);
  const [selectedForecastVariance, setSelectedForecastVariance] = useState("");
  const [forecastVarianceData, setForecastVarianceData] = useState(null);
  const [showChatModal, setShowChatModal] = useState(false);
  const chatApiPayloadRef = useRef();

  const closeChatModal = () => {
    setShowChatModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  let otbocc = 0,
    otbrms = 0,
    otbadr = 0,
    otbrev = 0,
    netStlyOcc = 0,
    netStlyRms = 0,
    netStlyAdr = 0,
    netStlyRev = 0,
    totalLyOcc = 0,
    totalLyRms = 0,
    totalLyAdr = 0,
    totalLyRev = 0,
    budgetOcc = 0,
    budgetRms = 0,
    budgetAdr = 0,
    budgetRev = 0,
    emaForecastOcc = 0,
    emaForecastRms = 0,
    emaForecastAdr = 0,
    emaForecastRev = 0,
    emaForecastVar = 0,
    varianceNetStlyRms = 0,
    varianceTotalLyRms = 0,
    varianceBudgetRms = 0,
    varianceForecastRms = 0,
    budgetVarianceOtbRms = 0;
  const getOnTheBookDetail = async () => {
    setOtbLoading(true);
    let onTheBookResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.ANNUAL_SUMMARY.ON_THE_BOOK,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, onTheBookResponse);
    if (response?.isValidate) {
      setOTBData(response?.data?.data);
      setOtbLoading(false);
    }

    if (!response?.isValidate) {
      setOTBData(OTBTableData);
      setOtbLoading(false);
    }
  };

  const getNetStlyDetail = async () => {
    setNetStlyLoading(true);
    let netStlyDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.ANNUAL_SUMMARY.NET_STLY,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, netStlyDetailResponse);
    if (response?.isValidate) {
      setNetStlyData(response?.data?.data);
      setNetStlyLoading(false);
    }

    if (!response?.isValidate) {
      setNetStlyData(netStlyTableData);
      setNetStlyLoading(false);
    }
  };

  const getTotalLyDetail = async () => {
    setTotalLyLoading(true);
    let totalLyDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.ANNUAL_SUMMARY.TOTAL_LY,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, totalLyDetailResponse);
    if (response?.isValidate) {
      setTotalLyData(response?.data?.data);
      setTotalLyLoading(false);
    }

    if (!response?.isValidate) {
      setTotalLyData(totalLyTableData);
      setTotalLyLoading(false);
    }
  };

  const getBudgetDetail = async () => {
    setBudgetLoading(true);
    let budgetDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.ANNUAL_SUMMARY.BUDGET,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, budgetDetailResponse);
    if (response?.isValidate) {
      setBudgetData(response?.data?.data);
      setBudgetLoading(false);
    }

    if (!response?.isValidate) {
      setBudgetData(budgetTableData);
      setBudgetLoading(false);
    }
  };

  const getRmsDetail = async () => {
    setEmaForecastLoading(true);
    let rmsDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.RMS_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, rmsDetailResponse);
    if (response?.isValidate) {
      setEmaForecastData(response?.data?.data);
      setEmaForecastLoading(false);
    }

    if (!response?.isValidate) {
      setEmaForecastData(emaForecastTableData);
      setEmaForecastLoading(false);
    }
  };

  const getEmaForecastDetail = async () => {
    setEmaForecastLoading(true);
    let budgetDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.SPIDER_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, budgetDetailResponse);
    if (response?.isValidate) {
      setEmaForecastData(response?.data?.data);
      setEmaForecastLoading(false);
    }

    if (!response?.isValidate) {
      setEmaForecastData(emaForecastTableData);
      setEmaForecastLoading(false);
    }
  };

  const getUserDetail = async () => {
    setEmaForecastLoading(true);
    let userDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.USER_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, userDetailResponse);
    if (response?.isValidate) {
      setEmaForecastData(response?.data?.data);
      setEmaForecastLoading(false);
    }

    if (!response?.isValidate) {
      setEmaForecastData(emaForecastTableData);
      setEmaForecastLoading(false);
    }
  };

  const getNovaDetail = async () => {
    setEmaForecastLoading(true);
    let userDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.NOVA_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, userDetailResponse);
    if (response?.isValidate) {
      setEmaForecastData(response?.data?.data);
      setEmaForecastLoading(false);
    }

    if (!response?.isValidate) {
      setEmaForecastData(emaForecastTableData);
      setEmaForecastLoading(false);
    }
  };

  const [tblids, settblids] = useState({
    AnnualSummaryMonth: commonService.GenerateGUID(),
    onThebookWidget: commonService.GenerateGUID(),
    netStlyWidget: commonService.GenerateGUID(),
    totalLyWidget: commonService.GenerateGUID(),
    budgetWidget: commonService.GenerateGUID(),
    emaForecast: commonService.GenerateGUID(),
  });

  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.onThebookWidget} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.netStlyWidget} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.totalLyWidget} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.budgetWidget} .perbackground-rev`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.emaForecast} .perbackground-rev`
  );

  useEffect(() => {
    if (api === 0) {
      setOTBData(OTBTableData);
      setNetStlyData(netStlyTableData);
      setTotalLyData(totalLyTableData);
      setBudgetData(budgetTableData);
      setEmaForecastData(emaForecastTableData);
    } else {
      if (para?.asOfDate) {
        getOnTheBookDetail();
        getNetStlyDetail();
        getTotalLyDetail();
        getBudgetDetail();
      }
    }
  }, [para?.asOfDate, selectedYear]);

  useEffect(() => {
    if (api === 0) {
      setEmaForecastData(emaForecastTableData);
    } else {
      if (para?.asOfDate) {
        getEmaForecastDetail();
      }
    }
  }, [para?.asOfDate]);

  useEffect(() => {
    if (api === 1 && para?.asOfDate) {
      if (selectedForecast === "ema") {
        getEmaForecastDetail();
      } else if (selectedForecast === "rms") {
        getRmsDetail();
      } else if (selectedForecast === "user") {
        getUserDetail();
      } else if(selectedForecast === "nova"){
        getNovaDetail();
      }
    }
  }, [selectedYear, selectedForecast]);

  let AllWidgetContainer = document.getElementById(
    "AnnualSummaryAllWidgetInSingle"
  );
  if (AllWidgetContainer !== null) {
    let CW = AllWidgetContainer.clientWidth;
    CW = CW - 120;
    CW = Math.round(CW / 5);
    if (CW < 250) {
      CW = 250;
    }
    let Widget = AllWidgetContainer.getElementsByClassName(
      "allTableTogather-widget"
    );
    if (Widget !== null && Widget.length !== 0) {
      for (let i = 0; i < Widget.length; i++) {
        Widget[i].style.width = CW + "px";
      }
    }
  }

  const handleExport = async (type) => {
    let tableId = document.querySelector("#AnnualSummaryAllWidgetInSingle");

    if (tableId.querySelector(".loding-animation")) {
      errorToastMessage("Wait for the data loading.");
      return;
    }

    let groupingJson = {
      data: [],
      filename: `${para?.propertyCode}-Annual Summary-${moment(
        new Date()
      ).format("MMDDYYYY")}`,
    };

    let groupingRowData = [
      {
        widgetname: "AnnualSummary",
        rowdata: [],
      },
    ];
    let totalJson = [
      {
        widgetname: "BlankCommon",
        rowdata: [{ data: ["Total"] }],
      },
    ];

    let rowItem = document.querySelector("#AnnualSummaryAllWidgetInSingle");

    // let rowTitleName = document?.querySelector(
    //   ".generalTermSetup-heading h2"
    // )?.innerText;

    groupingRowData[0].rowdata.push({
      data: para?.rowTitle ? [para?.rowTitle] : [],
    });
    groupingRowData[0].rowdata.push({
      data: [],
    });

    let findMonthTable = rowItem?.querySelector(
      ".allTableTogatherWrapper .ViewPropertySetupTable table"
    );

    let findMonthTableHeader = findMonthTable?.querySelectorAll("thead tr");
    let findMonthTableTbody = findMonthTable?.querySelectorAll("tbody tr");

    findMonthTableHeader?.forEach((tblHeaderItem, tblHeaderIndex) => {
      if (tblHeaderIndex === 0) {
        let text = tblHeaderItem.querySelector("tr th span").innerText;
        groupingRowData[0].rowdata.push({
          data: [text ? text : ""],
        });
      } else {
        let text = tblHeaderItem.querySelector("tr th").innerText;
        groupingRowData[0].rowdata.push({
          data: [text ? text : ""],
        });
      }
    });

    findMonthTableTbody?.forEach((tblBodyItem, tblBodyIndex) => {
      let text = tblBodyItem.querySelector("td").innerText;

      if (findMonthTableTbody?.length - 1 === tblBodyIndex) {
      } else {
        groupingRowData[0].rowdata.push({
          data: [text ? text : ""],
        });
      }
    });

    const getAllTableData = (tbl) => {
      let findMonthTable = tbl?.querySelector(
        ".allTableTogather-widget .ViewPropertySetupTable table"
      );

      let findMonthTableHeader = findMonthTable?.querySelectorAll("thead tr");
      let findMonthTableTbody = findMonthTable?.querySelectorAll("tbody tr");

      findMonthTableHeader.forEach((tblHeaderItem, tblHeaderIndex) => {
        let tblTh = tblHeaderItem.querySelectorAll("tr th");
        let tblSpan = "";
        if (rowIndex === 0) {
          tblSpan = tblHeaderItem.querySelector("tr th span");
        }

        tblTh?.forEach((tblThItem) => {
          let datePickerExists = tblThItem.querySelector(
            ".react-datepicker-wrapper"
          );

          let varianceClassExists =
            tblThItem.classList.contains("varianceField");

          if (!datePickerExists) {
            if (!varianceClassExists) {
              let text = tblSpan ? tblSpan?.innerText : tblThItem?.innerText;
              groupingRowData[0].rowdata[tblHeaderIndex + 2].data.push(
                text ? (text === "ema" ? "SYSTEM" : text) : ""
              );
            }
          }
        });
      });

      findMonthTableTbody.forEach((tblBodyItem, tblBodyIndex) => {
        let tblTd = tblBodyItem.querySelectorAll("td");

        tblTd?.forEach((tblTdItem, tblTdIndex) => {
          let text = tblTdItem?.innerText;
          let varianceClassExists = tblTdItem.classList.contains("d-none");
          if (!varianceClassExists) {
            if (findMonthTableTbody?.length - 1 === tblBodyIndex) {
              totalJson[0].rowdata[0].data.push(text ? text : "");
            } else {
              groupingRowData[0].rowdata[tblBodyIndex + 4].data.push(
                text ? text : ""
              );
            }
          }
        });
      });
    };

    let findOtherTable = rowItem?.querySelectorAll(
      ".allTableTogather .allTableTogather-widget"
    );

    findOtherTable?.forEach((otherTblItem) => {
      getAllTableData(otherTblItem);
    });

    groupingJson.data = [...groupingJson.data, ...groupingRowData];
    groupingJson.data = [...groupingJson.data, ...totalJson];
    groupingJson = {
      ...groupingJson,
      snapshotid: snapshotId ? parseInt(snapshotId) : 0,
      sendtoemails: userDetail?.email ? userDetail?.email : "",
      actiontype: type,
      asofdata: para?.asOfDate
        ? commonService.getDateInDBFormat(para?.asOfDate)
        : "",
    };
    await commonService.widgetWiseExportExcel(groupingJson);
  };

  const handleChatBotJson = async () => {
    let tableId = document?.querySelector("#AnnualSummaryAllWidgetInSingle");

    if (tableId?.querySelector(".loding-animation")) {
      errorToastMessage("Wait for the data loading.");
      return;
    }
    let groupingJson = {
      data: [
        { month: "January" },
        { month: "February" },
        { month: "March" },
        { month: "April" },
        { month: "May" },
        { month: "June" },
        { month: "July" },
        { month: "August" },
        { month: "September" },
        { month: "October" },
        { month: "November" },
        { month: "December" },
      ],
    };

    let getAllTable = tableId?.querySelectorAll(
      ".allTableTogather .allTableTogather-widget .ViewPropertySetupTable .stripped__table table"
    );

    getAllTable?.forEach((tableItem, tableIndex) => {
      let getTbody = tableItem?.querySelectorAll("tbody tr");
      let tableName = "otb";
      if (tableIndex === 1) {
        tableName = "stly";
      } else if (tableIndex === 2) {
        tableName = "ly";
      } else if (tableIndex === 3) {
        tableName = "budget";
      } else if (tableIndex === 4) {
        tableName = "forecast";
      }

      getTbody?.forEach((tbodyItem, tbodyIndex) => {
        let getAllTds = tbodyItem?.querySelectorAll("td");

        getAllTds?.forEach((tdItem, tdIndex) => {
          if (tbodyIndex !== getTbody?.length - 1) {
            let keyName = "occ";
            if (tdIndex === 1) {
              keyName = "rms";
            } else if (tdIndex === 2) {
              keyName = "adr";
            } else if (tdIndex === 3) {
              keyName = "rev";
            }

            groupingJson.data[tbodyIndex] = {
              ...groupingJson.data[tbodyIndex],
              [`${tableName}${keyName}`]: tdItem?.innerText
                ? tdItem?.innerText
                : "",
            };
          }
        });
      });
    });
    chatApiPayloadRef.current = groupingJson;
    setShowChatModal(true);
    return groupingJson;
  };

  const getVarianceData = async () => {
    let varianceDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.ANNUAL_SUMMARY.GET_VARIANCE,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, varianceDataResponse);

    if (response?.isValidate) {
      setVarianceData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setVarianceData(null);
    }
  };

  const getBudgetVariance = async () => {
    let budgetVarinaceResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.ANNUAL_SUMMARY.GET_BUDGET_VARIANCE,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, budgetVarinaceResponse);

    if (response?.isValidate) {
      setBudgetVarianceData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setBudgetVarianceData(null);
    }
  };

  const getForecastVariance = async () => {
    let forecastVarianceApi =
      API_URL.ANNUAL_SUMMARY.GET_SYSTEM_FORECAST_VARIANCE;

    if (selectedForecast === "rms") {
      forecastVarianceApi = API_URL.ANNUAL_SUMMARY.GET_RMS_FORECAST_VARIANCE;
    } else if (selectedForecast === "user") {
      forecastVarianceApi = API_URL.ANNUAL_SUMMARY.GET_USER_FORECAST_VARIANCE;
    }
    let forecastVarinaceResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + forecastVarianceApi,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, forecastVarinaceResponse);

    if (response?.isValidate) {
      setForecastVarianceData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setForecastVarianceData(null);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getVarianceData();
        getBudgetVariance();
      }
    }
  }, [para?.asOfDate, selectedYear]);

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getForecastVariance();
      }
    }
  }, [para?.asOfDate, selectedYear, selectedForecast]);

  const getTotalAdr = (type) => {
    let finalAdr = 0;
    let otbTotalAdr =
      otbrev !== 0 && otbrms !== 0 ? parseInt(otbrev / otbrms) : 0;
    if (type === "netStly") {
      let netStlyTotalAdr =
        netStlyRev !== 0 && netStlyRms !== 0
          ? parseInt(netStlyRev / netStlyRms)
          : 0;
      finalAdr =
        otbTotalAdr && netStlyTotalAdr ? otbTotalAdr - netStlyTotalAdr : 0;
    } else if (type === "totalLy") {
      let totalLyTotalAdr =
        totalLyRev !== 0 && totalLyRms !== 0
          ? parseInt(totalLyRev / totalLyRms)
          : 0;
      finalAdr =
        otbTotalAdr && totalLyTotalAdr ? otbTotalAdr - totalLyTotalAdr : 0;
    } else if (type === "Budget") {
      let BudgetTotalAdr =
        budgetRev !== 0 && budgetRms !== 0
          ? parseInt(budgetRev / budgetRms)
          : 0;
      finalAdr =
        otbTotalAdr && BudgetTotalAdr ? otbTotalAdr - BudgetTotalAdr : 0;
    } else if (type === "forecast") {
      let forecastAdr =
        emaForecastRev !== 0 && emaForecastRms !== 0
          ? parseInt(emaForecastRev / emaForecastRms)
          : 0;
      finalAdr = otbTotalAdr && forecastAdr ? otbTotalAdr - forecastAdr : 0;
    }
    return finalAdr;
  };

  const getTotalAdrForBudget = (type) => {
    let finalAdr = 0;
    let budgetTotalAdr =
      budgetRev !== 0 && budgetRms !== 0 ? parseInt(budgetRev / budgetRms) : 0;

    if (type === "otb") {
      let otbTotalAdr =
        otbrev !== 0 && otbrms !== 0 ? parseInt(otbrev / otbrms) : 0;
      finalAdr =
        budgetTotalAdr && otbTotalAdr ? budgetTotalAdr - otbTotalAdr : 0;
    }

    return finalAdr;
  };

  const getTotalAdrVarianceForBudget = (type) => {
    let finalAdrVariance = 0;
    let budgetTotalAdr =
      budgetRev !== 0 && budgetRms !== 0 ? parseInt(budgetRev / budgetRms) : 0;

    if (type === "otb") {
      let otbTotalAdr =
        otbrev !== 0 && otbrms !== 0 ? parseInt(otbrev / otbrms) : 0;
      finalAdrVariance =
        budgetTotalAdr > 0 && otbTotalAdr > 0
          ? (budgetTotalAdr * 100) / otbTotalAdr - 100
          : 0;
    }

    return finalAdrVariance;
  };

  const valueForBudgetVariance = (keyName, data) => {
    return data?.[0]?.[keyName] ? data?.[0]?.[keyName] : 0;
  };

  const updateTableTotalValue = (
    tableId,
    currentTblAdrTotal,
    currentTblRevTotal,
    varianceType
  ) => {
    let budgetTotalAdr =
      budgetRev !== 0 && budgetRms !== 0 ? parseInt(budgetRev / budgetRms) : 0;

    let forecastTotalAdr =
      emaForecastRev !== 0 && emaForecastRms !== 0
        ? parseInt(emaForecastRev / emaForecastRms)
        : 0;
    let findTable = document.getElementById(tableId);

    let tblBody = findTable?.querySelectorAll("tbody tr");

    let lastRow = tblBody[tblBody?.length - 1];
    let lastRowAllTd = lastRow?.querySelectorAll("td");

    let budgetFinalAdr = budgetTotalAdr - currentTblAdrTotal;

    let ForecastFinalAdr = forecastTotalAdr - currentTblAdrTotal;

    let budgetFinalAdrVariance =
      budgetTotalAdr && currentTblAdrTotal
        ? (budgetTotalAdr * 100) / currentTblAdrTotal - 100
        : 0;

    let forecastFinalAdrVariance =
      forecastTotalAdr && currentTblAdrTotal
        ? (forecastTotalAdr * 100) / currentTblAdrTotal - 100
        : 0;

    let budgetFinalRev = budgetRev - currentTblRevTotal;

    let forecastFinalRev = emaForecastRev - currentTblRevTotal;

    let budgetFinalRevVariance =
      budgetRev && currentTblRevTotal
        ? (budgetRev * 100) / currentTblRevTotal - 100
        : 0;

    let forecastFinalRevVariance =
      emaForecastRev && currentTblRevTotal
        ? (emaForecastRev * 100) / currentTblRevTotal - 100
        : 0;

    lastRowAllTd?.forEach((item, index) => {
      if (varianceType === "budget") {
        if (index === 2) {
          item.innerText = `${commonService.formateAmount(budgetFinalAdr)}`;
        }
        if (index === 3) {
          item.innerText = `${budgetFinalAdrVariance?.toFixed(1)}%`;
        }
        if (index === 4) {
          item.innerText = `${commonService.formateAmount(budgetFinalRev)}`;
        }
        if (index === 5) {
          item.innerText = `${budgetFinalRevVariance?.toFixed(1)}%`;
        }
      } else if (varianceType === "forecast") {
        if (index === 2) {
          item.innerText = `${commonService.formateAmount(ForecastFinalAdr)}`;
        }
        if (index === 3) {
          item.innerText = `${forecastFinalAdrVariance?.toFixed(1)}%`;
        }
        if (index === 4) {
          item.innerText = `${commonService.formateAmount(forecastFinalRev)}`;
        }
        if (index === 5) {
          item.innerText = `${forecastFinalRevVariance?.toFixed(1)}%`;
        }
      }
    });
  };

  const getTotalForVariance = () => {
    if (selectedBudgetVariance === "otb") {
      let otbTotalAdr =
        otbrev !== 0 && otbrms !== 0 ? parseInt(otbrev / otbrms) : 0;

      updateTableTotalValue(
        tblids.onThebookWidget,
        otbTotalAdr,
        otbrev,
        "budget"
      );
    } else if (selectedForecastVariance === "otb") {
      let otbTotalAdr =
        otbrev !== 0 && otbrms !== 0 ? parseInt(otbrev / otbrms) : 0;

      updateTableTotalValue(
        tblids.onThebookWidget,
        otbTotalAdr,
        otbrev,
        "forecast"
      );
    } else if (selectedBudgetVariance === "netStly") {
      let netStlyTotalAdr =
        netStlyRev !== 0 && netStlyRms !== 0
          ? parseInt(netStlyRev / netStlyRms)
          : 0;

      updateTableTotalValue(
        tblids.netStlyWidget,
        netStlyTotalAdr,
        netStlyRev,
        "budget"
      );
    } else if (selectedForecastVariance === "netStly") {
      let netStlyTotalAdr =
        netStlyRev !== 0 && netStlyRms !== 0
          ? parseInt(netStlyRev / netStlyRms)
          : 0;

      updateTableTotalValue(
        tblids.netStlyWidget,
        netStlyTotalAdr,
        netStlyRev,
        "forecast"
      );
    } else if (selectedBudgetVariance === "totalLy") {
      let totalLyTotalAdr =
        totalLyRev !== 0 && totalLyRms !== 0
          ? parseInt(totalLyRev / totalLyRms)
          : 0;

      updateTableTotalValue(
        tblids.totalLyWidget,
        totalLyTotalAdr,
        totalLyRev,
        "budget"
      );
    } else if (selectedForecastVariance === "totalLy") {
      let totalLyTotalAdr =
        totalLyRev !== 0 && totalLyRms !== 0
          ? parseInt(totalLyRev / totalLyRms)
          : 0;

      updateTableTotalValue(
        tblids.totalLyWidget,
        totalLyTotalAdr,
        totalLyRev,
        "forecast"
      );
    } else if (selectedForecastVariance === "budget") {
      let budgetTotalAdr =
        budgetRev !== 0 && budgetRms !== 0
          ? parseInt(budgetRev / budgetRms)
          : 0;

      updateTableTotalValue(
        tblids.budgetWidget,
        budgetTotalAdr,
        budgetRev,
        "forecast"
      );
    } else if (
      selectedBudgetVariance === "ema" ||
      selectedBudgetVariance === "rms" ||
      selectedBudgetVariance === "user"
    ) {
      let forecastTotalAdr =
        emaForecastRev !== 0 && emaForecastRms !== 0
          ? parseInt(emaForecastRev / emaForecastRms)
          : 0;

      updateTableTotalValue(
        tblids.emaForecast,
        forecastTotalAdr,
        emaForecastRev,
        "budget"
      );
    }
  };

  const handleAnnualSummaryChat = () => {
    let tableId = document?.querySelector("#AnnualSummaryAllWidgetInSingle");

    if (tableId?.querySelector(".loding-animation")) {
      errorToastMessage("Wait for the data loading.");
      return;
    }
    let groupingJson = {
      propertyid: para?.propertyDetail?.propertyid,
      clientid: userDetail?.clientid,
      userid: userDetail?.userid,
      prompt: "Please generate summary",
      json: {},
    };

    let getAllTable = tableId?.querySelectorAll(
      ".allTableTogather .allTableTogather-widget .ViewPropertySetupTable .stripped__table table"
    );

    getAllTable?.forEach((tableItem, tableIndex) => {
      let getTbody = tableItem?.querySelectorAll("tbody tr");
      let tableName = "On The Books";
      if (tableIndex === 1) {
        tableName = "Net STLY";
      } else if (tableIndex === 2) {
        tableName = "Total LY";
      } else if (tableIndex === 3) {
        tableName = "Budget";
      } else if (tableIndex === 4) {
        tableName = "Forecast";
      }
      let arr = [];
      const removeSymbol = (value) => {
        let data = value?.replaceAll("$", "");
        let finalValue = data?.replaceAll(",", "");
        return finalValue;
      };

      getTbody?.forEach((tbodyItem, tbodyIndex) => {
        if (tbodyIndex !== getTbody?.length - 1) {
          let getAllTds = tbodyItem?.querySelectorAll("td");
          let obj = {
            propertyCode: para?.propertyCode,
            AsOfDate: para?.asOfDate,
            year: selectedYear,
            month: month[tbodyIndex],
          };

          getAllTds?.forEach((tdItem, tdIndex) => {
            if (tbodyIndex !== getTbody?.length - 1) {
              if (tdIndex == 0) {
                obj.occ = removeSymbol(tdItem?.innerText);
              } else if (tdIndex === 1) {
                obj.rms = removeSymbol(tdItem?.innerText);
              } else if (tdIndex === 2) {
                obj.adr = removeSymbol(tdItem?.innerText);
              } else if (tdIndex === 3) {
                obj.rev = removeSymbol(tdItem?.innerText);
              }
            }
          });
          arr.push(obj);
        }
      });
      groupingJson.json[tableName] = arr;
    });
  };

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {process.env.REACT_APP_ENVIORMENT !== "ema" &&
            showVariance === false &&
            selectedBudgetVariance === "" &&
            selectedForecastVariance === "" && (
              <Button
                className="heading-menu-btns"
                type="button"
                title="Annual Summary Detail"
              >
                <img
                  src={chatIcon}
                  alt="chatIcon"
                  onClick={() => {
                    handleChatBotJson();
                  }}
                />
              </Button>
            )}

          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                if (
                  window.location.href.includes(
                    "Annual%20Summary%20(RevPAK)"
                  ) ||
                  window.location.href.includes("RevPAK%20Summary")
                ) {
                  handleExport("EMAIL");
                } else {
                  commonService.fnSendWidgetMail(
                    tblids.AnnualSummaryMonth +
                      "," +
                      tblids.onThebookWidget +
                      "," +
                      tblids.netStlyWidget +
                      "," +
                      tblids.totalLyWidget +
                      "," +
                      tblids.budgetWidget +
                      "," +
                      tblids.emaForecast,
                    "Annual Summary",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.annualSummary,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={
                tblids.AnnualSummaryMonth +
                "," +
                tblids.onThebookWidget +
                "," +
                tblids.netStlyWidget +
                "," +
                tblids.totalLyWidget +
                "," +
                tblids.budgetWidget +
                "," +
                tblids.emaForecast
              }
              onClick={() => {
                if (
                  window.location.href.includes(
                    "Annual%20Summary%20(RevPAK)"
                  ) ||
                  window.location.href.includes("RevPAK%20Summary")
                ) {
                  handleExport("DOWNLOAD");
                } else {
                  commonService.fnExportInCSV(
                    tblids.AnnualSummaryMonth +
                      "," +
                      tblids.onThebookWidget +
                      "," +
                      tblids.netStlyWidget +
                      "," +
                      tblids.totalLyWidget +
                      "," +
                      tblids.budgetWidget +
                      "," +
                      tblids.emaForecast,
                    // "Annual Summary"
                    `${para?.propertyCode}-Annual Summary-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  );
                }
              }}
            />
          </Button>
        </div>
      )}
      <Row>
        <div
          className="d-flex allTableTogatherWrapper"
          id="AnnualSummaryAllWidgetInSingle"
        >
          <div style={{ width: "110px" }} className="pe-0">
            <div className="ViewPropertySetupTable monthSelectTable">
              <div className="stripped__table">
                <Table id={tblids.AnnualSummaryMonth} responsive>
                  <thead>
                    <tr>
                      <th>
                        <div>
                          <Form.Select
                            value={selectedYear}
                            onChange={(e) => {
                              setSelectedYear(e.target.value);
                            }}
                            style={{ padding: 0 }}
                          >
                            <option value="">Select</option>
                            {yearsArr?.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                          <span style={{ display: "none" }}>
                            {selectedYear}
                          </span>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>Month</th>
                    </tr>
                  </thead>
                  <tbody>
                    {month?.map((item, index) => (
                      <tr key={item}>
                        <td>{item}</td>
                      </tr>
                    ))}
                    <tr>
                      <td className="boldTd">Total</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="allTableTogather">
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.onThebookWidget} responsive>
                    <thead>
                      <tr>
                        <th
                          colSpan={
                            selectedBudgetVariance === "otb" ||
                            selectedForecastVariance === "otb"
                              ? 4
                              : 2
                          }
                          className="text-center"
                        >
                          On The Books
                        </th>
                        <th colSpan={2} className="varianceField">
                          <div className="form-check form-switch no-border-break from-groups jay-put-this-checkbox">
                            <input
                              className="form-check-input me-2"
                              type="checkbox"
                              role="switch"
                              checked={showVariance}
                              onChange={() => {
                                setSelectedBudgetVariance("");
                                setShowVariance(!showVariance);
                              }}
                            />
                            <label className="form-check-label">Variance</label>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        {(selectedBudgetVariance === "otb" ||
                          selectedForecastVariance === "otb") && (
                          <th className="varianceField"></th>
                        )}
                        <th className="text-end">REV</th>
                        {(selectedBudgetVariance === "otb" ||
                          selectedForecastVariance === "otb") && (
                          <th className="varianceField"></th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        OTBData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === OTBData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : otbLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        (OTBData ||
                          budgetVarianceData ||
                          forecastVarianceData) &&
                        month?.map((item, index) => {
                          let findMonthData = OTBData?.filter(
                            (x) => x.month === item
                          );

                          let findBudgetVarianceData =
                            budgetVarianceData?.filter((x) => x.month === item);

                          let findForecastVarianceData =
                            forecastVarianceData?.filter(
                              (x) => x.month === item
                            );

                          let occ = 0,
                            rms = 0,
                            adr = 0,
                            rev = 0,
                            adrVariance = 0,
                            revVariance = 0;

                          otbrms =
                            otbrms +
                            (findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0);
                          otbadr =
                            otbadr +
                            (findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0);
                          otbrev =
                            otbrev +
                            (findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0);

                          if (selectedBudgetVariance === "otb") {
                            occ = valueForBudgetVariance(
                              "OTB_OCC",
                              findBudgetVarianceData
                            );
                            rms = valueForBudgetVariance(
                              "OTB_RMS",
                              findBudgetVarianceData
                            );
                            adr = valueForBudgetVariance(
                              "OTB_ADR",
                              findBudgetVarianceData
                            );
                            rev = valueForBudgetVariance(
                              "OTB_REV",
                              findBudgetVarianceData
                            );

                            adrVariance =
                              valueForBudgetVariance(
                                "Budget_ADR",
                                findBudgetVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("adr", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    "Budget_ADR",
                                    findBudgetVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "adr",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            revVariance =
                              valueForBudgetVariance(
                                "Budget_REV",
                                findBudgetVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("rev", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    "Budget_REV",
                                    findBudgetVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "rev",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            otbocc =
                              otbocc +
                              valueForBudgetVariance(
                                "OTB_OCC",
                                findBudgetVarianceData
                              );
                            budgetVarianceOtbRms =
                              budgetVarianceOtbRms +
                              valueForBudgetVariance(
                                "OTB_RMS",
                                findBudgetVarianceData
                              );
                          } else if (selectedForecastVariance === "otb") {
                            let keyName = "Forecast";
                            if (selectedForecast === "rms") {
                              keyName = "RMS_Forecast";
                            } else if (selectedForecast === "user") {
                              keyName = "USER_Forecast";
                            }
                            occ = valueForBudgetVariance(
                              "OTB_OCC",
                              findForecastVarianceData
                            );
                            rms = valueForBudgetVariance(
                              "OTB_RMS",
                              findForecastVarianceData
                            );
                            adr = valueForBudgetVariance(
                              "OTB_ADR",
                              findForecastVarianceData
                            );
                            rev = valueForBudgetVariance(
                              "OTB_REV",
                              findForecastVarianceData
                            );

                            adrVariance =
                              valueForBudgetVariance(
                                `${keyName}_ADR`,
                                findForecastVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("adr", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    `${keyName}_ADR`,
                                    findForecastVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "adr",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            revVariance =
                              valueForBudgetVariance(
                                `${keyName}_REV`,
                                findForecastVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("rev", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    `${keyName}_REV`,
                                    findForecastVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "rev",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            otbocc =
                              otbocc +
                              valueForBudgetVariance(
                                "OTB_OCC",
                                findForecastVarianceData
                              );
                            budgetVarianceOtbRms =
                              budgetVarianceOtbRms +
                              valueForBudgetVariance(
                                "OTB_RMS",
                                findForecastVarianceData
                              );
                          } else {
                            occ = valueForBudgetVariance("occ", findMonthData);
                            rms = valueForBudgetVariance("rms", findMonthData);
                            adr = valueForBudgetVariance("adr", findMonthData);
                            rev = valueForBudgetVariance("rev", findMonthData);

                            otbocc =
                              otbocc +
                              (findMonthData?.[0]?.occ
                                ? findMonthData?.[0]?.occ
                                : 0);
                          }

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td
                                  className={`${
                                    selectedBudgetVariance === "otb" ||
                                    selectedForecastVariance === "otb"
                                      ? occ > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {occ}%
                                </td>
                                <td
                                  className={`text-center ${
                                    selectedBudgetVariance === "otb" ||
                                    selectedForecastVariance === "otb"
                                      ? rms > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {commonService.formateRoom(rms)}
                                </td>
                                <td
                                  className={`text-end ${
                                    selectedBudgetVariance === "otb" ||
                                    selectedForecastVariance === "otb"
                                      ? adr > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {commonService.formateAmount(adr)}
                                </td>
                                {(selectedBudgetVariance === "otb" ||
                                  selectedForecastVariance === "otb") && (
                                  <td
                                    className={`text-end ${
                                      selectedBudgetVariance === "otb" ||
                                      selectedForecastVariance === "otb"
                                        ? adrVariance > 0
                                          ? "greenText"
                                          : "redText"
                                        : ""
                                    }`}
                                  >
                                    {adrVariance?.toFixed(1)}%
                                  </td>
                                )}

                                <td
                                  className={`text-end ${
                                    selectedBudgetVariance === "otb" ||
                                    selectedForecastVariance === "otb"
                                      ? ""
                                      : "perbackground perbackground-rev"
                                  } ${
                                    selectedBudgetVariance === "otb" ||
                                    selectedForecastVariance === "otb"
                                      ? rev > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                  value={rev}
                                >
                                  {commonService.formateAmount(rev)}
                                </td>
                                {(selectedBudgetVariance === "otb" ||
                                  selectedForecastVariance === "otb") && (
                                  <td
                                    className={`text-end ${
                                      selectedBudgetVariance === "otb" ||
                                      selectedForecastVariance === "otb"
                                        ? revVariance > 0
                                          ? "greenText"
                                          : "redText"
                                        : ""
                                    }`}
                                  >
                                    {revVariance?.toFixed(1)}%
                                  </td>
                                )}
                              </tr>
                              {index === 11 && (
                                <tr key={index}>
                                  <td className="boldTd">
                                    {parseInt(otbocc / 12)}%
                                  </td>
                                  <td className="boldTd text-center">
                                    {selectedBudgetVariance === "otb" ||
                                    selectedForecastVariance === "otb"
                                      ? commonService.formateRoom(
                                          budgetVarianceOtbRms
                                        )
                                      : commonService.formateRoom(otbrms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {selectedBudgetVariance === "otb"
                                      ? commonService.formateAmount(
                                          getTotalAdrForBudget("otb")
                                        )
                                      : otbrev !== 0 && otbrms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(otbrev / otbrms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>

                                  <td
                                    className={`boldTd text-end ${
                                      selectedBudgetVariance === "otb" ||
                                      selectedForecastVariance === "otb"
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    {getTotalAdrVarianceForBudget(
                                      "otb"
                                    )?.toFixed(1)}
                                    %
                                  </td>

                                  <td className="boldTd text-end">
                                    {selectedBudgetVariance === "otb"
                                      ? commonService.formateAmount(
                                          budgetRev - otbrev
                                        )
                                      : commonService.formateAmount(otbrev)}
                                  </td>

                                  <td
                                    className={`boldTd text-end ${
                                      selectedBudgetVariance === "otb" ||
                                      selectedForecastVariance === "otb"
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    {(
                                      (budgetRev * 100) / otbrev -
                                      100
                                    )?.toFixed(1)}
                                    %
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.netStlyWidget} responsive>
                    <thead>
                      <tr>
                        <th
                          colSpan={
                            showVariance ||
                            selectedBudgetVariance === "netStly" ||
                            selectedForecastVariance === "netStly"
                              ? 6
                              : 4
                          }
                          className="text-center"
                        >
                          Net Stly
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        {(showVariance ||
                          selectedBudgetVariance === "netStly" ||
                          selectedForecastVariance === "netStly") && (
                          <th className="text-end varianceField"></th>
                        )}
                        <th className="text-end">REV</th>
                        {(showVariance ||
                          selectedBudgetVariance === "netStly" ||
                          selectedForecastVariance === "netStly") && (
                          <th className="text-end varianceField"></th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        netStlyData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              {(showVariance ||
                                selectedBudgetVariance === "netStly") && (
                                <td></td>
                              )}
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                              {(showVariance ||
                                selectedBudgetVariance === "netStly") && (
                                <td></td>
                              )}
                            </tr>
                            {index === netStlyData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                {(showVariance ||
                                  selectedBudgetVariance === "netStly") && (
                                  <td className="boldTd text-end"></td>
                                )}
                                <td className="boldTd text-end">$0</td>
                                {(showVariance ||
                                  selectedBudgetVariance === "netStly") && (
                                  <td className="boldTd text-end"></td>
                                )}
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : netStlyLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        (netStlyData ||
                          varianceData ||
                          budgetVarianceData ||
                          forecastVarianceData) &&
                        month?.map((item, index) => {
                          let findMonthData = netStlyData?.filter(
                            (x) => x.month === item
                          );

                          let varianceMonthData = varianceData?.filter(
                            (x) => x?.month === item
                          );

                          let findBudgetVarianceData =
                            budgetVarianceData?.filter((x) => x.month === item);

                          let findForecastVarianceData =
                            forecastVarianceData?.filter(
                              (x) => x.month === item
                            );

                          let occ = 0,
                            rms = 0,
                            adr = 0,
                            rev = 0,
                            adrVariance = 0,
                            revVariance = 0;
                          netStlyAdr =
                            netStlyAdr +
                            (findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0);
                          netStlyRev =
                            netStlyRev +
                            (findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0);
                          netStlyRms =
                            netStlyRms +
                            (findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0);
                          if (showVariance) {
                            occ = varianceMonthData?.[0]?.STLY_OCC
                              ? varianceMonthData?.[0]?.STLY_OCC
                              : 0;
                            rms = varianceMonthData?.[0]?.STLY_RMS
                              ? varianceMonthData?.[0]?.STLY_RMS
                              : 0;
                            adr = varianceMonthData?.[0]?.STLY_ADR
                              ? varianceMonthData?.[0]?.STLY_ADR
                              : 0;
                            rev = varianceMonthData?.[0]?.STLY_REV
                              ? varianceMonthData?.[0]?.STLY_REV
                              : 0;
                            adrVariance =
                              varianceMonthData?.[0]?.OTB_ADR > 0 &&
                              findMonthData?.[0]?.adr > 0
                                ? ((varianceMonthData?.[0]?.OTB_ADR
                                    ? varianceMonthData?.[0]?.OTB_ADR
                                    : 0) *
                                    100) /
                                    (findMonthData?.[0]?.adr
                                      ? findMonthData?.[0]?.adr
                                      : 0) -
                                  100
                                : 0;

                            revVariance =
                              varianceMonthData?.[0]?.OTB_REV > 0 &&
                              findMonthData?.[0]?.rev > 0
                                ? ((varianceMonthData?.[0]?.OTB_REV
                                    ? varianceMonthData?.[0]?.OTB_REV
                                    : 0) *
                                    100) /
                                    (findMonthData?.[0]?.rev
                                      ? findMonthData?.[0]?.rev
                                      : 0) -
                                  100
                                : 0;

                            netStlyOcc =
                              netStlyOcc +
                              (varianceMonthData?.[0]?.STLY_OCC
                                ? varianceMonthData?.[0]?.STLY_OCC
                                : 0);
                            varianceNetStlyRms =
                              varianceNetStlyRms +
                              (varianceMonthData?.[0]?.STLY_RMS
                                ? varianceMonthData?.[0]?.STLY_RMS
                                : 0);
                          } else if (selectedBudgetVariance === "netStly") {
                            occ = valueForBudgetVariance(
                              "STLY_OCC",
                              findBudgetVarianceData
                            );
                            rms = valueForBudgetVariance(
                              "STLY_RMS",
                              findBudgetVarianceData
                            );
                            adr = valueForBudgetVariance(
                              "STLY_ADR",
                              findBudgetVarianceData
                            );
                            rev = valueForBudgetVariance(
                              "STLY_REV",
                              findBudgetVarianceData
                            );

                            adrVariance =
                              valueForBudgetVariance(
                                "Budget_ADR",
                                findBudgetVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("adr", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    "Budget_ADR",
                                    findBudgetVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "adr",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            revVariance =
                              valueForBudgetVariance(
                                "Budget_REV",
                                findBudgetVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("rev", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    "Budget_REV",
                                    findBudgetVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "rev",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            netStlyOcc =
                              netStlyOcc +
                              valueForBudgetVariance(
                                "STLY_OCC",
                                findBudgetVarianceData
                              );
                            varianceNetStlyRms =
                              varianceNetStlyRms +
                              valueForBudgetVariance(
                                "STLY_RMS",
                                findBudgetVarianceData
                              );
                          } else if (selectedForecastVariance === "netStly") {
                            let keyName = "Forecast";
                            if (selectedForecast === "rms") {
                              keyName = "RMS_Forecast";
                            } else if (selectedForecast === "user") {
                              keyName = "USER_Forecast";
                            }
                            occ = valueForBudgetVariance(
                              "STLY_OCC",
                              findForecastVarianceData
                            );
                            rms = valueForBudgetVariance(
                              "STLY_RMS",
                              findForecastVarianceData
                            );
                            adr = valueForBudgetVariance(
                              "STLY_ADR",
                              findForecastVarianceData
                            );
                            rev = valueForBudgetVariance(
                              "STLY_REV",
                              findForecastVarianceData
                            );

                            adrVariance =
                              valueForBudgetVariance(
                                `${keyName}_ADR`,
                                findForecastVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("adr", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    `${keyName}_ADR`,
                                    findForecastVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "adr",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            revVariance =
                              valueForBudgetVariance(
                                `${keyName}_REV`,
                                findForecastVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("rev", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    `${keyName}_REV`,
                                    findForecastVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "rev",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            otbocc =
                              otbocc +
                              valueForBudgetVariance(
                                "STLY_OCC",
                                findForecastVarianceData
                              );
                            varianceNetStlyRms =
                              varianceNetStlyRms +
                              valueForBudgetVariance(
                                "STLY_RMS",
                                findForecastVarianceData
                              );
                          } else {
                            occ = findMonthData?.[0]?.occ
                              ? findMonthData?.[0]?.occ
                              : 0;
                            rms = findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0;
                            adr = findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0;
                            rev = findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0;

                            netStlyOcc =
                              netStlyOcc +
                              (findMonthData?.[0]?.occ
                                ? findMonthData?.[0]?.occ
                                : 0);
                          }

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td
                                  className={`${
                                    showVariance ||
                                    selectedBudgetVariance === "netStly" ||
                                    selectedForecastVariance === "netStly"
                                      ? occ > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {occ}%
                                </td>
                                <td
                                  className={`text-center ${
                                    showVariance ||
                                    selectedBudgetVariance === "netStly" ||
                                    selectedForecastVariance === "netStly"
                                      ? rms > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {commonService.formateRoom(rms)}
                                </td>
                                <td
                                  className={`text-end ${
                                    showVariance ||
                                    selectedBudgetVariance === "netStly" ||
                                    selectedForecastVariance === "netStly"
                                      ? adr > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {commonService.formateAmount(adr)}
                                </td>
                                {(showVariance ||
                                  selectedBudgetVariance === "netStly" ||
                                  selectedForecastVariance === "netStly") && (
                                  <td
                                    className={`text-end ${
                                      showVariance ||
                                      selectedBudgetVariance === "netStly"
                                        ? adrVariance > 0
                                          ? "greenText"
                                          : "redText"
                                        : ""
                                    }`}
                                  >
                                    {adrVariance?.toFixed(1)}%
                                  </td>
                                )}
                                <td
                                  className={`text-end ${
                                    showVariance ||
                                    selectedBudgetVariance === "netStly" ||
                                    selectedForecastVariance === "netStly"
                                      ? ""
                                      : "perbackground perbackground-rev"
                                  } ${
                                    showVariance ||
                                    selectedBudgetVariance === "netStly" ||
                                    selectedForecastVariance === "netStly"
                                      ? rev > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                  value={rev}
                                >
                                  {commonService.formateAmount(rev)}
                                </td>
                                {(showVariance ||
                                  selectedBudgetVariance === "netStly" ||
                                  selectedForecastVariance === "netStly") && (
                                  <td
                                    className={`text-end ${
                                      showVariance ||
                                      selectedBudgetVariance === "netStly" ||
                                      selectedForecastVariance === "netStly"
                                        ? revVariance > 0
                                          ? "greenText"
                                          : "redText"
                                        : ""
                                    }`}
                                  >
                                    {revVariance?.toFixed(1)}%
                                  </td>
                                )}
                              </tr>
                              {index === 11 && (
                                <tr key={index}>
                                  <td className="boldTd">
                                    {parseInt(netStlyOcc / 12)}%
                                  </td>
                                  <td className="boldTd text-center">
                                    {showVariance ||
                                    selectedBudgetVariance === "netStly" ||
                                    selectedForecastVariance === "netStly"
                                      ? commonService.formateRoom(
                                          varianceNetStlyRms
                                        )
                                      : commonService.formateRoom(netStlyRms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {showVariance
                                      ? commonService.formateAmount(
                                          getTotalAdr("netStly")
                                        )
                                      : netStlyRev !== 0 && netStlyRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(netStlyRev / netStlyRms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td
                                    className={`boldTd text-end ${
                                      showVariance ||
                                      selectedBudgetVariance === "netStly" ||
                                      selectedForecastVariance === "netStly"
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    {(
                                      ((otbrev !== 0 && otbrms !== 0
                                        ? parseInt(otbrev / otbrms)
                                        : 0) *
                                        100) /
                                        (netStlyRev !== 0 && netStlyRms !== 0
                                          ? parseInt(netStlyRev / netStlyRms)
                                          : 0) -
                                      100
                                    )?.toFixed(1)}
                                    %
                                  </td>
                                  <td className="boldTd text-end">
                                    {showVariance
                                      ? commonService.formateAmount(
                                          otbrev - netStlyRev
                                        )
                                      : commonService.formateAmount(netStlyRev)}
                                  </td>
                                  <td
                                    className={`boldTd text-end ${
                                      showVariance ||
                                      selectedBudgetVariance === "netStly" ||
                                      selectedForecastVariance === "netStly"
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    {(
                                      (otbrev * 100) / netStlyRev -
                                      100
                                    )?.toFixed(1)}
                                    %
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.totalLyWidget} responsive>
                    <thead>
                      <tr>
                        <th
                          colSpan={
                            showVariance ||
                            selectedBudgetVariance === "totalLy" ||
                            selectedForecastVariance === "totalLy"
                              ? 6
                              : 4
                          }
                          className="text-center"
                        >
                          Total Ly
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        {(showVariance ||
                          selectedBudgetVariance === "totalLy" ||
                          selectedForecastVariance === "totalLy") && (
                          <th className="varianceField"></th>
                        )}
                        <th className="text-end">REV</th>
                        {(showVariance ||
                          selectedBudgetVariance === "totalLy" ||
                          selectedForecastVariance === "totalLy") && (
                          <th className="varianceField"></th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        totalLyData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === totalLyData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : totalLyLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        (totalLyData ||
                          varianceData ||
                          budgetVarianceData ||
                          forecastVarianceData) &&
                        month?.map((item, index) => {
                          let findMonthData = totalLyData?.filter(
                            (x) => x.month === item
                          );

                          let varianceMonthData = varianceData?.filter(
                            (x) => x?.month === item
                          );

                          let findBudgetVarianceData =
                            budgetVarianceData?.filter((x) => x.month === item);

                          let findForecastVarianceData =
                            forecastVarianceData?.filter(
                              (x) => x.month === item
                            );

                          let occ = 0,
                            rms = 0,
                            adr = 0,
                            rev = 0,
                            adrVariance = 0,
                            revVariance = 0;
                          totalLyAdr =
                            totalLyAdr +
                            (findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0);
                          totalLyRev =
                            totalLyRev +
                            (findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0);
                          totalLyRms =
                            totalLyRms +
                            (findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0);
                          if (showVariance) {
                            occ = varianceMonthData?.[0]?.LY_OCC
                              ? varianceMonthData?.[0]?.LY_OCC
                              : 0;
                            rms = varianceMonthData?.[0]?.LY_RMS
                              ? varianceMonthData?.[0]?.LY_RMS
                              : 0;
                            adr = varianceMonthData?.[0]?.LY_ADR
                              ? varianceMonthData?.[0]?.LY_ADR
                              : 0;
                            rev = varianceMonthData?.[0]?.LY_REV
                              ? varianceMonthData?.[0]?.LY_REV
                              : 0;
                            adrVariance =
                              varianceMonthData?.[0]?.OTB_ADR > 0 &&
                              findMonthData?.[0]?.adr > 0
                                ? ((varianceMonthData?.[0]?.OTB_ADR
                                    ? varianceMonthData?.[0]?.OTB_ADR
                                    : 0) *
                                    100) /
                                    (findMonthData?.[0]?.adr
                                      ? findMonthData?.[0]?.adr
                                      : 0) -
                                  100
                                : 0;

                            revVariance =
                              varianceMonthData?.[0]?.OTB_REV > 0 &&
                              findMonthData?.[0]?.rev > 0
                                ? ((varianceMonthData?.[0]?.OTB_REV
                                    ? varianceMonthData?.[0]?.OTB_REV
                                    : 0) *
                                    100) /
                                    (findMonthData?.[0]?.rev
                                      ? findMonthData?.[0]?.rev
                                      : 0) -
                                  100
                                : 0;

                            totalLyOcc =
                              totalLyOcc +
                              (varianceMonthData?.[0]?.LY_OCC
                                ? varianceMonthData?.[0]?.LY_OCC
                                : 0);
                            varianceTotalLyRms =
                              varianceTotalLyRms +
                              (varianceMonthData?.[0]?.LY_RMS
                                ? varianceMonthData?.[0]?.LY_RMS
                                : 0);
                          } else if (selectedBudgetVariance === "totalLy") {
                            occ = valueForBudgetVariance(
                              "LY_OCC",
                              findBudgetVarianceData
                            );
                            rms = valueForBudgetVariance(
                              "LY_RMS",
                              findBudgetVarianceData
                            );
                            adr = valueForBudgetVariance(
                              "LY_ADR",
                              findBudgetVarianceData
                            );
                            rev = valueForBudgetVariance(
                              "LY_REV",
                              findBudgetVarianceData
                            );

                            adrVariance =
                              valueForBudgetVariance(
                                "Budget_ADR",
                                findBudgetVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("adr", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    "Budget_ADR",
                                    findBudgetVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "adr",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            revVariance =
                              valueForBudgetVariance(
                                "Budget_REV",
                                findBudgetVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("rev", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    "Budget_REV",
                                    findBudgetVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "rev",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            totalLyOcc =
                              totalLyOcc +
                              valueForBudgetVariance(
                                "LY_OCC",
                                findBudgetVarianceData
                              );
                            varianceTotalLyRms =
                              varianceTotalLyRms +
                              valueForBudgetVariance(
                                "LY_RMS",
                                findBudgetVarianceData
                              );
                          } else if (selectedForecastVariance === "totalLy") {
                            let keyName = "Forecast";
                            if (selectedForecast === "rms") {
                              keyName = "RMS_Forecast";
                            } else if (selectedForecast === "user") {
                              keyName = "USER_Forecast";
                            }
                            occ = valueForBudgetVariance(
                              "LY_OCC",
                              findForecastVarianceData
                            );
                            rms = valueForBudgetVariance(
                              "LY_RMS",
                              findForecastVarianceData
                            );
                            adr = valueForBudgetVariance(
                              "LY_ADR",
                              findForecastVarianceData
                            );
                            rev = valueForBudgetVariance(
                              "LY_REV",
                              findForecastVarianceData
                            );

                            adrVariance =
                              valueForBudgetVariance(
                                `${keyName}_ADR`,
                                findForecastVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("adr", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    `${keyName}_ADR`,
                                    findForecastVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "adr",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            revVariance =
                              valueForBudgetVariance(
                                `${keyName}_REV`,
                                findForecastVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("rev", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    `${keyName}_REV`,
                                    findForecastVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "rev",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            totalLyOcc =
                              totalLyOcc +
                              valueForBudgetVariance(
                                "LY_OCC",
                                findForecastVarianceData
                              );
                            budgetVarianceOtbRms =
                              budgetVarianceOtbRms +
                              valueForBudgetVariance(
                                "LY_RMS",
                                findForecastVarianceData
                              );
                          } else {
                            occ = findMonthData?.[0]?.occ
                              ? findMonthData?.[0]?.occ
                              : 0;
                            rms = findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0;
                            adr = findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0;
                            rev = findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0;

                            totalLyOcc =
                              totalLyOcc +
                              (findMonthData?.[0]?.occ
                                ? findMonthData?.[0]?.occ
                                : 0);
                          }

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td
                                  className={`${
                                    showVariance ||
                                    selectedBudgetVariance === "totalLy" ||
                                    selectedForecastVariance === "totalLy"
                                      ? occ > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {occ}%
                                </td>
                                <td
                                  className={`text-center ${
                                    showVariance ||
                                    selectedBudgetVariance === "totalLy" ||
                                    selectedForecastVariance === "totalLy"
                                      ? rms > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {commonService.formateRoom(rms)}
                                </td>
                                <td
                                  className={`text-end ${
                                    showVariance ||
                                    selectedBudgetVariance === "totalLy" ||
                                    selectedForecastVariance === "totalLy"
                                      ? adr > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {commonService.formateAmount(adr)}
                                </td>
                                {(showVariance ||
                                  selectedBudgetVariance === "totalLy" ||
                                  selectedForecastVariance === "totalLy") && (
                                  <td
                                    className={`text-end ${
                                      showVariance ||
                                      selectedBudgetVariance === "totalLy" ||
                                      selectedForecastVariance === "totalLy"
                                        ? adrVariance > 0
                                          ? "greenText"
                                          : "redText"
                                        : ""
                                    }`}
                                  >
                                    {adrVariance?.toFixed(1)}%
                                  </td>
                                )}
                                <td
                                  className={`text-end ${
                                    showVariance ||
                                    selectedBudgetVariance === "totalLy" ||
                                    selectedForecastVariance === "totalLy"
                                      ? ""
                                      : "perbackground perbackground-rev"
                                  } ${
                                    showVariance ||
                                    selectedBudgetVariance === "totalLy" ||
                                    selectedForecastVariance === "totalLy"
                                      ? rev > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                  value={rev}
                                >
                                  {commonService.formateAmount(rev)}
                                </td>
                                {(showVariance ||
                                  selectedBudgetVariance === "totalLy" ||
                                  selectedForecastVariance === "totalLy") && (
                                  <td
                                    className={`text-end ${
                                      showVariance ||
                                      selectedBudgetVariance === "totalLy" ||
                                      selectedForecastVariance === "totalLy"
                                        ? revVariance > 0
                                          ? "greenText"
                                          : "redText"
                                        : ""
                                    }`}
                                  >
                                    {revVariance?.toFixed(1)}%
                                  </td>
                                )}
                              </tr>
                              {index === 11 && (
                                <tr key={index}>
                                  <td className="boldTd">
                                    {parseInt(totalLyOcc / 12)}%
                                  </td>
                                  <td className="boldTd text-center">
                                    {showVariance ||
                                    selectedBudgetVariance === "totalLy"
                                      ? commonService.formateRoom(
                                          varianceTotalLyRms
                                        )
                                      : commonService.formateRoom(totalLyRms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {showVariance
                                      ? commonService.formateAmount(
                                          getTotalAdr("totalLy")
                                        )
                                      : totalLyRev !== 0 && totalLyRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(totalLyRev / totalLyRms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td
                                    className={`boldTd text-end ${
                                      showVariance ||
                                      selectedBudgetVariance === "totalLy" ||
                                      selectedForecastVariance === "totalLy"
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    {(
                                      ((otbrev !== 0 && otbrms !== 0
                                        ? parseInt(otbrev / otbrms)
                                        : 0) *
                                        100) /
                                        (totalLyRev !== 0 && totalLyRms !== 0
                                          ? parseInt(totalLyRev / totalLyRms)
                                          : 0) -
                                      100
                                    )?.toFixed(1)}
                                    %
                                  </td>
                                  <td className="boldTd text-end">
                                    {showVariance
                                      ? commonService.formateAmount(
                                          otbrev - totalLyRev
                                        )
                                      : commonService.formateAmount(totalLyRev)}
                                  </td>
                                  <td
                                    className={`boldTd text-end ${
                                      showVariance ||
                                      selectedBudgetVariance === "totalLy" ||
                                      selectedForecastVariance === "totalLy"
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    {(
                                      (otbrev * 100) / totalLyRev -
                                      100
                                    )?.toFixed(1)}
                                    %
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="p-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable ">
                <div className="stripped__table">
                  <Table id={tblids.budgetWidget} responsive>
                    <thead>
                      <tr>
                        <th
                          colSpan={
                            showVariance ||
                            selectedForecastVariance === "budget"
                              ? 3
                              : 2
                          }
                          className="text-center"
                        >
                          Budget
                        </th>
                        <th
                          className="text-center varianceField"
                          colSpan={
                            showVariance ||
                            selectedForecastVariance === "budget"
                              ? 3
                              : 2
                          }
                        >
                          <Form.Select
                            value={selectedBudgetVariance}
                            onChange={(e) => {
                              if (e.target.value !== "") {
                                setShowVariance(false);
                                setSelectedForecastVariance("");
                              }
                              if (
                                e.target.value === "ema" ||
                                e.target.value === "rms" ||
                                e.target.value === "user"
                              ) {
                                setSelectedForecast(e.target.value);
                              }
                              setSelectedBudgetVariance(e.target.value);
                            }}
                          >
                            <option value="">Select Variance</option>
                            <option value={"otb"}>On the Books</option>
                            <option value={"netStly"}>Net STLY</option>
                            <option value={"totalLy"}>Total LY</option>
                            <option value={"ema"}>System Forecast</option>
                            <option value={"rms"}>RMS Forecast</option>
                            <option value={"user"}>User Forecast</option>
                            <option value={"nova"}>Nova Forecast</option>
                          </Form.Select>
                          <span style={{ display: "none" }}>
                            {selectedBudgetVariance}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        {(showVariance ||
                          selectedForecastVariance === "budget") && (
                          <th className="varianceField"></th>
                        )}
                        <th className="text-end">REV</th>
                        {(showVariance ||
                          selectedForecastVariance === "budget") && (
                          <th className="varianceField"></th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        budgetData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                            </tr>
                            {index === budgetData?.length - 1 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : budgetLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        (budgetData || varianceData || forecastVarianceData) &&
                        month?.map((item, index) => {
                          let findMonthData = budgetData?.filter(
                            (x) => x.month?.trim() === item
                          );

                          let varianceMonthData = varianceData?.filter(
                            (x) => x?.month === item
                          );

                          let findForecastVarianceData =
                            forecastVarianceData?.filter(
                              (x) => x?.month === item
                            );

                          let occ = 0,
                            rms = 0,
                            adr = 0,
                            rev = 0,
                            adrVariance = 0,
                            revVariance = 0;
                          budgetAdr =
                            budgetAdr +
                            (findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0);
                          budgetRev =
                            budgetRev +
                            (findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0);
                          budgetRms =
                            budgetRms +
                            (findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0);
                          if (showVariance) {
                            occ = varianceMonthData?.[0]?.Budget_OCC
                              ? varianceMonthData?.[0]?.Budget_OCC
                              : 0;
                            rms = varianceMonthData?.[0]?.Budget_RMS
                              ? varianceMonthData?.[0]?.Budget_RMS
                              : 0;
                            adr = varianceMonthData?.[0]?.Budget_ADR
                              ? varianceMonthData?.[0]?.Budget_ADR
                              : 0;
                            rev = varianceMonthData?.[0]?.Budget_REV
                              ? varianceMonthData?.[0]?.Budget_REV
                              : 0;
                            adrVariance =
                              varianceMonthData?.[0]?.OTB_ADR &&
                              findMonthData?.[0]?.adr
                                ? ((varianceMonthData?.[0]?.OTB_ADR
                                    ? varianceMonthData?.[0]?.OTB_ADR
                                    : 0) *
                                    100) /
                                    (findMonthData?.[0]?.adr
                                      ? findMonthData?.[0]?.adr
                                      : 0) -
                                  100
                                : 0;

                            revVariance =
                              varianceMonthData?.[0]?.OTB_REV &&
                              findMonthData?.[0]?.rev
                                ? ((varianceMonthData?.[0]?.OTB_REV
                                    ? varianceMonthData?.[0]?.OTB_REV
                                    : 0) *
                                    100) /
                                    (findMonthData?.[0]?.rev
                                      ? findMonthData?.[0]?.rev
                                      : 0) -
                                  100
                                : 0;

                            budgetOcc =
                              budgetOcc +
                              (varianceMonthData?.[0]?.Budget_OCC
                                ? varianceMonthData?.[0]?.Budget_OCC
                                : 0);
                            varianceBudgetRms =
                              varianceBudgetRms +
                              (varianceMonthData?.[0]?.Budget_RMS
                                ? varianceMonthData?.[0]?.Budget_RMS
                                : 0);
                          } else if (selectedForecastVariance === "budget") {
                            let keyName = "Forecast";
                            if (selectedForecast === "rms") {
                              keyName = "RMS_Forecast";
                            } else if (selectedForecast === "user") {
                              keyName = "USER_Forecast";
                            }
                            occ = valueForBudgetVariance(
                              "Budget_OCC",
                              findForecastVarianceData
                            );
                            rms = valueForBudgetVariance(
                              "Budget_RMS",
                              findForecastVarianceData
                            );
                            adr = valueForBudgetVariance(
                              "Budget_ADR",
                              findForecastVarianceData
                            );
                            rev = valueForBudgetVariance(
                              "Budget_REV",
                              findForecastVarianceData
                            );

                            adrVariance =
                              valueForBudgetVariance(
                                `${keyName}_ADR`,
                                findForecastVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("adr", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    `${keyName}_ADR`,
                                    findForecastVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "adr",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            revVariance =
                              valueForBudgetVariance(
                                `${keyName}_REV`,
                                findForecastVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("rev", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    `${keyName}_REV`,
                                    findForecastVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "rev",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            budgetOcc =
                              budgetOcc +
                              valueForBudgetVariance(
                                "Budget_OCC",
                                findForecastVarianceData
                              );
                            budgetVarianceOtbRms =
                              budgetVarianceOtbRms +
                              valueForBudgetVariance(
                                "Budget_RMS",
                                findForecastVarianceData
                              );
                          } else {
                            occ = findMonthData?.[0]?.occ
                              ? findMonthData?.[0]?.occ
                              : 0;
                            rms = findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0;
                            adr = findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0;
                            rev = findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0;

                            budgetOcc =
                              budgetOcc +
                              (findMonthData?.[0]?.occ
                                ? findMonthData?.[0]?.occ
                                : 0);
                          }

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td
                                  className={`${
                                    showVariance ||
                                    selectedForecastVariance === "budget"
                                      ? occ > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {occ}%
                                </td>
                                <td
                                  className={`text-center ${
                                    showVariance ||
                                    selectedForecastVariance === "budget"
                                      ? rms > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {commonService.formateRoom(rms)}
                                </td>
                                <td
                                  className={`text-end ${
                                    showVariance ||
                                    selectedForecastVariance === "budget"
                                      ? adr > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {commonService.formateAmount(adr)}
                                </td>
                                {(showVariance ||
                                  selectedForecastVariance === "budget") && (
                                  <td
                                    className={`text-end ${
                                      adrVariance
                                        ? occ > 0
                                          ? "greenText"
                                          : "redText"
                                        : ""
                                    }`}
                                  >
                                    {adrVariance?.toFixed(1)}%
                                  </td>
                                )}
                                <td
                                  className={`text-end ${
                                    showVariance ||
                                    selectedForecastVariance === "budget"
                                      ? ""
                                      : "perbackground perbackground-rev"
                                  } ${
                                    showVariance ||
                                    selectedForecastVariance === "budget"
                                      ? rev > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                  value={rev}
                                >
                                  {commonService.formateAmount(rev)}
                                </td>
                                {(showVariance ||
                                  selectedForecastVariance === "budget") && (
                                  <td
                                    className={`text-end ${
                                      showVariance ||
                                      selectedForecastVariance === "budget"
                                        ? revVariance > 0
                                          ? "greenText"
                                          : "redText"
                                        : ""
                                    }`}
                                  >
                                    {revVariance?.toFixed(1)}%
                                  </td>
                                )}
                              </tr>
                              {index === 11 && (
                                <tr key={index}>
                                  <td className="boldTd">
                                    {budgetOcc > 0
                                      ? parseInt(budgetOcc / 12)
                                      : 0}
                                    %
                                  </td>
                                  <td className="boldTd text-center">
                                    {showVariance
                                      ? commonService.formateRoom(
                                          varianceBudgetRms
                                        )
                                      : commonService.formateRoom(budgetRms)}
                                  </td>
                                  <td className="boldTd text-end">
                                    {showVariance
                                      ? commonService.formateAmount(
                                          getTotalAdr("Budget")
                                        )
                                      : budgetRev !== 0 && budgetRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(budgetRev / budgetRms)
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td
                                    className={`boldTd text-end ${
                                      showVariance ||
                                      selectedForecastVariance === "budget"
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    {(
                                      ((otbrev !== 0 && otbrms !== 0
                                        ? parseInt(otbrev / otbrms)
                                        : 0) *
                                        100) /
                                        (budgetRev !== 0 && budgetRms !== 0
                                          ? parseInt(budgetRev / budgetRms)
                                          : 0) -
                                      100
                                    )?.toFixed(1)}
                                    %
                                  </td>
                                  <td className="boldTd text-end">
                                    {showVariance
                                      ? commonService.formateAmount(
                                          otbrev - budgetRev
                                        )
                                      : commonService.formateAmount(budgetRev)}
                                  </td>
                                  <td
                                    className={`boldTd text-end ${
                                      showVariance ||
                                      selectedForecastVariance === "budget"
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    {(
                                      (otbrev * 100) / budgetRev -
                                      100
                                    )?.toFixed(1)}
                                    %
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="ps-0 allTableTogather-widget">
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.emaForecast} responsive>
                    <thead>
                      <tr>
                        <th
                          colSpan={
                            showVariance ||
                            selectedBudgetVariance === "ema" ||
                            selectedBudgetVariance === "rms" ||
                            selectedBudgetVariance === "user"
                              ? 3
                              : 2
                          }
                          className="text-center"
                        >
                          <Form.Select
                            value={selectedForecast}
                            onChange={(e) => {
                              setSelectedBudgetVariance("");
                              setSelectedForecast(e.target.value);
                            }}
                          >
                            <option value={"ema"}>System Forecast</option>
                            <option value={"rms"}>RMS Forecast</option>
                            <option value={"user"}>User Forecast</option>
                            <option value={"nova"}>Nova Forecast</option>
                          </Form.Select>
                          <span style={{ display: "none" }}>
                            {selectedForecast}
                          </span>
                        </th>
                        <th
                          className="text-center varianceField"
                          colSpan={
                            showVariance ||
                            selectedBudgetVariance === "ema" ||
                            selectedBudgetVariance === "rms" ||
                            selectedBudgetVariance === "user"
                              ? 3
                              : 2
                          }
                        >
                          <Form.Select
                            value={selectedForecastVariance}
                            onChange={(e) => {
                              if (e.target.value !== "") {
                                setShowVariance(false);
                                setSelectedBudgetVariance("");
                              }
                              setSelectedForecastVariance(e.target.value);
                            }}
                          >
                            <option value="">Select Variance</option>
                            <option value={"otb"}>On the Books</option>
                            <option value={"netStly"}>Net STLY</option>
                            <option value={"totalLy"}>Total LY</option>
                            <option value={"budget"}>Budget</option>
                          </Form.Select>
                          <span style={{ display: "none" }}>
                            {selectedForecastVariance}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th>OCC</th>
                        <th className="text-center">RMS</th>
                        <th className="text-end">ADR</th>
                        {(showVariance ||
                          selectedBudgetVariance === "ema" ||
                          selectedBudgetVariance === "rms" ||
                          selectedBudgetVariance === "user") && (
                          <th className="varianceField"></th>
                        )}
                        <th className="text-end">REV</th>
                        {(showVariance ||
                          selectedBudgetVariance === "ema" ||
                          selectedBudgetVariance === "rms" ||
                          selectedBudgetVariance === "user") && (
                          <th className="varianceField"></th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {api === 0 ? (
                        emaForecastData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item?.occ}%</td>
                              <td className="text-center">{item?.rms}</td>
                              <td className="text-end">${item?.adr}</td>
                              <td
                                className="text-end perbackground perbackground-rev"
                                value={item?.rev}
                              >
                                ${item?.rev}
                              </td>
                              {/* <td>{item?.var}%</td> */}
                            </tr>
                            {index === 11 && (
                              <tr>
                                <td className="boldTd">0%</td>
                                <td className="boldTd text-center">0</td>
                                <td className="boldTd text-end">$0</td>
                                <td className="boldTd text-end">$0</td>
                                {/* <td className="boldTd">0%</td> */}
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : emaForecastLoading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        (emaForecastData ||
                          varianceData ||
                          budgetVarianceData) &&
                        month?.map((item, index) => {
                          let findMonthData = emaForecastData?.filter(
                            (x) => x.month?.trim() === item?.trim()
                          );

                          let varianceMonthData = varianceData?.filter(
                            (x) => x?.month === item
                          );

                          let findBudgetVarianceData =
                            budgetVarianceData?.filter((x) => x.month === item);

                          let occ = 0,
                            rms = 0,
                            adr = 0,
                            rev = 0,
                            adrVariance = 0,
                            revVariance = 0;
                          emaForecastAdr =
                            emaForecastAdr +
                            (findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0);
                          emaForecastRev =
                            emaForecastRev +
                            (findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0);
                          emaForecastRms =
                            emaForecastRms +
                            (findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0);
                          if (showVariance) {
                            let keyName = "Forecast";
                            if (selectedForecast === "rms") {
                              keyName = "RMS_Forecast";
                            } else if (selectedForecast === "user") {
                              keyName = "USER_Forecast";
                            }
                            occ = varianceMonthData?.[0]?.[`${keyName}_OCC`]
                              ? varianceMonthData?.[0]?.[`${keyName}_OCC`]
                              : 0;
                            rms = varianceMonthData?.[0]?.[`${keyName}_RMS`]
                              ? varianceMonthData?.[0]?.[`${keyName}_RMS`]
                              : 0;
                            adr = varianceMonthData?.[0]?.[`${keyName}_ADR`]
                              ? varianceMonthData?.[0]?.[`${keyName}_ADR`]
                              : 0;
                            rev = varianceMonthData?.[0]?.[`${keyName}_REV`]
                              ? varianceMonthData?.[0]?.[`${keyName}_REV`]
                              : 0;
                            adrVariance =
                              varianceMonthData?.[0]?.OTB_ADR &&
                              findMonthData?.[0]?.adr
                                ? ((varianceMonthData?.[0]?.OTB_ADR
                                    ? varianceMonthData?.[0]?.OTB_ADR
                                    : 0) *
                                    100) /
                                    (findMonthData?.[0]?.adr
                                      ? findMonthData?.[0]?.adr
                                      : 0) -
                                  100
                                : 0;

                            revVariance =
                              varianceMonthData?.[0]?.OTB_REV &&
                              findMonthData?.[0]?.rev
                                ? ((varianceMonthData?.[0]?.OTB_REV
                                    ? varianceMonthData?.[0]?.OTB_REV
                                    : 0) *
                                    100) /
                                    (findMonthData?.[0]?.rev
                                      ? findMonthData?.[0]?.rev
                                      : 0) -
                                  100
                                : 0;

                            emaForecastOcc =
                              emaForecastOcc +
                              (varianceMonthData?.[0]?.[`${keyName}_OCC`]
                                ? varianceMonthData?.[0]?.[`${keyName}_OCC`]
                                : 0);
                            varianceForecastRms =
                              varianceForecastRms +
                              (varianceMonthData?.[0]?.[`${keyName}_RMS`]
                                ? varianceMonthData?.[0]?.[`${keyName}_RMS`]
                                : 0);
                          } else if (selectedBudgetVariance === "ema") {
                            occ = valueForBudgetVariance(
                              "Forecast_OCC",
                              findBudgetVarianceData
                            );
                            rms = valueForBudgetVariance(
                              "Forecast_RMS",
                              findBudgetVarianceData
                            );
                            adr = valueForBudgetVariance(
                              "Forecast_ADR",
                              findBudgetVarianceData
                            );
                            rev = valueForBudgetVariance(
                              "Forecast_REV",
                              findBudgetVarianceData
                            );

                            adrVariance =
                              valueForBudgetVariance(
                                "Budget_ADR",
                                findBudgetVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("adr", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    "Budget_ADR",
                                    findBudgetVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "adr",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            revVariance =
                              valueForBudgetVariance(
                                "Budget_REV",
                                findBudgetVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("rev", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    "Budget_REV",
                                    findBudgetVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "rev",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            emaForecastOcc =
                              emaForecastOcc +
                              valueForBudgetVariance(
                                "Forecast_OCC",
                                findBudgetVarianceData
                              );
                            varianceForecastRms =
                              varianceForecastRms +
                              valueForBudgetVariance(
                                "Forecast_RMS",
                                findBudgetVarianceData
                              );
                          } else if (selectedBudgetVariance === "rms") {
                            occ = valueForBudgetVariance(
                              "RMS_Forecast_OCC",
                              findBudgetVarianceData
                            );
                            rms = valueForBudgetVariance(
                              "RMS_Forecast_RMS",
                              findBudgetVarianceData
                            );
                            adr = valueForBudgetVariance(
                              "RMS_Forecast_ADR",
                              findBudgetVarianceData
                            );
                            rev = valueForBudgetVariance(
                              "RMS_Forecast_REV",
                              findBudgetVarianceData
                            );

                            adrVariance =
                              valueForBudgetVariance(
                                "Budget_ADR",
                                findBudgetVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("adr", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    "Budget_ADR",
                                    findBudgetVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "adr",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            revVariance =
                              valueForBudgetVariance(
                                "Budget_REV",
                                findBudgetVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("rev", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    "Budget_REV",
                                    findBudgetVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "rev",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            emaForecastOcc =
                              emaForecastOcc +
                              valueForBudgetVariance(
                                "RMS_Forecast_OCC",
                                findBudgetVarianceData
                              );
                            varianceForecastRms =
                              varianceForecastRms +
                              valueForBudgetVariance(
                                "RMS_Forecast_RMS",
                                findBudgetVarianceData
                              );
                          } else if (selectedBudgetVariance === "user") {
                            occ = valueForBudgetVariance(
                              "USER_Forecast_OCC",
                              findBudgetVarianceData
                            );
                            rms = valueForBudgetVariance(
                              "USER_Forecast_RMS",
                              findBudgetVarianceData
                            );
                            adr = valueForBudgetVariance(
                              "USER_Forecast_ADR",
                              findBudgetVarianceData
                            );
                            rev = valueForBudgetVariance(
                              "USER_Forecast_REV",
                              findBudgetVarianceData
                            );

                            adrVariance =
                              valueForBudgetVariance(
                                "Budget_ADR",
                                findBudgetVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("adr", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    "Budget_ADR",
                                    findBudgetVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "adr",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            revVariance =
                              valueForBudgetVariance(
                                "Budget_REV",
                                findBudgetVarianceData
                              ) > 0 &&
                              valueForBudgetVariance("rev", findMonthData) > 0
                                ? (valueForBudgetVariance(
                                    "Budget_REV",
                                    findBudgetVarianceData
                                  ) *
                                    100) /
                                    valueForBudgetVariance(
                                      "rev",
                                      findMonthData
                                    ) -
                                  100
                                : 0;

                            emaForecastOcc =
                              emaForecastOcc +
                              valueForBudgetVariance(
                                "USER_Forecast_OCC",
                                findBudgetVarianceData
                              );
                            varianceForecastRms =
                              varianceForecastRms +
                              valueForBudgetVariance(
                                "USER_Forecast_RMS",
                                findBudgetVarianceData
                              );
                          } else {
                            occ = findMonthData?.[0]?.occ
                              ? findMonthData?.[0]?.occ
                              : 0;
                            rms = findMonthData?.[0]?.rms
                              ? findMonthData?.[0]?.rms
                              : 0;
                            adr = findMonthData?.[0]?.adr
                              ? findMonthData?.[0]?.adr
                              : 0;
                            rev = findMonthData?.[0]?.rev
                              ? findMonthData?.[0]?.rev
                              : 0;

                            emaForecastOcc =
                              emaForecastOcc +
                              (findMonthData?.[0]?.occ
                                ? findMonthData?.[0]?.occ
                                : 0);
                          }

                          return (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td
                                  className={`${
                                    showVariance ||
                                    selectedBudgetVariance === "ema" ||
                                    selectedBudgetVariance === "rms" ||
                                    selectedBudgetVariance === "user"
                                      ? occ > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {occ}%
                                </td>
                                <td
                                  className={`text-center ${
                                    showVariance ||
                                    selectedBudgetVariance === "ema" ||
                                    selectedBudgetVariance === "rms" ||
                                    selectedBudgetVariance === "user"
                                      ? rms > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {commonService.formateRoom(rms)}
                                </td>
                                <td
                                  className={`text-end ${
                                    showVariance ||
                                    selectedBudgetVariance === "ema" ||
                                    selectedBudgetVariance === "rms" ||
                                    selectedBudgetVariance === "user"
                                      ? adr > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                >
                                  {commonService.formateAmount(adr)}
                                </td>
                                {(showVariance ||
                                  selectedBudgetVariance === "ema" ||
                                  selectedBudgetVariance === "rms" ||
                                  selectedBudgetVariance === "user") && (
                                  <td
                                    className={`text-end ${
                                      showVariance ||
                                      selectedBudgetVariance === "ema" ||
                                      selectedBudgetVariance === "rms" ||
                                      selectedBudgetVariance === "user"
                                        ? adrVariance > 0
                                          ? "greenText"
                                          : "redText"
                                        : ""
                                    }`}
                                  >
                                    {adrVariance?.toFixed(1)}%
                                  </td>
                                )}
                                <td
                                  className={`text-end ${
                                    showVariance ||
                                    selectedBudgetVariance === "ema" ||
                                    selectedBudgetVariance === "rms" ||
                                    selectedBudgetVariance === "user"
                                      ? ""
                                      : "perbackground perbackground-rev"
                                  } ${
                                    showVariance ||
                                    selectedBudgetVariance === "ema" ||
                                    selectedBudgetVariance === "rms" ||
                                    selectedBudgetVariance === "user"
                                      ? rev > 0
                                        ? "greenText"
                                        : "redText"
                                      : ""
                                  }`}
                                  value={rev}
                                >
                                  {commonService.formateAmount(rev)}
                                </td>
                                {(showVariance ||
                                  selectedBudgetVariance === "ema" ||
                                  selectedBudgetVariance === "rms" ||
                                  selectedBudgetVariance === "user") && (
                                  <td
                                    className={`text-end ${
                                      showVariance ||
                                      selectedBudgetVariance === "ema" ||
                                      selectedBudgetVariance === "rms" ||
                                      selectedBudgetVariance === "user"
                                        ? revVariance > 0
                                          ? "greenText"
                                          : "redText"
                                        : ""
                                    }`}
                                  >
                                    {revVariance?.toFixed(1)}%
                                  </td>
                                )}
                              </tr>
                              {index === 11 && (
                                <tr>
                                  <td className="boldTd">
                                    {emaForecastOcc > 0
                                      ? parseInt(emaForecastOcc / 12)
                                      : 0}
                                    %
                                  </td>
                                  <td className="boldTd text-center">
                                    {showVariance ||
                                    selectedBudgetVariance === "ema" ||
                                    selectedBudgetVariance === "rms" ||
                                    selectedBudgetVariance === "user"
                                      ? commonService.formateRoom(
                                          varianceForecastRms
                                        )
                                      : commonService.formateRoom(
                                          emaForecastRms
                                        )}
                                  </td>
                                  <td className="boldTd text-end">
                                    {showVariance
                                      ? commonService.formateAmount(
                                          getTotalAdr("forecast")
                                        )
                                      : emaForecastRev !== 0 &&
                                        emaForecastRms !== 0
                                      ? commonService.formateAmount(
                                          parseInt(
                                            emaForecastRev / emaForecastRms
                                          )
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td
                                    className={`boldTd text-end ${
                                      showVariance ||
                                      selectedBudgetVariance === "ema" ||
                                      selectedBudgetVariance === "rms" ||
                                      selectedBudgetVariance === "user"
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    {otbrev !== 0 &&
                                    otbrms !== 0 &&
                                    emaForecastRev !== 0 &&
                                    emaForecastRms !== 0
                                      ? (
                                          ((otbrev !== 0 && otbrms !== 0
                                            ? parseInt(otbrev / otbrms)
                                            : 0) *
                                            100) /
                                            (emaForecastRev !== 0 &&
                                            emaForecastRms !== 0
                                              ? parseInt(
                                                  emaForecastRev /
                                                    emaForecastRms
                                                )
                                              : 0) -
                                          100
                                        )?.toFixed(1)
                                      : 0}
                                    %
                                  </td>
                                  <td className="boldTd text-end">
                                    {showVariance
                                      ? commonService.formateAmount(
                                          otbrev - emaForecastRev
                                        )
                                      : commonService.formateAmount(
                                          emaForecastRev
                                        )}
                                  </td>
                                  <td
                                    className={`boldTd text-end ${
                                      showVariance ||
                                      selectedBudgetVariance === "ema" ||
                                      selectedBudgetVariance === "rms" ||
                                      selectedBudgetVariance === "user"
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    {otbrev && emaForecastRev
                                      ? (
                                          (otbrev * 100) / emaForecastRev -
                                          100
                                        )?.toFixed(1)
                                      : 0}
                                    %
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {getTotalForVariance()}
      </Row>
      {api !== 0 && (
        <div style={{ position: "relative" }}>
          <Comments widgetDetails={para} editorId={"AnnualSummary"} />
          <img
            src={chatIcon}
            onClick={(e) => {
              e.stopPropagation();
              handleAnnualSummaryChat();
            }}
            style={{
              display:"none",
              position: "absolute",
              right: "35px",
              height: "20px",
              top: "8px",
              zIndex: "999999",
              cursor: "pointer",
            }}
          />
        </div>
      )}

      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
      {showChatModal && (
        <AnnualSummaryChatModal
          showModal={showChatModal}
          closeModal={closeChatModal}
          payload={chatApiPayloadRef.current}
        />
      )}
    </div>
  );
};

export default AnnualSummary;
